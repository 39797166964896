import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { downloadFile } from "../../../../services/api/fileDownload";
import { DropDownButtonComponent } from "../../DropDownButtonComponent";

export const Download = ({ scan, meta }) => {
  const navigate = useNavigate();

  return (
    <DropDownButtonComponent
      text="Download"
      items={[
        {
          text: "Generate Pdf",
          action: () => navigate(`/analysis-pdf/${scan}`),
        },
      ]}
    />
  );
};

export const Radare = ({ scan, isLoading, radare }) => {
  return (
    <DropDownButtonComponent
      text="Radare"
      items={[
        {
          text: "View in radare",
          action: () => window.open(radare),
          isLoading: isLoading,
        },
      ]}
    />
  );
};

export const Compare = ({ onClick }) => {
  const navigate = useNavigate();
  const btnBgColorMode = useColorModeValue(
    "lightMode.secBgColor",
    "lightMode.btnBgColor"
  );

  return (
    <Menu>
      <MenuButton
        py="4"
        as={Button}
        fontWeight={"700"}
        bgColor={'darkMode.grayBackground'}
        fontSize={[10, 10, "13px", "13px"]}
        height={["30px", "30px", "40px", "40px"]}
      >
        <Flex
          gap={2}
          display="flex"
          align="center"
          justify={"center"}
          flexDirection={"row"}
        >
          <Text>Comparison</Text>
          <AiOutlineCaretDown />
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onClick}>Compare</MenuItem>
        <MenuItem onClick={() => navigate("/analysis/compared-list")}>
          All Comparisons
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
