export { default as Analysis } from "../assets/Analysis.png";
export { default as Combo } from "../assets/Combo-Chart.png";
export { default as Disturb } from "../assets/Disturb.png";
export { default as Funnel } from "../assets/Funnel.png";
export { default as Honeycombs } from "../assets/Honeycombs.png";
export { default as Logout } from "../assets/Logout.png";
export { default as Settings } from "../assets/Settings.png";
export { default as User } from "../assets/User.png";
export { default as LogoutDark } from "../assets/LogoutDark.png";
export { default as SettingsDark } from "../assets/SettingsDark.png";
export { default as Sun } from "../assets/Sun.png";
export { default as UserDark } from "../assets/UserDark.png";
export { default as Ellip } from "../assets/Ellip.png";
export { default as Ellips } from "../assets/Ellips.png";
export { default as Wave } from "../assets/wave.svg";
export { default as Ellipse } from "../assets/Ellipse.png";
export { default as Upload } from "../assets/Upload.svg";
export { default as Logo } from "../assets/Logo.png";
export { default as Computer } from "../assets/computer.svg";
export { default as Download } from "../assets/download.svg";
export { default as Malware } from "../assets/malware.svg";
export { default as Threat } from "../assets/threat.svg";
export { default as Warning } from "../assets/warning.svg";
export { default as ComputerDarkmode } from "../assets/computer-darkmode.svg";
export { default as MalwareDarkmode } from "../assets/malware-darkmode.svg";
export { default as ThreatDarkmode } from "../assets/threat-darkmode.svg";
export { default as WarningDarkmode } from "../assets/warning-darkmode.svg";
export { default as sud } from "../assets/sud.png";
export { default as Info } from "../assets/info.png";
export { default as General } from "../assets/general.png";
export { default as Error } from "../assets/error.png";
export { default as PdfAnalysis } from "../assets/Vector.png";
export { default as MalwareSettings } from "../assets/Malware.png";
export { default as Ellipse43 } from "../assets/Ellipse43.svg";
export { default as Ellipse44 } from "../assets/Ellipse 44.svg";
export { default as Support } from "../assets/support.svg";
