import React from "react";
import {
  Badge,
  Box,
  Menu,
  MenuButton,
  Flex,
  MenuList,
  Button,
  Text,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { getDiffInTime, scrollBarStyle } from "../../utils/helpers";
import { IoMdNotifications } from "react-icons/io";
import { BiMessageDots } from "react-icons/bi";
import {
  useGetNotifications,
  useMarkAsRead,
  useDeleteNotifications,
  useDeleteNotificationsId,
  useMarkAllAsRead,
} from "../../services/query/notification";
import useCustomToast from "../../utils/notification";
import { GET_NOTIFICATIONS } from "../../services/queryKeys";
import { FaTrash } from "react-icons/fa";

const Notification = () => {
  
  const queryClient = useQueryClient();
  const { successToast } = useCustomToast();
  const { data } = useGetNotifications();
  // const secBgColorMode = useColorModeValue(
  //   "lightMode.miniBgColor",
  //   "darkMode.miniBgColor"
  // );

  const secBgColorMode = "darkMode.secBgColor"

  // const unread = useColorModeValue(
  //   "lightMode.labelBgColor",
  //   "darkMode.labelBgColor"
  // );
  const unread = "darkMode.labelBgColor"

  // const textColorMode = useColorModeValue("lightMode.mainTextColor", "#fff");
  const textColorMode = "#ffffff"
  // const bg = useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor");
  const bg = "darkMode.grayBackground"

  // const itemBg = useColorModeValue(
  //   "lightMode.secBgColor",
  //   "darkMode.secBgColor"
  // );

  const itemBg = "darkMode.cardBgColor"

  const notify = () => {
    queryClient.invalidateQueries(GET_NOTIFICATIONS);

    localStorage.setItem("notification", data?.length);
  };

  const prevNotifications: any = localStorage.getItem("notification");

  const { mutate } = useMarkAsRead({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_NOTIFICATIONS);
    },
  });

  const markAsRead = (id) => {
    mutate({
      id,
    });
  };

  const { mutate: deleteMutate } = useDeleteNotificationsId({
    onSuccess: () => {
      successToast("Notification deleted");
      queryClient.invalidateQueries(GET_NOTIFICATIONS);
    },
  });

  const deleteById = (id) => {
    deleteMutate({
      id,
    });
  };

  const { mutate: allDelete } = useDeleteNotifications({
    onSuccess: () => {
      successToast("Notifications deleted");
      queryClient.invalidateQueries(GET_NOTIFICATIONS);
    },
  });

  const deleteAll = () => {
    allDelete();
  };

  const { mutate: allMutate } = useMarkAllAsRead({
    onSuccess: () => {
      queryClient.invalidateQueries(GET_NOTIFICATIONS);
    },
  });

  const markAllAsRead = () => {
    allMutate();
  };

  return (
    <Menu>
      <MenuButton
        mx="10px"
        p="1em"
        as={Button}
        fontSize="11"
        bgColor="darkMode.grayBackground"
        _active={{bgColor: "darkMode.grayBackground"}}
        _focus={{bgColor: "darkMode.grayBackground"}}
        _hover={{bgColor: "darkMode.grayBackground"}}
        onClick={notify}
      >
        {data?.length - prevNotifications === 0 ? (
          <IoMdNotifications size={16} />
        ) : (
          <Badge colorScheme="none">
            <IoMdNotifications size={16} />
          </Badge>
        )}
      </MenuButton>

      <MenuList
        bgColor={bg}
        py="10px"
        zIndex={"20"}
        w={["200px", "300px", "400px", "500px"]}
        h="40vh"
        overflowY="auto"
        sx={scrollBarStyle}
        boxShadow="dark-lg"
      >
        <Box
          w="100%"
          px="9px"
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          borderBottom="1px"
          borderColor="gray.300"
          pb="10px"
        >
          <Text color={textColorMode} fontSize="14px">
            Notifications
          </Text>
          <Flex w={["70%", "55%", "40%", "30%"]} justifyContent="space-between">
            {data?.length > 0 && (
              <>
                <Text
                  cursor="pointer"
                  fontSize="10px"
                  color={textColorMode}
                  as={Button}
                  mr="5px"
                  h="32px"
                  bgColor="darkMode.grayBackground"
                  textAlign="center"
                  fontWeight={"500"}
                  onClick={markAllAsRead}
                  _active={{bgColor: "darkMode.grayBackground"}}
                  _focus={{bgColor: "darkMode.grayBackground"}}
                  _hover={{bgColor: "darkMode.grayBackground"}}
                >
                  Mark all as read
                </Text>
                <Icon
                  as={FaTrash}
                  fontSize="12px"
                  color={"red"}
                  onClick={deleteAll}
                  cursor="pointer"
                  my="auto"
                />
              </>
            )}
          </Flex>
        </Box>

        <>
          {data?.map((item, i) => (
            <Box
              key={i}
              color={!item.unread ? textColorMode : unread}
              py="7px"
              px="11px"
              onClick={() => markAsRead(item.id)}
              cursor="pointer"
              background={item.unread ? itemBg : secBgColorMode}
              display="flex"
              alignItems="center"
            >
              <Text
                fontSize="16px"
                mr="2"
                color="#FFF"
                cursor="pointer"
                textAlign="center"
                onClick={() => deleteById(item.id)}
                mb="1"
              >
                x
              </Text>
              <Text fontSize={"15px"} color="#FFF">
                <BiMessageDots color="#FFF"/>
              </Text>{" "}
              <Flex color="#FFF" fontSize="11px" w="100%" justifyContent="space-between">
                <Text ml="7px">{item.verb}</Text>
                <Text>{getDiffInTime(item.timestamp)}</Text>
              </Flex>
            </Box>
          ))}
        </>
      </MenuList>
    </Menu>
  );
};

export default Notification;
