import React from "react";
import { Box, Button, Textarea, useColorModeValue } from "@chakra-ui/react";
import {
  useCreateComment,
  useGetComments,
} from "../../../../services/query/analysis";
import useCustomToast from "../../../../utils/notification";

const CreateComment = ({
  setComment,
  setShowBotton,
  setShowBox,
  onChange,
  onClick,
  comment,
}) => {
  const { errorToast, successToast } = useCustomToast();
  const { refetch } = useGetComments();

  const { mutate, isLoading: postLoading } = useCreateComment({
    onSuccess: (res) => {
      successToast(res.message);
      refetch();
      setComment("");
      setShowBotton(false);
      setShowBox("comment");
    },
    onError: (err) => {
      errorToast(err.message);
    },
  });

  const create = () => {
    mutate({
      comment,
    });
  };

  const border = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );
  const color = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.labelTextColor"
  );
  const btn = useColorModeValue("lightMode.secBgColor", "lightMode.btnBgColor");
  const textarea = useColorModeValue(
    "lightMode.cardBgColor",
    "darkMode.cardBgColor"
  );

  return (
    <Box
      height={"auto"}
      display="flex"
      alignItems={"center"}
      borderRadius="4px"
      justifyContent="space-between"
    >
      <Box w="95%" mx={"auto"}>
        <Box
          display={"flex"}
          flexDirection={["column", "row", "row", "row"]}
          mt="6"
        >
          <Button
            h={"40px"}
            width={"125px"}
            fontSize={[13, 13, "15px", "15px"]}
            paddingX={"9"}
            py="3"
            fontWeight={"500"}
            bgColor="#d9e3ee"
            onClick={onClick}
            background={btn}
          >
            Comment
          </Button>
          <Box w={"9"} />
          <Textarea
            placeholder="Write your comment here"
            size={"xs"}
            borderRadius="5"
            variant={"unstyled"}
            border="1px"
            borderColor={border}
            bgColor={textarea}
            color={color}
            pl={"8px"}
            value={comment}
            onChange={onChange}
            mt={["9px", "0", "0", "0"]}
          />
        </Box>

        <Box display={"flex"} mt="10" mb="5">
          <Button
            h={"40px"}
            fontSize={[13, 13, "15px", "15px"]}
            paddingX={"9"}
            py="3"
            fontWeight={"500"}
            bgColor="#d9e3ee"
            width={"107px"}
            opacity="0"
            cursor={"default"}
            display={["none", "block", "block", "block"]}
          >
            Mad
          </Button>

          <Box w={"9"} display={["none", "block", "block", "block"]} />

          <Button
            h={"40px"}
            fontSize={[13, 13, "15px", "15px"]}
            paddingX={"9"}
            py="3"
            fontWeight={"500"}
            bgColor="#d9e3ee"
            width={"125px"}
            mx={["auto", "0", "0", "0"]}
            background={btn}
            isLoading={postLoading}
            onClick={create}
            disabled={comment.length < 1}
            data-testid="create"
          >
            Add
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateComment;
