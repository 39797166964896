import { Box, Button, Center, Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import ErrorComponent from "../../components/data/ErrorComponent";
import BottomData from "../../components/data/Virus/BottomData/BottomData";
import TopData from "../../components/data/Virus/TopData/TopData";
import LoadingComponent from "../../components/Loaders/LoadingComponent";
import { useGetAnalysis, useGetAnalysisCount } from "../../services/query/analysis";
import { useGetHybridOverview, useGetHybridReport } from "../../services/query/hybrid";
import { useThreatDetails } from "../../services/query/threat";
import { useGetVirusReport } from "../../services/query/virus";
import { useCodeCaveDetails } from "../../services/query/codeCave"
import { useCodeAnalysisDetails } from "../../services/query/analysis";
import { apiURL } from "../../environment"
import AccordionComponent from "../../components/data/Analysis/Accordion";
import { useGetThreatSummaryDetail } from "../../services/query/dashboard";


const MalwareReport = () => {
  const [para, setPara] = React.useState("");

  const cardBgColor = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );

  const { id } = useParams();

  React.useEffect(() => {
    const myId = id?.split("_");

    setPara(!myId ? `${id}` : myId![0]);
  });
    
  const { data, isLoading, error, refetch } = useGetVirusReport(para as string);

  const { data: factData } = useGetAnalysis(id as string);

  const { data: hybridData } = useGetHybridReport(para as string);

  const { data: threatData } = useThreatDetails(id);

  const { data: analysisCount } = useGetAnalysisCount(id as string);

  const { data: codeAnalysisData } = useCodeAnalysisDetails(id as string)

  const { data: hybridOverview } = useGetHybridOverview(id as string)
  
  const { data: threat, isLoading: threatLoading, error: threatError} = useGetThreatSummaryDetail(id as string)

  // const { data: codeCaveData } = useCodeCaveDetails(id as string)
  
  const codeAnalysisHtml = apiURL + "firmware/get-q-analysis-report-by-type/" + id 
  
  const scanners = hybridData?.data;


  return (
    <Box>
      <Flex
        width={"100%"}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center">
          <CustomHeader title="Malware Report" />
          <Button
            bgColor={cardBgColor}
            mr="10px"
            disabled={true}
            cursor={"pointer"}
            onClick={() => {
              // Apply patch
            }}
          >
            Apply Patch
          </Button>
      </Flex>
      

      {(isLoading || threatLoading) && <LoadingComponent />}

      {error && (
        <ErrorComponent
          message="This firmware cannot be processed."
          header={id && id}
          reload={refetch}
        />
      )}
      {data && (
        <>
          <TopData data={data?.data} safe={analysisCount?.malware_count < 1} name={threatData?.device_name} />
          <BottomData
            hybridData={scanners}
            data={data?.data?.scans}
            factData={factData}
            threatData={threatData}
            codeAnalysisData={codeAnalysisData}
            codeAnalysisHtml={codeAnalysisHtml}
            hybridOverview={hybridOverview}
            sha={para}
          />
        </>
      )}
    </Box>
  );
};

export default MalwareReport;
