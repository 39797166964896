import React from "react";
import { Box, Heading, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { BiLinkExternal } from "react-icons/bi";
import { useGetFirmwareNews } from "../../../services/query/dashboard";
import PageLoader from "../../Loaders/PageLoader";
import { dateFormat, scrollBarStyle } from "../../../utils/helpers";

const FirmwareNews = () => {
  const { data, isLoading } = useGetFirmwareNews();

  // const bgColor = useColorModeValue("lightMode.secBgColor", "darkMode.secBgColor")
  const bgColor = "darkMode.mainBgColor"
  // const cardColor = useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor")
  const cardColor = "darkMode.cardBgColor"

  return (
    <Box>
      <Box
        bg={bgColor}
        p="10px 0px 16px 17px"
        borderTopRadius="10px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        Firmware News
      </Box>
      <Box
        marginTop="-8px"
        bg={cardColor}
        p="10px 30px 2px 17px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="10px"
        h={{ base: "330px", xl: "310px" }}
        overflowY="auto"
        sx={scrollBarStyle}
      >
        {isLoading && <PageLoader lineNo={4} />}
        {data?.length > 0 ? (
          data?.map((item: any) => (
            <Box key={item?.id} mb="16px">
              <Heading fontSize="16px" as="h4">
                {item?.title || "No Result"}
              </Heading>
              <Box my="2px" fontSize="14px">
                {item?.body || "No Result"}{" "}
                <Link
                  color="blue.600"
                  display="inline-flex"
                  alignItems="center"
                  fontWeight="bold"
                  href={item?.link || "/"}
                  isExternal
                >
                  Read more <BiLinkExternal />
                </Link>
              </Box>
              <Box fontSize="12px" color="#909090">
                {dateFormat(item?.created_at)}
              </Box>
            </Box>
          ))
        ) : (
          <Text>No firmware news at the moment</Text>
        )}
      </Box>
    </Box>
  );
};

export default FirmwareNews;
