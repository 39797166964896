import { Box, Grid, Text } from "@chakra-ui/react";
import React from "react";
import { TimeFormater } from "../../common/TimeFormater";
import Accordions from "../Analysis/Accordion";

export const VirusAccordion = ({ analysis, name }) => {
  if (analysis?.detected) {
    return (
      // <Accordions
      //   name={name}
      //   detected={analysis?.detected ? true : false}
      //   dataItems={[
      //     {
      //       title: "detected",
      //       data: analysis?.detected ? "true" : "false" || "No Result",
      //     },
      //     {
      //       title: "version",
      //       data: analysis?.version || "No Result",
      //     },
      //     {
      //       title: "result",
      //       data: analysis?.result || "No Result",
      //     },
      //     {
      //       title: "update",
      //       data: analysis?.update || "No Result",
      //     },
      //   ]}
      // />
      <Box
          p="10px"
          borderRadius="0.75em"
          bgColor={"darkMode.grayBackground"}
          >
          <Text fontSize="16">{name}</Text>
          <Text fontSize="12">{analysis?.result}</Text>
      </Box>
    );
  } else {
    return <div style={{display: "none"}}></div>
  }
  
};

export const HybridAccordion = ({ analysis, name }) => {
  return (
    <Accordions
      name={name}
      dataItems={[
        {
          title: "Error Message",
          data: analysis?.error_message || "No Result",
        },
        {
          title: "Percent",
          data: analysis?.percent || "No Result",
        },
        {
          title: "Positives",
          data: analysis?.positives || "No Result",
        },
        {
          title: "Progress",
          data: analysis?.progress || "No Result",
        },
        {
          title: "Status",
          data: analysis?.status || "No Result",
        },
        {
          title: "Total",
          data: analysis?.total || "No Result",
        },
      ]}
    />
  );
};

export const Lookup = ({ analysis, sha }) => {
  if (analysis?.hashlookup?.skipped) {
    return (
      <Accordions
        name="Hash lookup"
        dataItems={[
          {
            title: "Time of Analysis",
            data:
              <TimeFormater date={analysis?.hashlookup?.analysis_date} /> ||
              "No Result",
          },
          {
            title: "Plugin Version",
            data: analysis?.hashlookup?.plugin_version || "No Result",
          },
          {
            title: "Skipped",
            data: analysis?.hashlookup?.skipped || "No Result",
          },
          {
            title: "Sha",
            data: sha || "No Result",
          },
          // {
          //   title: "Summary",
          //   data: "No Result",
          // },
        ]}
      />
    );
  } else {
    return <div></div>
  }
  
};

export const MiddleAccordion = ({ analysis, name, title }) => {
  return (
    // <Accordions
    //   name={name}
    //   dataItems={[
    //     {
    //       title: title,
    //       data: analysis || "No Result",
    //     },
    //   ]}
    // />
    <Grid
        flexDirection={'row'}
        templateColumns='repeat(1, 1fr)'
        gap='2'
        
        >
        <Text
          mb="5px"
          fontWeight="500"
          fontSize="16px"
          >
          {name}
        </Text>
        <Text 
          p="3" 
          borderRadius="5px" 
          bgColor="darkMode.grayBackground" 
          fontSize="14px" fontWeight="200"
          mb="10px"
          >
          {analysis}
        </Text>
    </Grid>
  );
};

export const Unpacker = ({ analysis }) => {
  return (
    <Accordions
      name="Unpacker"
      dataItems={[
        // {
        //   title: "Time of Analysis",
        //   data:
        //     <TimeFormater date={analysis?.unpacker?.analysis_date} /> ||
        //     "No Result",
        // },
        // {
        //   title: "Plugin Version",
        //   data: analysis?.unpacker?.plugin_version || "No Result",
        // },
        {
          title: "Entropy",
          data: analysis?.unpacker?.entropy || "No Result",
        },
        {
          title: "Number of excluded files",
          data: analysis?.unpacker?.number_of_excluded_files || "No Result",
        },
        {
          title: "Number of unpacked directories",
          data:
            analysis?.unpacker?.number_of_unpacked_directories || "No Result",
        },
        {
          title: "Number of unpacked files",
          data: analysis?.unpacker?.number_of_unpacked_files || "No Result",
        },
        {
          title: "Plugin used",
          data: analysis?.unpacker?.plugin_used || "No Result",
        },
        {
          title: "Size packed",
          data: analysis?.unpacker?.size_packed || "No Result",
        },
        {
          title: "Size unpacked",
          data: analysis?.unpacker?.size_unpacked || "No Result",
        },
        {
          title: "Output",
          data: analysis?.unpacker?.output || "No Result",
        },
        // {
        //   title: "Summary",
        //   data: "",
        // },
      ]}
    />
  );
};

export const Intezer = ({ analysis, bgCustomBColor }) => {
  return (
    <Accordions
      name="Intezer"
      bgCustomBColor={bgCustomBColor}
      dataItems={[
        {
          title: "Time of Analysis",
          data: analysis?.analysis_time || "No Result",
        },
        {
          title: "Analysis ID",
          data: analysis?.analysis_id || "No Result",
        },
        {
          title: "Sub Verdict",
          data: analysis?.sub_verdict || "No Result",
        },
        {
          title: "Verdict",
          data: analysis?.verdict || "No Result",
        },
        // {
        //   title: "Summary",
        //   data: "No Result",
        // },
      ]}
    />
  );
};
