import React from "react";
import { Box, Button, Divider, Text, useColorMode } from "@chakra-ui/react";

export const Btn = ({ isLoading }) => {
  return (
    <Box display="flex" justifyContent="flex-end" w="100" mt="5">
      <Button
        type="submit"
        isLoading={isLoading}
        bgColor="darkMode.grayBackground"
        color="white"
        size="md"
        height="40px"
        width={["100%", "150px", "150px", "150px"]}
      >
        Update
      </Button>
    </Box>
  );
};

export const Header = ({ title, label }) => {
  const { colorMode } = useColorMode();

  return (
    <Box px={["20px", "20px", "10", "20"]} py={["20px", "20px", "10", "10"]}>
      <Text
        fontWeight="500"
        fontSize="30px"
        lineHeight="16px"
        color={"white"}
        my={3}
      >
        {title}
      </Text>
      <Text fontWeight="500" lineHeight="16px" mt={7} color={"white"}>
        {label}
      </Text>
    </Box>
  );
};

export const Footer = ({ label, subLabel }) => {
  const { colorMode } = useColorMode();

  return (
    <Box mt="20" pb="5">
      <Text
        fontWeight="500"
        fontSize="30px"
        lineHeight="16px"
        my={3}
        color={"white"}
      >
        {label}
      </Text>
      <Text color={"white"} fontWeight="500" lineHeight="16px" mt={7}>
        {subLabel}
      </Text>
    </Box>
  );
};

export const Divide = () => {
  const { colorMode } = useColorMode();

  return <Divider bgColor={"darkMode.cardBgColor"} />;
};
