import axiosInstance from "../axiosInstance";
import {
  BINARY_SEARCH,
  BINARY_SEARCH_INIT,
  BASIC_SEARCH,
  BINARY_SEARCH_HISTORY,
  ADVANCED_FIRMWARE_SEARCH,
  INITIALIZE_BINARY_SEARCH,
  LIST_ALL_BINARY_SEARCH,
  GET_BINARY_LIST_DETAILS,
} from "../apiUrl";

export const initializeBinarySearch = async (body) => {
  const res = await axiosInstance.post(BINARY_SEARCH_INIT, body);
  return res.data;
};

export const initializeBinary = async (body) => {
  const res = await axiosInstance.post(INITIALIZE_BINARY_SEARCH, body);
  return res.data;
};

export const getAllBinarySearchList = async () => {
  const res = await axiosInstance.get(LIST_ALL_BINARY_SEARCH);
  return res.data;
};

export const getBinarySearchDetails = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${GET_BINARY_LIST_DETAILS}${id}`);
  return res.data;
};

export const getBinarySearchList = async ({ queryKey }) => {
  const [, scanUId] = queryKey;
  const res = await axiosInstance.get(`${BINARY_SEARCH}?scan_uid=${scanUId}`);
  return res.data;
};

export const getBinarySearchHistory = async () => {
  const res = await axiosInstance.get(BINARY_SEARCH_HISTORY);
  return res.data;
};

export const basicSearch = async (body) => {
  const res = await axiosInstance.post(BASIC_SEARCH, body);
  return res.data;
};

export const advancedFirmwareSearch = async (body) => {
  const res = await axiosInstance.post(ADVANCED_FIRMWARE_SEARCH, body);
  return res.data;
};
