import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { getExploitMitigation } from "../api/exploitMitigation";
import { EXPLOIT_MITIGATION } from "../queryKeys";

export const useGetExploitMitigation = (ids: string, options = {}) => {
  const { data, isLoading, error } = useQuery<any, AxiosError>(
    [EXPLOIT_MITIGATION, ids],
    getExploitMitigation,
    { ...options }
  );
  return { data, isLoading, error };
};
