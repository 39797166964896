import React from "react";
import { Box, useColorModeValue, Grid } from "@chakra-ui/react";
import { useBasicSearch } from "../../services/query/search";
import { useGetModels } from "../../services/query/analysis";
import useCustomToast from "../../utils/notification";
import CustomHeader from "../../components/common/CustomHeader";
import ModelItemComponent from "../../components/data/search-components/ModelItemComponent";
import { flexed, holdingBox } from "../../components/styles/styles";
import BasicSearchInput from "../../components/CustomInput/BasicSearchInput";
import { Btn } from "../../components/data/search-components/Form";

const BasicSearch = () => {
  const [deviceName, setDeviceName] = React.useState("");
  const [deviceClass, setDeviceClass] = React.useState("");
  const [response, setResponse] = React.useState<any[]>([]);
  const { errorToast, infoToast } = useCustomToast();
  const { data } = useGetModels();
  const { mutate, isLoading } = useBasicSearch({
    onSuccess: (res) => {
      setResponse(res?.results);
      if (res?.results.length < 1) {
        infoToast("Search doesn't match a firmware", 6000);
      }
    },
    onError: (err) => {
      errorToast(err?.response?.data);
      errorToast(err.response.data.firmwares[0]);
    },
  });

  const search = () => {
    if (deviceClass === "") {
      errorToast("Please select device class");
    } else if (deviceName === "") {
      errorToast("Please select device name");
    } else {
      mutate({
        device_name: deviceName,
        device_class: deviceClass,
      });
    }
  };

  // const textColorMode = useColorModeValue(
  //   "lightMode.mainTextColor",
  //   "darkMode.mainTextColor"
  // );
  const textColorMode = "darkMode.mainTextColor"

  const bgColor = "darkMode.secBgColor"

  return (
    <Box>
      <Box color={textColorMode}>
        <CustomHeader title="Search Firmware Database" />
      </Box>

      <Box>
        <Box 
          sx={flexed}
          bgColor={bgColor}
          >
          <BasicSearchInput
            value={deviceClass}
            onChange={(e) => {
              setDeviceClass(e.target.value);
            }}
            placeholder="Select Device Class"
          >
            {data?.results?.map((item, i) => (
              <option style={{backgroundColor: "#323541"}} value={item.device_class} key={i}>
                {item.device_class}
              </option>
            ))}
          </BasicSearchInput>
          <BasicSearchInput
            value={deviceName}
            onChange={(e) => {
              setDeviceName(e.target.value);
            }}
            placeholder="Select Device Name"
          >
            {data?.results?.map((item, i) => (
              <option style={{backgroundColor: "#323541"}} value={item.device_name} key={i}>
                {item.device_name}
              </option>
            ))}
          </BasicSearchInput>
        </Box>
        <Btn onClick={search} isLoading={isLoading} />

        {response && (
          <Box sx={holdingBox}>
            <Grid
              my="1em"
              columnGap="2em"
              templateColumns={["repeat(1, 1fr)", null, null, "repeat(2, 1fr)"]}
            >
              {response &&
                response?.map((item: any, i: number) => (
                  <ModelItemComponent item={item} key={i} />
                ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BasicSearch;
