import { Box, Grid, Text, GridItem } from "@chakra-ui/react";
import { useState } from "react";
import ErrorComponent from "../../components/data/ErrorComponent";
import CustomHeader from "../../components/common/CustomHeader";
import ListEmptyComponent from "../../components/data/ListEmptyComponent";
import PageLoader from "../../components/Loaders/PageLoader";
import { useGetModels } from "../../services/query/analysis";
import { useFirmwareScanList } from "../../services/query/firmware";
import { ModelItemComponent } from "../../components/data/Analysis/ModelItemComponent";
import { FirmwareSelect } from "../../components/CustomInput/SelectLayout";

const BrowseFirmware = () => {
  const { data: firmwareId } = useFirmwareScanList();
  const { data, error, isLoading, refetch } = useGetModels();
  const [deClass, setDeClass] = useState("");
  const [deVendor, setDeVendor] = useState("");
  const someClasses = data?.results?.filter((so) => {
    return so.device_class.includes(deClass) && so.vendor.includes(deVendor);
  });

  const holdingBox = {
    px: [2, 9, 10, "200px"],
    py: [2, 3, 5, 5],
  };

  const flexed = {
    display: ["block", "flex", "flex", "flex"],
    justifyContent: "space-between",
    px: [2, 9, 10, "200px"],
    py: [2, 3, 5, 5],
    width: "100%",
    margin: 0,
  };

  return (
    <Box>
      <CustomHeader title="Browse Firmwares" />
      <Box sx={holdingBox} textAlign="center">
        <Text as="span" fontWeight="600">
          {someClasses?.length}
        </Text>{" "}
        Total Firmware(s) Available
      </Box>

      <Box my="5">
        <Box sx={flexed}>
          <FirmwareSelect
            placeholder="Select Device Class"
            onChange={(e) => setDeClass(e.target.value)}
          >
            {firmwareId?.results.map((data, i) => (
              <option key={i} value={data?.device_class}>
                {data?.device_class || "No device class"}
              </option>
            ))}
          </FirmwareSelect>
          <FirmwareSelect
            placeholder="Select Vendor"
            onChange={(e) => setDeVendor(e.target.value)}
          >
            {firmwareId?.results.map((data, i) => (
              <option key={i + 1} value={data?.vendor}>
                {data?.vendor || "No Vendor"}
              </option>
            ))}
          </FirmwareSelect>
        </Box>

        {isLoading && (
          <Grid
            templateColumns={["repeat(1, 1fr)", null, null, "repeat(1, 1fr)"]}
            gap={6}
            sx={holdingBox}
          >
            <GridItem>
              <PageLoader lineNo={6} />
            </GridItem>
            <GridItem>
              <PageLoader lineNo={6} />
            </GridItem>
          </Grid>
        )}

        {error && <ErrorComponent message={error?.message} reload={refetch} />}

        {data && data.results?.length === 0 && (
          <Box sx={holdingBox}>
            <ListEmptyComponent message="No firmware found" />
          </Box>
        )}
        <Box sx={holdingBox}>
          {data && (
            <Grid
              my="1em"
              columnGap="2em"
              templateColumns={["repeat(1, 1fr)", null, null, "repeat(1, 1fr)"]}
            >
              {someClasses?.map((item: any, i: number) => (
                <ModelItemComponent item={item} key={i} />
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BrowseFirmware;
