import React, { useEffect, useState } from "react";
import {
  Text,
  Box,
  useColorMode,
  Grid,
  GridItem,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useGetModels } from "../../services/query/analysis";
import ErrorComponent from "../../components/data/ErrorComponent";
import PageLoader from "../../components/Loaders/PageLoader";
import ListEmptyComponent from "../../components/data/ListEmptyComponent";
import Pagination from "../../components/common/Pagination";
import { GET_PAGINATED_MODELS } from "../../services/apiUrl";
import CustomHeader from "../../components/common/CustomHeader";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";

export const ModelItemComponent = ({ item }: { item: any }) => {
  const { colorMode } = useColorMode();

  const bgColor = "darkMode.grayBackground"

  return (
    <>
      <Link to={`/security/scan-overview/${item?.scan_uid}`}>
        <Box
          my="9px"
          p="20px"
          borderRadius="0.5em"
          bgColor={bgColor}
          borderColor="none"
        >
          <Text fontWeight="500" fontSize={{ lg: "20px" }} textAlign="center">
            {item?.device_name ? item?.device_name : "No device name"}
          </Text>
        </Box>
      </Link>
    </>
  );
};

const Models = () => {
  const [currentEndpoint, setCurrentEndpoint] = useState(GET_PAGINATED_MODELS);
  const { data, error, isLoading, refetch } = useGetModels(currentEndpoint);
  const [show, setShow] = useState(false);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Box>
      <CustomHeader title="Uploaded Firmwaress"  />

      {isLoading && (
        <Grid
          templateColumns={["repeat(1, 1fr)", null, null, "repeat(2, 1fr)"]}
          gap={6}
        >
          <GridItem>
            <PageLoader lineNo={6} />
          </GridItem>
          <GridItem>
            <PageLoader lineNo={6} />
          </GridItem>
        </Grid>
      )}

      {error && <ErrorComponent message={error?.message} reload={refetch} />}

      {data && data?.results?.length === 0 && (
        <ListEmptyComponent message="No firmware found" />
      )}

      {data && (
        <>
          <Flex
            w="80%"
            mx="auto"
            h="60px"
            border="none"
            justifyContent="space-between"
            alignItems="center"
            cursor="pointer"
            borderRadius="8px"
            bgColor="darkMode.cardBgColor"
            onClick={() => setShow(!show)}
            fontWeight="600"
            mt="25px"
          >
            <Text marginLeft="20px">Uploaded Firmwares</Text>

            <Icon
              fontSize="25px"
              marginRight="20px"
              as={show ? BiChevronDown : BiChevronRight}
            />
          </Flex>
          <Box my="1em" w="80%" mx="auto">
            {show && (
              <>
                {data?.results?.map((item: any, i: number) => (
                  <ModelItemComponent item={item} key={i} />
                ))}
              </>
            )}
          </Box>
        </>
      )}
      {show && (
        <>
          {data && (
            <Pagination
              setCurrentEndpoint={setCurrentEndpoint}
              next={data?.next}
              prev={data?.previous}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Models;
