import { Box } from "@chakra-ui/react";
import { useFirmwareStatistics } from "../../services/query/firmwareStatistics";
import FirmwareSoho from "../../components/data/Info/FirmwareSoho";
import CustomHeader from "../../components/common/CustomHeader";
import LoadingComponent from "../../components/Loaders/LoadingComponent";

const FirmwareStatistics = () => {
  const { data, isLoading, error } = useFirmwareStatistics();

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <CustomHeader title="Firmware Statistics" />
      </Box>
      {isLoading && <LoadingComponent />}
      {data && <FirmwareSoho data={data} isLoading={isLoading} error={error} />}
    </Box>
  );
};

export default FirmwareStatistics;
