export const baseStyle = {
  flex: 1,
  display: "flex",

  alignItems: "center",
  padding: "20px",
  borderWidth: "3px",
  borderColor: "rgba(0, 0, 0, 0.25)",
  borderStyle: "dashed",
  backgroundColor: "#4F5776",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  height: "200px",
  borderRadius: "15px",
};

export const focusedStyle = {
  borderColor: "#2196f3",
};

export const acceptStyle = {
  borderColor: "#00e676",
};

export const rejectStyle = {
  borderColor: "#ff1744",
};

export const inptBoxStyle = {
  border: "none",
  color: "white",
  fontWeight: "500",
  mb: ["2", "1", "1", "1"],
  height: "auto",
};

export const flexed = {
  display: ["block", "flex", "flex", "flex"],
  justifyContent: "space-between",
  px: [2, 9, 10, "200px"],
  py: [2, 3, 5, 5],
  width: "100%",
  margin: 0,
};

export const holdingBox = {
  px: [2, 9, 10, "200px"],
  py: [2, 3, 5, 5],
};
