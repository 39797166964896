import { Box, Flex, useColorMode } from "@chakra-ui/react";
import React from "react";
import CustomHeader from "../../components/common/CustomHeader";

const MalwareLayout = ({ first, value, color }) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      minH="40px"
      bg={
        colorMode === "light"
          ? color === "light"
            ? "transparent"
            : "#D9E3EB"
          : color === "black"
          ? "#1C2A35"
          : "transparent"
      }
    >
      <Flex pl="10px" w="30%" alignItems="center">
        {first}
      </Flex>
      <Flex
        pl="10px"
        w="70%"
        alignItems="center"
        borderLeft="1px solid #D9E3EB"
      >
        {value}
      </Flex>
    </Flex>
  );
};

const MalwareResult = () => {
  return (
    <Box>
      <CustomHeader title="Malware Result" />

      <Box border="1px solid #D9E3EB" mt="20px">
        <MalwareLayout color="light" first={"Response Code"} value="1" />
        <MalwareLayout
          color="black"
          first={"Verbose Message"}
          value="Scan finished, scan information embedded in this object "
        />
        <MalwareLayout color="light" first={"Resource"} value="1" />
        <MalwareLayout color="black" first={"Scan Id"} value="1" />
        <MalwareLayout color="light" first={"Md5"} value="1522632hsjsjs92iwi" />
        <MalwareLayout
          color="black"
          first={"Sha 1"}
          value="737383939y3ehejjdji3"
        />
        <MalwareLayout
          color="light"
          first={"Sha 256"}
          value="262627uhshshsjjsjw"
        />
        <MalwareLayout color="black" first={"Scan Date"} value="12/23/2022" />
        <MalwareLayout
          color="light"
          first={"Permalink"}
          value="https://wuuwiwommshssu"
        />
      </Box>
    </Box>
  );
};

export default MalwareResult;
