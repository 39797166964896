import {
  GridItem,
  Text,
  Box,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";

const FirmwareBox = ({
  title,
  children,
  icon,
}: {
  title: string;
  children: ReactNode;
  icon: any;
}) => {
  const secBgColorMode = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );

  return (
    <GridItem
      boxShadow="xs"
      rounded="10px"
      colSpan={{ base: 3, md: 1 }}
      h={{ base: "55vh", md: "70vh" }}
      bg={secBgColorMode}
      p={[3, 3, 5, 5]}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        mb={2}
      >
        <Image
          src={icon}
          alt="i"
          width="25px"
          pr={2}
          alignSelf="center"
          mb={2}
        />
        <Text fontWeight={"bold"} mb="10px" fontSize={"large"}>
          {title}
        </Text>
      </Box>

      {children}
    </GridItem>
  );
};

export default FirmwareBox;
