import { useMutation, useQuery } from "react-query";
import {
  getBinarySearchHistory,
  getBinarySearchList,
  initializeBinarySearch,
  basicSearch,
  getBinarySearchDetails,
  getAllBinarySearchList,
  initializeBinary,
  advancedFirmwareSearch,
} from "../api/search";
import {
  INITIAL_BINARY_SEARCH,
  INITIAL_BINARY,
  BINARY_SEARCH,
  BASIC_SEARCH,
  GET_BINARY_LIST_DETAILS,
  ADVANCED_FIRMWARE_SEARCH,
  BINARY_SEARCH_HISTORY,
  GET_BINARY_LIST,
} from "../queryKeys";

export const useInitializeBinarySearch = (options = {}) => {
  const { mutate, isLoading } = useMutation(initializeBinarySearch, {
    mutationKey: INITIAL_BINARY_SEARCH,
    ...options,
  });

  return { mutate, isLoading };
};

export const useInitializeBinary = (options = {}) => {
  const { mutate, isLoading } = useMutation(initializeBinary, {
    mutationKey: INITIAL_BINARY,
    ...options,
  });
  return { mutate, isLoading };
};

export const useGetAllBinarySearchList = (options = {}) => {
  const { data, isLoading, error } = useQuery(
    [GET_BINARY_LIST],
    getAllBinarySearchList,
    {
      ...options,
    }
  );
  return { data, isLoading, error };
};

export const useGetBinarySearchDetails = (id: string, options = {}) => {
  const { data, isLoading } = useQuery(
    [GET_BINARY_LIST_DETAILS, id],
    getBinarySearchDetails,
    {
      ...options,
    }
  );
  return { data, isLoading };
};

export const useBinarySearchList = (
  enableReq,
  scanUId,
  setInitialSearchStatus
) => {
  const { data, isLoading, error } = useQuery(
    [BINARY_SEARCH, scanUId],
    getBinarySearchList,
    {
      enabled: enableReq,
      onSuccess: (): any => setInitialSearchStatus(false),
    }
  );

  return { data, isLoading, error };
};

export const useBinarySearchHistory = () => {
  const { data, isLoading, error } = useQuery(
    [BINARY_SEARCH_HISTORY],
    getBinarySearchHistory
  );

  return { data, isLoading, error };
};

export const useBasicSearch = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(basicSearch, {
    mutationKey: BASIC_SEARCH,
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useAdvancedFirmwareSearch = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(advancedFirmwareSearch, {
    mutationKey: ADVANCED_FIRMWARE_SEARCH,
    ...options,
  });

  return { mutate, isLoading, data };
};
