import React from "react";
import { Box, useColorModeValue, Grid } from "@chakra-ui/react";
import { useAdvancedFirmwareSearch } from "../../services/query/search";
import { ExampleQueries } from "../../components/data/search-components/ModelExampleQueries";
import { Structure } from "../../components/data/search-components/Structure";
import useCustomToast from "../../utils/notification";
import { holdingBox } from "../../components/styles/styles";
import CustomHeader from "../../components/common/CustomHeader";
import ModelItemComponent from "../../components/data/search-components/ModelItemComponent";
import Form, { Btn } from "../../components/data/search-components/Form";

const AdvancedSearch = () => {
  const [queries, setQueries] = React.useState("");
  const [err, setErr] = React.useState("");
  const [response, setResponse] = React.useState<any[]>([]);
  const { errorToast, infoToast } = useCustomToast();
  const { mutate, isLoading } = useAdvancedFirmwareSearch({
    onSuccess: (res) => {
      if (res?.results.length < 1) {
        infoToast("Query doesn't match a firmware", 6000);
      }
      setResponse(res?.results);
      setQueries("");
    },
    onError: (err) => {
      setErr(err?.response?.data);
      errorToast(err?.response?.data);
    },
  });

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return JSON.parse(str);
  }

  const search = () => {
    const quer = isJson(queries);

    mutate({
      query: quer ? quer : queries,
      limit: 10,
      offset: 0,
      recursive: false,
      reverse: false,
    });
  };

  const textColorMode = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.mainTextColor"
  );

  return (
    <Box>
      <Box color={textColorMode}>
        <CustomHeader title="Advanced Search" />
      </Box>

      <Box>
        <Form
          value={queries}
          onChange={(e) => {
            setQueries(e.target.value);
          }}
          err={err}
        />
        <Btn isLoading={isLoading} onClick={search} />

        {response && (
          <Box sx={holdingBox}>
            <Grid
              my="1em"
              columnGap="2em"
              templateColumns={["repeat(1, 1fr)", null, null, "repeat(2, 1fr)"]}
            >
              {response &&
                response?.map((item: any, i: number) => (
                  <ModelItemComponent item={item} key={i} />
                ))}
            </Grid>
          </Box>
        )}

        <ExampleQueries />
        <Structure />
      </Box>
    </Box>
  );
};

export default AdvancedSearch;
