import React from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import PageLoader from "../../Loaders/PageLoader";
import { useGetGeneralItems } from "../../../services/query/dashboard";
import { scrollBarStyle } from "../../../utils/helpers";

export const Layout = ({ label, data }) => {
  return (
    <Flex justifyContent="space-between" mb="7px">
      <Box>{label}</Box>
      <Box color="#909090">{data}</Box>
    </Flex>
  );
};

const GeneralItems = () => {
  const { data: generalItems, isLoading, refetch } = useGetGeneralItems();
  const data = generalItems?.message;

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  // const bgColor = useColorModeValue("lightMode.secBgColor", "darkMode.secBgColor")
  const bgColor = "darkMode.mainBgColor"
  // const cardColor = useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor")
  const cardColor = "darkMode.cardBgColor"

  return (
    <Box mb="14px">
      <Box
        bg={bgColor}
        p="10px 0px 16px 17px"
        borderTopRadius="10px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        General Items
      </Box>
      <Box
        marginTop="-8px"
        bg={cardColor}
        p="10px 30px 2px 17px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="10px"
        h={{ base: "288px" }}
        overflowY="auto"
        sx={scrollBarStyle}
      >
        {isLoading && <PageLoader lineNo={4} />}
        {generalItems && (
          <Box w="100%" textAlign="left">
            <Layout label="Firmware Samples" data={data?.firmware_samples} />
            <Layout
              label="Firmware Total Size"
              data={data?.firmware_total_size}
            />
            <Layout
              label="Firmware TAverage Size"
              data={data?.firmware_average_size}
            />
            <Layout
              label="Unique Included Files"
              data={data?.unique_included_files}
            />
            <Layout
              label="Unique Included Files Total Sizeate"
              data={data?.unique_included_files_total_size}
            />
            <Layout
              label="Unique Included Files Average Size"
              data={data?.unique_included_files_average_size}
            />
            <Layout
              label="Statistics Generation Time"
              data={data?.statistics_generation_time}
            />
            <Layout label="Backend CPU Load" data={data?.backend_cpu_load} />
            <Layout
              label="Currently Analyzed Firmware"
              data={data?.currently_analyzed_firmware}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GeneralItems;
