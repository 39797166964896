import React from "react";
import {
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useColorModeValue,
  List,
  Box,
  Grid,
  Flex,
} from "@chakra-ui/react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AccordionProps } from "../../../../types";
import AccordionDataItemComponent from "./AccordionDataItemComponent";
import AccordionLinkDataItemComponent from "./AccordionLinkDataItemComponent";



const AccordionComponent = ({
  name,
  dataItems,
  linkDataItems,
  bgCustomBColor,
  detected,
}: AccordionProps) => {
  const textColorMode = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.mainTextColor"
  );

  const secBgColorMode = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );

  const borderColorMode = useColorModeValue(
    "lightMode.mainBgColor",
    "darkMode.mainBgColor"
  );
  const buttonBgColorMode = useColorModeValue("white", "darkMode.mainBgColor");
  return (
    <>
      <Grid
      w='100%'
      maxW='100%'
      bg="darkMode.grayBackground"
      padding={'1rem'}
      >
        {dataItems?.map((item, index) => 
        (
            
            <AccordionDataItemComponent
              key={index}
              title={item.title}
              data={item.data}
            />
          ))}

          {linkDataItems?.length === 0 ? (
            <Box>No Result</Box>
          ) : (
            <List>
              {linkDataItems?.map((item, index) => (
                <AccordionLinkDataItemComponent
                  key={index}
                  index={item.index}
                  link={item.link}
                />
              ))}
            </List>
          )}
      </Grid>
    </>
  );
};

export default AccordionComponent;
