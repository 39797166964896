import React from "react";
import { useQueryClient } from "react-query";
import { Box, Button, Flex, useColorMode } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import {
  useGetComparison,
  useDeleteComparison,
} from "../../services/query/comparison";
import { GET_COMPARISONS } from "../../services/queryKeys";
import TableLoader from "../../components/Loaders/TableLoader";
import useCustomToast from "../../utils/notification";
import CustomHeader from "../../components/common/CustomHeader";
import {
  File,
  GenInfo,
  Info,
  Layout,
  Result,
  ScanResult,
} from "../../components/data/Analysis/constants";

const AnalysisResult = () => {
  const { colorMode } = useColorMode();
  const { errorToast, successToast } = useCustomToast();
  const params = useParams();
  const navigate = useNavigate();
  const { uid1, uid2 } = params;
  const firmwares = [uid1?.toString(), uid2?.toString()];
  const { data, isLoading: isComparisonDataLoading } = useGetComparison(
    1,
    firmwares,
    false
  );

  const key1 = data && Object.keys(data?.result?.general?.device_name)[0];
  const key2 = data && Object.keys(data?.result?.general?.device_name)[1];
  const generalInfo =
    data &&
    Object.keys(data?.result?.general)?.map((el) => ({
      property: el,
      first: data?.result?.general[el][key1],
      second: data?.result?.general[el][key2],
    }));

  const { mutate } = useDeleteComparison({
    onSuccess: () => {
      successToast("Comparison deleted successfully");

      setTimeout(() => {
        navigate("/analysis/compared-list");
      }, 2000);
    },
    onError: (err) => {
      errorToast(err.response.data.detail);
    },
  });

  const deleteCompare = () => {
    mutate({
      id: data.id,
    });
  };

  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.invalidateQueries(GET_COMPARISONS);
  }, [queryClient, GET_COMPARISONS]);

  return (
    <Box w="100%">
      {isComparisonDataLoading && (
        <Box
          p={6}
          borderWidth="2px"
          borderColor={
            colorMode === "light"
              ? "lightMode.secBgColor"
              : "darkMode.cardBgColor"
          }
        >
          <TableLoader />
        </Box>
      )}
      <Box mx="auto" my="30px">
        <Flex
          align={"center"}
          justify="space-between"
          gap={4}
          my={4}
          alignItems="center">
          <CustomHeader title="Comparison Results" />
          <Button
            bg={'red'}
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this Comparison?"
                )
              ) {
                deleteCompare();
              }
            }}
            rightIcon={<AiFillDelete />}
          >
            Delete
          </Button>
        </Flex>
        <Layout>
          <File label="Firmware" />
          <Result data={data?.firmwares[0]?.device_name} />
          <Result data={data?.firmwares[1]?.device_name} />
        </Layout>
        <Layout>
          <File label="Scan UID" />
          <ScanResult
            onClick={() =>
              navigate(`/analysis/${data?.firmwares[0]?.scan_uid}`)
            }
            data={data?.firmwares[0]?.scan_uid}
          />
          <ScanResult
            onClick={() =>
              navigate(`/analysis/${data?.firmwares[1]?.scan_uid}`)
            }
            data={data?.firmwares[1]?.scan_uid}
          />
        </Layout>
        <GenInfo>General Information</GenInfo>

        {generalInfo?.map((firmware) => (
          <Box
            key={firmware.property}
            display={"flex"}
            textAlign={"left"}
            border={"1px"}
            borderColor="gray"
          >
            <Info w="20%" data={firmware?.property} />
            <Info w="40%" data={firmware?.first} />
            <Info w="40%" data={firmware?.second} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AnalysisResult;
