import React, { useEffect, useState } from "react";
import { Box, useColorModeValue, Grid, Button, Flex, Spinner, InputGroup, InputLeftElement, Input, useDisclosure } from "@chakra-ui/react";
import { patchManagerQuery, updatePatchesQuery }  from "../../services/query/patchManager";
import useCustomToast from "../../utils/notification";
import CustomHeader from "../../components/common/CustomHeader";
import PatchesTable from "../../components/data/ManageEngine/PatchesTable";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'

import { 
    IoIosCheckmarkCircle,
    IoIosCloseCircle,
    IoIosSearch
} from "react-icons/io";
import { authenticateOTPService, checkOTPService, sendOTPService } from "../../services/api/patchManager";

const ManageEngine = () => {
    const [allData, setAllData] = useState([])
    const [patches, setPatches] = useState([]);
    const [search, setSearch] = useState("");
    const [err, setErr] = useState("");
    const { errorToast, infoToast } = useCustomToast();
    const [OTP, setOTP] = useState("")
    const [tokenExpired, setTokenExpired] = useState(true)

    const [refreshLoading, setRefreshLoading] = useState(false)

    const { isOpen, onOpen, onClose } = useDisclosure()

    const { isLoading: isUpdateLoading, refetch: updateRefetch, error: updateError } = updatePatchesQuery();

    const { data, isLoading, refetch, error } = patchManagerQuery({
        onSuccess: (res) => {
            console.log(res)
            setPatches(res);
            setAllData(res)
        },
        onError: (err) => {
            setErr(err);
            errorToast(err);
        },
    });

    const handleOTP = (ev) => {
        setOTP(ev.target.value)
    }

    const handleSearch = (ev) => {
        setSearch(ev.target.value)
        setPatches(allData.filter((item) => {
            console.log()
            for (const elem of Object.getOwnPropertyNames(item)) {
                if (String(item[elem]).toLowerCase().includes(search.toLowerCase())) {
                    return true
                }
            }
            return false
        }))
    }

    const checkOtpToken = async () => {
        try {
            const res: any = await checkOTPService()
            console.log(res)
            if (res.status === 200) {
                infoToast(res.data.data.message, 2000)
                setTokenExpired(false)
            }
            else if (res.response.status === 400) {
                errorToast("OTP Token has expired. You should authenticate again.")
                setTokenExpired(true)
            } else {
                errorToast("OTP Token has expired. You should authenticate again.")
                setTokenExpired(true)
            }
        } catch (error) {
            console.log(error)
            errorToast(String(error))
        }
    }

    const sendOTP = () => {
        console.log("SendOTP")
        onOpen()
        sendOTPService()
            .then((res) => res)
            .then((res: any) => {
                console.log(res)
                if (res.status === 200) {
                    infoToast(res.data.data.message, 1500)
                }
                return res
            })
            .catch((error) => {
                console.log(error)
            })

        setTimeout(()=>{
            onClose()
            setOTP("")
        }, 1000*60*5) // 5 minutes
    }

    const checkOTP = () => {
        console.log("CheckOTP:", OTP)

        authenticateOTPService(OTP)
            .then((res) => res)
            .then((res: any) => {
                console.log(res)
                if (res.status === 200) {
                    infoToast("Token Verified", 2000)
                    onClose()
                } else if (res.response.status === 400) {
                    errorToast("Error authenticating OTP Code")
                } else if (res.response.status === 500) {
                    errorToast("Error authenticating OTP Code")
                } else {
                    errorToast("Error authenticating OTP Code")
                }
                 
                return res
            })
            .catch((error) => {
                console.log(error)
                errorToast(String(error))
            })
    }

    useEffect(()=>{
        refetch()
            .then(res => res.data)
            .then(res => {
                setPatches(res)
                return res
            })
            .catch(err => {
                errorToast(String(err))
            })  

        checkOtpToken()
            .then(res => res)
            .then(res => res)
            .catch(err => err)
    }, [])

    const getPatches = async () => {
        setRefreshLoading(true)
        try {
            await updateRefetch()

            const res = await refetch()
            setPatches(res.data)
            infoToast("Patches reloaded", 1500)
            setRefreshLoading(false)

        } catch (error) {
            errorToast(String(error))
        }
        setRefreshLoading(false)
        return true
    }

    const buttonBgColor = "gray" 

    const textColorMode = useColorModeValue(
        "lightMode.mainTextColor",
        "darkMode.mainTextColor"
    );

    return (
        <Box>
            <Box mt={2} color={textColorMode}>
                <Flex color='white' direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <CustomHeader title="Patch Manager" />
                    <Flex color='white' direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <div style={{marginRight: "3px"}}>
                            {tokenExpired ? <IoIosCloseCircle color="red"/> : <IoIosCheckmarkCircle color="green"/>}
                        </div>
                        
                        <Button bg='#0e7490' mr={3} disabled={!tokenExpired} onClick={sendOTP}>Send me OTP</Button>
                        <Button bg={buttonBgColor} isLoading={refreshLoading} onClick={getPatches}>Refresh</Button>
                    </Flex>
                    
                </Flex>
                
            </Box>
            <br></br>
            {isLoading && <div>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='gray.500'
                    size='xl'
                />
            </div>
            
            }
            {patches &&
                <div>
                    <InputGroup>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<IoIosSearch color='gray.300' />}
                            
                        />
                        <Input 
                            type='text' placeholder='Search' 
                            value={search}
                            onChange={handleSearch}
                        />
                    </InputGroup>
                    <br></br>
                    <PatchesTable
                        title={"Patches Downloaded"}
                        renderData={patches}
                        refreshMethod={getPatches}
                    />
                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>OTP Authentication</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <div>OTP Token</div>
                                <br></br>
                                <Input 
                                    type='text' 
                                    placeholder='OTP' 
                                    value={OTP}
                                    onChange={handleOTP}
                                />
                            </ModalBody>

                            <ModalFooter>
                                <Button colorScheme='blue' mr={3} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button variant='ghost' onClick={checkOTP}>Send</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </div>
            
            }
    </Box>
  );
};

export default ManageEngine;
