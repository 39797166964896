import { Flex, Text, Icon } from "@chakra-ui/react";
import React from "react";
import { BiChevronRight, BiChevronDown } from "react-icons/bi";

const FirmwareCustomDropdown = ({ show, setShow }) => {
  return (
    <Flex
      w="80%"
      mx="auto"
      h="60px"
      border="none"
      bgColor="darkMode.cardBgColor"
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
      borderRadius="8px"
      onClick={() => setShow(!show)}
      fontWeight="600"
      mt="25px"
    >
      <Text marginLeft="20px">Uploaded Firmwares</Text>

      <Icon
        fontSize="25px"
        marginRight="20px"
        as={show ? BiChevronDown : BiChevronRight}
      />
    </Flex>
  );
};

export default FirmwareCustomDropdown;
