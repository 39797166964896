import { Box } from "@chakra-ui/react";
import React from "react";

const SmallBox = () => {
  return <Box h="45px" w="8px" bg="rgb(82, 86, 89)" />;
};

const BigBox = () => {
  return <Box h="45px" w="13px" bg="rgb(82, 86, 89)" mx="7px" />;
};

const BoxDesign = () => {
  return (
    <Box display="flex" my="15px">
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
      <SmallBox />
      <BigBox />
    </Box>
  );
};

export default BoxDesign;
