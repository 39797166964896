import { VIRUS_LIST, VIRUS_REPORT, VIRUS_UPLOAD } from "../apiUrl";
import axiosInstance from "../axiosInstance";

export const getVirusReport = async ({ queryKey }) => {
  const [, sha] = queryKey;

  const body = {
    file_hash: sha,
  };

  const res = await axiosInstance.post(VIRUS_REPORT, body);
  return res.data;
};

export const getVirusList = async () => {
  const res = await axiosInstance.get(VIRUS_LIST);
  return res.data;
};

export const postVirus = async (body) => {
  const res = await axiosInstance.post(VIRUS_UPLOAD, body);
  return res.data;
};
