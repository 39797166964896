import DataTable from 'react-data-table-component';

const bgColor = '#33384A'
const textColor = "white"

const customStyles = {
    rows: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
    },
    table: {
		style: {
			backgroundColor: bgColor,
            color: textColor,
		},
	},
    headRow: {
        style: {
			backgroundColor: bgColor,
            color: textColor,
		},
    },
    rowCells: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
    },
    headCells: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
    },
    header: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
    },
    pagination: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
        pageButtonsStyle: {
			borderRadius: '50%',
			height: '40px',
			width: '40px',
			padding: '8px',
			margin: 'px',
			cursor: 'pointer',
			transition: '0.4s',
			color: textColor,
            fill: "white",
			backgroundColor: '#4F5776',
        },
    },
    cells: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none",
            width: "200px",
        },
    },
    noData: {
		style: {
			display: 'flex',
            border: "none",
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: bgColor,
            color: textColor,
		},
	},
};

// const truncateText = (text: string)  => {
//     if (text.length > 35) {
//         return text.slice(0, 35) + "..."
//     } else {
//         return text
//     }
// }

const CodeAnalysisTable = ({renderData, title}) => {
    const columns: any = [];
    if (renderData && renderData?.length > 0) {
        const properties = Object.getOwnPropertyNames(renderData[0])
    

        for (const prop of properties) {
            columns.push({
                name: prop,
                selector: (row) => row[prop],
                sortable: true,
                // cell: (row) => {
                //     // console.log(value)
                //     console.log(row)
                //     // const props = Object.getOwnPropertyNames(param)
                //     // console.log(props)
                //     return (
                //         <Flex color='white'>
                //             {/* {props && props.map((elem)=>{
                //                 return (
                //                     <Center w='100px' bg='green.500'>
                //                         <Text>{truncateText(param[elem])}</Text>
                //                     </Center>
                //                 )
                //             })} */}
                            
                //         </Flex>
                //     )
                // }
            })
        }
        const data = renderData

        return (
            <DataTable
                title={title}
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
            />
        ) 
    } else {
        return (<p>No data to show</p>)
    }
    
}

export default CodeAnalysisTable
