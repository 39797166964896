import React from "react";
import { Text, Box, Heading } from "@chakra-ui/react";
import { holdingBox } from "../../styles/styles";

export const ExampleQueries = () => {
  return (
    <Box sx={holdingBox}>
      <Heading
        as="h4"
        fontSize="23px"
        fontWeight="semibold"
        textAlign="center"
        my={"16px"}
      >
        Example Queries
      </Heading>

      <Box my="10px">
        <Text fontSize={"15px"}>Simple:</Text>
        <Text fontSize={"13px"}>
          {"{"}"device_class": "Router", "vendor": "AVM"{"}"}
        </Text>
        <Text fontSize={"14px"} opacity="0.7">
          Select firmware files based on specific vendor and device class
        </Text>
      </Box>

      <Box my="10px">
        <Text fontSize={"15px"}>With regular expression:</Text>
        <Text fontSize={"13px"}>
          {"{"}"device_name": {"{"}"$options": "si", "$regex": "Fritz.+Box"
          {"}"}
          {"}"}
        </Text>
        <Text fontSize={"14px"} opacity="0.7">
          Match field with regular expression. Options mean interpret dot as
          wildcard (s) and case insensitive (i){" "}
        </Text>
      </Box>

      <Box my="10px">
        <Text fontSize={"15px"}>With arithmetic:</Text>
        <Text fontSize={"13px"}>
          {"{"}"processed_analysis.file_type.mime": "application/x-executable",
          "size": {"{"}"$lt": 1337{"}"}
          {"}"}
        </Text>
        <Text fontSize={"14px"} opacity="0.7">
          Select only executables that are smaller then or equal 1337 bytes
        </Text>
      </Box>

      <Box my="10px">
        <Text fontSize={"15px"}>With existence check:</Text>
        <Text fontSize={"13px"}>
          {"{"}"vendor": {"{"}"$exists": true{"}"}, "size": {"{"}"$lt": 4200000
          {"}"}
          {"}"}
        </Text>
        <Text fontSize={"14px"} opacity="0.7">
          Select files that have a vendor field (outer container) and are
          smaller than 4.2 MB
        </Text>
      </Box>

      <Box my="10px" fontSize={"14px"}>
        For further usage also see{" "}
        <Text
          as="a"
          href="https://www.mongodb.com/docs/v3.6/tutorial/query-documents/"
          color="blue"
        >
          MongoDB documentation
        </Text>{" "}
        or simply ask for help at our{" "}
        <Text as="a" href="https://gitter.im/FACT_core/community" color="blue">
          Gitter Channel
        </Text>
      </Box>
    </Box>
  );
};
