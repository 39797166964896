import { Box, Flex } from "@chakra-ui/react";
import { PDFProps } from "../../../../types";

export const Layout = ({ children, bg, mt }: PDFProps) => {
  return (
    <Box border="1px solid #DCDCDC" textAlign="center" py="4px" bg={bg} mt={mt}>
      {children}
    </Box>
  );
};

export const Layout1 = ({ children, color }) => {
  return (
    <Flex
      boxShadow="lg"
      py="5"
      fontSize="15px"
      color={"#fff"}
      bg={color === "white" ? "#1C2A35" : "#243641"}
      alignItems="center"
    >
      {children}
    </Flex>
  );
};

export const Layout2 = ({ children, bg }) => {
  return (
    <Flex
      boxShadow="lg"
      py="5"
      fontSize="15px"
      color={"#fff"}
      bg={bg}
      alignItems="center"
    >
      {children}
    </Flex>
  );
};
