import { Box, Button, Textarea, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import {
  useEditComment,
  useGetComments,
} from "../../../../services/query/analysis";
import useCustomToast from "../../../../utils/notification";

const EditComment = ({ edit, id, text, onClick, onChange, setText }) => {
  const { refetch } = useGetComments();
  const { mutate: editMutate, isLoading: editLoading } = useEditComment({
    onSuccess: (res) => {
      successToast(res.message);
      refetch();
      setText("");
    },
    onError: (err) => {
      errorToast(err.message);
      errorToast(err?.response?.data?.message);
    },
  });
  const { errorToast, successToast } = useCustomToast();
  const editComment = () => {
    editMutate({
      comment: text,
      id,
    });
  };

  const btn = useColorModeValue("lightMode.secBgColor", "lightMode.btnBgColor");
  const border = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );
  const color = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.labelTextColor"
  );
  const textarea = useColorModeValue(
    "lightMode.cardBgColor",
    "darkMode.cardBgColor"
  );

  return (
    <>
      {edit && (
        <Box
          height={"auto"}
          display="flex"
          alignItems={"center"}
          borderRadius="4px"
          justifyContent="space-between"
          w="100%"
        >
          <Box w="95%" mx={"auto"}>
            <Box display={"flex"} mt="6">
              <Button
                h={"40px"}
                width={"125px"}
                fontSize={[13, 13, "15px", "15px"]}
                paddingX={"9"}
                py="3"
                fontWeight={"500"}
                bgColor="#d9e3ee"
                onClick={onClick}
                background={btn}
              >
                Comment
              </Button>
              <Box w={"9"} />

              <Textarea
                placeholder="Edit your comment here"
                size={"xs"}
                borderRadius="5"
                variant={"unstyled"}
                border="1px"
                borderColor={border}
                bgColor={textarea}
                color={color}
                pl={"8px"}
                value={text}
                onChange={onChange}
                data-testid={"editinput"}
              />
            </Box>

            <Box display={"flex"} mt="10" mb="5">
              <Button
                h={"40px"}
                fontSize={[13, 13, "15px", "15px"]}
                paddingX={"9"}
                py="3"
                fontWeight={"500"}
                bgColor="#d9e3ee"
                width={"107px"}
                opacity="0"
                cursor={"default"}
                display={["none", "block", "block", "block"]}
              >
                Edit
              </Button>

              <Box w={"9"} display={["none", "block", "block", "block"]} />

              <Button
                h={"40px"}
                fontSize={[13, 13, "15px", "15px"]}
                paddingX={"9"}
                py="3"
                fontWeight={"500"}
                bgColor="#d9e3ee"
                width={"125px"}
                mx={["auto", "0", "0", "0"]}
                background={btn}
                isLoading={editLoading}
                onClick={editComment}
              >
                Edit
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default EditComment;
