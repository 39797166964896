import { Box } from "@chakra-ui/react";
import StatusCards from "../../components/data/SystemHealth/StatusCards";
import Plugins from "../../components/data/SystemHealth/Plugins";
import { useGetSystemStatus } from "../../services/query/systemStatus";
import LoadingComponent from "../../components/Loaders/LoadingComponent";
import ErrorComponent from "../../components/data/ErrorComponent";
import CustomHeader from "../../components/common/CustomHeader";

const SystemsHealth = () => {
  const { data, error, isLoading, refetch } = useGetSystemStatus();
  console.log(data)
  return (
    <div>
      <Box display="flex" alignItems="center">
        <CustomHeader title="System Health" />
      </Box>
      {isLoading && <LoadingComponent />}
      <Box my="10">
        {error && <ErrorComponent message={error.message} reload={refetch} />}

        {data && (
          <>
            <StatusCards data={data} />
            <Plugins data={data} />
          </>
        )}
      </Box>
    </div>
  );
};

export default SystemsHealth;
