import { useState } from "react";
import { Box } from "@chakra-ui/react";
import useCustomToast from "../../../utils/notification";
import { useChangeManageEngineCreds } from "../../../services/query/user";
import { UserInput } from "../../CustomInput/UserInput";
import { Btn, Divide, Header } from "./Layout";

const ManageEngineSettings = () => {
  const { errorToast, successToast } = useCustomToast();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiURL, setApiURL] = useState("");
  const [apiVersion, setApiVersion] = useState("");
  const [authType, setAuthType] = useState("");
  const [domainName, setDomainName] = useState("");
  const [trustedApiKey, setTrustedApiKey] = useState("");
  const [trustedApiURL, setTrustedApiURL] = useState("");

  const { mutate, isLoading: isSubmitting } = useChangeManageEngineCreds({
    onSuccess: () => {
      successToast("Update successful");
      setUsername("");
      setPassword("");
      setApiURL("");
      setApiVersion("");
      setAuthType("");
      setDomainName("");
      setTrustedApiKey("");
      setTrustedApiURL("")
    },
    onError: (err) => {
      errorToast(err);
    },
  });

  const changeCredsHandler = async (event) => {
    event.preventDefault();

    mutate({
      patch_engine_username: username,
      patch_engine_password: password,
      patch_engine_api_url: apiURL,
      patch_engine_api_version: apiVersion,
      patch_engine_auth_type: authType,
      patch_engine_domain_name: domainName,
      trusted_management_api_key: trustedApiKey,
      trusted_management_url: trustedApiURL,
    });
  };

  return (
    <div>
        <Header title="Manage Engine" label="Manage Engine Creds" />
        <Divide />
        <Box
            px={["20px", "20px", "10", "20"]}
            py={["20px", "20px", "10", "10"]}
            as="form"
            onSubmit={changeCredsHandler}
        >
          <UserInput
            label="Username"
            value={username}
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            isDisabled={false}
            testid="user"
          />
          <UserInput
            label="Password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            isDisabled={false}
            testid="pass"
          />
          <UserInput
            label="Api URL"
            value={apiURL}
            type="text"
            onChange={(e) => setApiURL(e.target.value)}
            isDisabled={false}
            testid="user"
          />
          <UserInput
            label="Api Version"
            value={apiVersion}
            type="text"
            onChange={(e) => setApiVersion(e.target.value)}
            isDisabled={false}
            testid="user"
          />
          <UserInput
            label="Auth Type"
            value={authType}
            type="text"
            onChange={(e) => setAuthType(e.target.value)}
            isDisabled={false}
            testid="user"
          />
          <UserInput
            label="Domain Name"
            value={domainName}
            type="text"
            onChange={(e) => setDomainName(e.target.value)}
            isDisabled={false}
            testid="pass"
          />
          <UserInput
            label="Trusted Api Key"
            value={trustedApiKey}
            type="text"
            onChange={(e) => setTrustedApiKey(e.target.value)}
            isDisabled={false}
            testid="user"
          />
          <UserInput
            label="Trusted Api URL"
            value={trustedApiURL}
            type="text"
            onChange={(e) => setTrustedApiURL(e.target.value)}
            isDisabled={false}
            testid="user"
          />
          <Btn isLoading={isSubmitting} />
        </Box>
    </div>
  );
};

export default ManageEngineSettings;
