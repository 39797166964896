import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import React from "react";

const PdfScans = ({ data }) => {
  if (data === undefined) {
    return (
        <></>
    ) 
  }
  return (
    <Box w="95%" mx="auto">
      <Box
        border="1px solid #DCDCDC"
        textAlign="center"
        py="4px"
        backgroundImage="linear-gradient(to right, #500F2A, #243641)"
      >
        <Text mb="5"> Scans </Text>
      </Box>

      <Box>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th w="30%" color="white"></Th>
                <Th
                  w="17.5%"
                  color="white"
                  fontSize="15px"
                  textTransform={"capitalize"}
                >
                  Positives
                </Th>
                <Th
                  w="17.5%"
                  color="white"
                  fontSize="15px"
                  textTransform={"capitalize"}
                >
                  Percent
                </Th>
                <Th
                  w="17.5%"
                  color="white"
                  fontSize="15px"
                  textTransform={"capitalize"}
                >
                  Progress
                </Th>
                <Th
                  w="17.5%"
                  color="white"
                  fontSize="15px"
                  textTransform={"capitalize"}
                >
                  Status
                </Th>
                <Th
                  w="17.5%"
                  color="white"
                  fontSize="15px"
                  textTransform={"capitalize"}
                >
                  Total
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {data.length >= 1 &&
                data?.map((item, i) => (
                  <Tr key={i}>
                    <Td w="30%">{item?.name}</Td>
                    <Td w="17.5%" borderLeft="1px solid white">
                      {item?.positives || "No Result"}
                    </Td>
                    <Td w="17.5%" borderLeft="1px solid white">
                      {item?.percent || "No Result"}
                    </Td>
                    <Td w="17.5%" borderLeft="1px solid white">
                      {item?.progress || "No Result"}
                    </Td>
                    <Td w="17.5%" borderLeft="1px solid white">
                      {item?.status || "No Result"}
                    </Td>
                    <Td w="17.5%" borderLeft="1px solid white">
                      {item?.total || "No Result"}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PdfScans;
