import React from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import {
  useDeleteComment,
  useGetComments,
} from "../../../../services/query/analysis";
import useCustomToast from "../../../../utils/notification";

const ShowComment = ({ setEdit, setText, setId, edit }) => {
  const { data, refetch } = useGetComments();
  const { errorToast, successToast } = useCustomToast();
  const border = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );
  const color = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.labelTextColor"
  );

  const { mutate: deleteMutate } = useDeleteComment({
    onSuccess: (res) => {
      successToast(res.message);
      refetch();
    },
    onError: (err) => {
      errorToast(err?.response?.data?.message);
    },
  });

  const deleteComment = (i) => {
    deleteMutate({
      id: i,
    });
  };

  return (
    <>
      {!data || data?.length < 1 ? (
        <Box display={"flex"} w="95%" mx={"auto"} py="5" color={color}>
          <Box
            fontSize={"12"}
            fontWeight="500"
            px="3"
            py="2"
            border={"1px"}
            borderColor={border}
            w="25%"
          >
            <Text>Anonymous</Text>
            <Text>20/04/2022</Text>
          </Box>
          <Box
            fontSize={"12"}
            fontWeight="500"
            px="3"
            py="2"
            borderTop={"1px"}
            borderBottom={"1px"}
            borderRight={"1px"}
            borderColor={border}
            w="75%"
            data-testid="comments"
          >
            <Text>This firmware has no comment</Text>
          </Box>
        </Box>
      ) : (
        data?.map((item, i) => (
          <Box
            display={"flex"}
            flexDirection="column"
            w="95%"
            mx={"auto"}
            py="1"
            color={color}
            key={i}
          >
            <Box display={"flex"} w="100%" mx={"auto"} py="1" color={color}>
              <Box
                fontSize={"12"}
                fontWeight="500"
                px="3"
                py="2"
                border={"1px"}
                borderColor={border}
                width="20%"
              >
                <Text>{item?.author ? item?.author : "anonymous"}</Text>
                <Text>{new Date(item?.created_at).toLocaleDateString()}</Text>
              </Box>
              <Box
                fontSize={"12"}
                fontWeight="500"
                px="3"
                py="2"
                borderTop={"1px"}
                borderBottom={"1px"}
                borderRight={"1px"}
                borderColor={border}
                w="70%"
              >
                <Text h="100%" w="100%" display={"flex"} alignItems={"center"}>
                  {item?.comment}
                </Text>
              </Box>
              <Box
                fontSize={"17px"}
                fontWeight="500"
                borderTop={"1px"}
                borderBottom={"1px"}
                borderRight={"1px"}
                borderColor={border}
                w="10%"
                display="flex"
                flexDirection="column"
                alignItems={"center"}
                justifyContent="center"
              >
                <Box
                  h="50%"
                  display="flex"
                  justifyContent="center"
                  borderBottom="1px"
                  borderColor={border}
                  width="100%"
                  alignItems={"center"}
                >
                  <AiFillEdit
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEdit(!edit);
                      setId(item?.id);
                      setText(item?.comment);
                    }}
                  />
                </Box>

                <Box
                  h="50%"
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  alignItems={"center"}
                >
                  <AiFillDelete
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to delete this comment?"
                        )
                      ) {
                        deleteComment(item?.id);
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ))
      )}
    </>
  );
};

export default ShowComment;
