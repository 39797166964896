import { useQuery, useMutation } from "react-query";
import { 
  PATCH_MANAGER,
  PATCH_APPROVE,
  PATCH_RUN_FULL_ANALYSIS,
  PATCH_SCAN_MALWARE,
  PATCH_DISAPPROVE,
  PATCH_UPDATE,
} from "../queryKeys";
import { authenticateOTPService, checkOTPService, patchManager, patchManagerApproveService, patchManagerDisapproveService, patchManagerRunFullAnalysisService, patchManagerScanForMalwareService, patchUpdate, sendOTPService } from "../api/patchManager";
import { PATCHES_AUTHENTICATE_OTP, PATCHES_CHECK_TOKEN, PATCHES_SEND_TOP } from "../apiUrl";


export const patchManagerQuery = (options = {}) => {
  const { data, isLoading, error, refetch } = useQuery(
    [PATCH_MANAGER],
    patchManager,
    {
      ...options,
    }
  );

  return { data, isLoading, error, refetch };
};

export const updatePatchesQuery = (options = {}) => {
  const { data, isLoading, error, refetch } = useQuery(
    [PATCH_UPDATE],
    patchUpdate,
    {
      ...options,
    }
  );

  return { data, isLoading, error, refetch };
};

export const usePatchManagerApprove = (options = {}) => {
  const { mutate, isLoading } = useMutation(patchManagerApproveService, {
    mutationKey: PATCH_APPROVE,
    ...options,
  });
  return { mutate, isLoading };
};


export const usePatchManagerDisapprove = (options = {}) => {
  const { mutate, isLoading } = useMutation(patchManagerDisapproveService, {
    mutationKey: PATCH_DISAPPROVE,
    ...options,
  });
  return { mutate, isLoading };
};


export const usePatchManagerRunFullAnalysis = (options = {}) => {
  const { mutate, isLoading } = useMutation(patchManagerRunFullAnalysisService, {
    mutationKey: PATCH_RUN_FULL_ANALYSIS,
    ...options,
  });
  return { mutate, isLoading };
};


export const usePatchManagerScanForMalware = (options = {}) => {
  const { mutate, isLoading } = useMutation(patchManagerScanForMalwareService, {
    mutationKey: PATCH_SCAN_MALWARE,
    ...options,
  });
  return { mutate, isLoading };
};


export const sendOTPQuery = (options = {}) => {
  const { data, isLoading, error, refetch } = useQuery(
    [PATCHES_SEND_TOP],
    sendOTPService,
    {
      ...options,
    }
  );

  return { data, isLoading, error, refetch };
};

export const authOTPQuery = (options = {}) => {
  const { data, isLoading, error, refetch } = useQuery(
    [PATCHES_AUTHENTICATE_OTP],
    authenticateOTPService,
    {
      ...options,
    }
  );

  return { data, isLoading, error, refetch };
};

export const checkOTPQuery = (options = {}) => {
  const { data, isLoading, error, refetch } = useQuery(
    [PATCHES_CHECK_TOKEN],
    checkOTPService,
    {
      ...options,
    }
  );

  return { data, isLoading, error, refetch };
};