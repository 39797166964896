import { Box, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useGetBinarySearchDetails } from "../../services/query/search";
import { dateFormat } from "../../utils/helpers";
import CustomHeader from "../../components/common/CustomHeader";

const BinarySearchDetails = () => {
  const { search_id } = useParams();
  const { data } = useGetBinarySearchDetails(search_id as string);
  return (
    <Box px={5} my={5}>
      <CustomHeader title="Binary Search Details" />
      <Text my={5}>Search ID: {data?.search_id}</Text>
      <Box my={4}>
        {data?.firmware === null ? (
          <Text></Text>
        ) : (
          <>
            <Text fontWeight={600}>Firmware Details</Text>
            <Text>Scan UID: {data?.firmware?.scan_uid}</Text>
            <Text>Device Name: {data?.firmware?.device_name}</Text>
            <Text>Device Class: {data?.firmware?.device_class}</Text>
            <Text>Vendor: {data?.firmware?.vendor}</Text>
            <Text>Created at: {dateFormat(data?.firmware?.created_at)}</Text>
          </>
        )}
      </Box>
      <Box>
        <Text fontWeight={600}>Yara Search Details</Text>
        <Text fontWeight={600}>Rule:</Text>
        <Text>{data?.yara_rule}</Text>
        <Text>Description: {data?.short_description}</Text>
        <Text>Status: {data?.status}</Text>
        <Text>Created at: {dateFormat(data?.created_at)}</Text>
        <Text fontWeight={600}>Search Result:</Text>
        <Text>
          {Object.values(data?.search_results || {})?.map((dat: any, idx) => (
            <div key={idx}>{dat}</div>
          ))}
        </Text>
      </Box>
    </Box>
  );
};

export default BinarySearchDetails;
