import React from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import TableLoader from "../../components/Loaders/TableLoader";
import CustomHeader from "../../components/common/CustomHeader";
import { useGetThreatDetails } from "../../services/query/dashboard";
import { dateFormat } from "../../utils/helpers";

const AllThreat = () => {
  const { data: threatDetails, isLoading: isthreatLoading } =
    useGetThreatDetails();
  const navigate = useNavigate();

  return (
    <Box w="100%">
      <CustomHeader title="All Threat Details" />

      <TableContainer
        borderRadius="8px"
        shadow={"md"}
        backgroundColor={useColorModeValue("#E3ECF6", "#1C2A35")}
      >
        {isthreatLoading && <TableLoader />}
        {threatDetails?.length > 0 ? (
          <Table variant={"simple"}>
            <Thead fontWeight={"700"} position="sticky" top={0}>
              <Tr>
                <Th>Device Name</Th>
                <Th>Device Class</Th>
                <Th>Vendor</Th>
                <Th>Upload Date</Th>
                <Th>Threat count</Th>
                <Th>cve count</Th>
                <Th>cwe count</Th>
                <Th>Malware count</Th>
              </Tr>
            </Thead>
            <Tbody>
              {threatDetails?.map((el, i) => (
                <Tr
                  key={i}
                  cursor="pointer"
                  onClick={() =>
                    navigate(`/analysis/${el?.firmware?.scan_uid}`)
                  }
                >
                  <Td>{el?.firmware?.device_name}</Td>
                  <Td>{el?.firmware?.device_class}</Td>
                  <Td>{el?.firmware?.vendor}</Td>
                  <Td>{dateFormat(el?.firmware?.created_at)}</Td>
                  <Td>{el?.threat_detail?.threat_count}</Td>
                  <Td>{el?.threat_detail?.cve_count}</Td>
                  <Td>{el?.threat_detail?.cwe_count}</Td>
                  <Td>{el?.threat_detail?.malware_count}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Center fontWeight="bold" h={"100%"}>
            No Data
          </Center>
        )}
      </TableContainer>
    </Box>
  );
};

export default AllThreat;
