import { useMutation } from "react-query";
import { createTicket } from "../api/uploadTicket";
import { UPLOAD_TICKET } from "../queryKeys";

export const useUploadTicket = (options = {}) => {
  const { mutate, isLoading } = useMutation(createTicket, {
    mutationKey: UPLOAD_TICKET,
    ...options,
  });

  return { mutate, isLoading };
};