import { Box, Grid, Heading } from "@chakra-ui/react";

import { SystemsPlugins } from "./SystemsPlugins";

const Plugins = ({ data }: any) => {
  const bgColor = "darkMode.cardBgColor"

  return (
    <div>
      <Box>
        <Box textAlign="center" my="10">
          <Heading
            as="h4"
            fontSize={["x-large", "xx-large", "xx-large", "xx-large"]}
            fontWeight="500"
            lineHeight="1.2"
            m="auto"
            borderTop="1px"
            borderBottom="1px"
            w={["70%", "50%", "50%", "30%"]}
          >
            Analysis Plugins
          </Heading>
        </Box>
        <Grid
          px={[2, 9, 10, 10]}
          py={[2, 3, 5, 5]}
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ]}
          gap={6}
        >
          <SystemsPlugins
            description={data?.plugins?.binwalk?.description}
            version={data?.plugins?.binwalk?.version}
            name={"binwalk"}
          />
          <SystemsPlugins
            description={data?.plugins?.cpu_architecture?.description}
            version={data?.plugins?.cpu_architecture?.version}
            name={"cpu architecture"}
          />
          <SystemsPlugins
            description={data?.plugins?.crypto_hints?.description}
            version={data?.plugins?.crypto_hints?.version}
            name={"crypto hints"}
          />
          <SystemsPlugins
            description={data?.plugins?.crypto_material?.description}
            version={data?.plugins?.crypto_material?.version}
            name={"crypto material"}
          />
          <SystemsPlugins
            description={data?.plugins?.cve_lookup?.description}
            version={data?.plugins?.cve_lookup?.version}
            name={"cve lookup"}
          />
          <SystemsPlugins
            description={data?.plugins?.cwe_checker?.description}
            version={data?.plugins?.cwe_checker?.version}
            name={"cwe checker"}
          />
          <SystemsPlugins
            description={data?.plugins?.device_tree?.description}
            version={data?.plugins?.device_tree?.version}
            name={"device tree"}
          />
          <SystemsPlugins
            description={data?.plugins?.elf_analysis?.description}
            version={data?.plugins?.elf_analysis?.version}
            name={"elf analysis"}
          />
          <SystemsPlugins
            description={data?.plugins?.exploit_mitigations?.description}
            version={data?.plugins?.exploit_mitigations?.version}
            name={"exploit mitigations"}
          />
          <SystemsPlugins
            description={data?.plugins?.file_hashes?.description}
            version={data?.plugins?.file_hashes?.version}
            name={"file hashes"}
          />
          <SystemsPlugins
            description={data?.plugins?.file_system_metadata?.description}
            version={data?.plugins?.file_system_metadata?.version}
            name={"file system metadata"}
          />
          <SystemsPlugins
            description={data?.plugins?.file_type?.description}
            version={data?.plugins?.file_type?.version}
            name={"file type"}
          />
          <SystemsPlugins
            description={data?.plugins?.hardware_analysis?.description}
            version={data?.plugins?.hardware_analysis?.version}
            name={"hardware analysis"}
          />
          <SystemsPlugins
            description={data?.plugins?.hashlookup?.description}
            version={data?.plugins?.hashlookup?.version}
            name={"hashlookup"}
          />
          <SystemsPlugins
            description={data?.plugins?.information_leaks?.description}
            version={data?.plugins?.information_leaks?.version}
            name={"information leaks"}
          />
          <SystemsPlugins
            description={data?.plugins?.init_systems?.description}
            version={data?.plugins?.init_systems?.version}
            name={"init systems"}
          />
          <SystemsPlugins
            description={data?.plugins?.input_vectors?.description}
            version={data?.plugins?.input_vectors?.version}
            name={"input vectors"}
          />
          <SystemsPlugins
            description={data?.plugins?.interesting_uris?.description}
            version={data?.plugins?.interesting_uris?.version}
            name={"interesting uris"}
          />
          <SystemsPlugins
            description={data?.plugins?.ip_and_uri_finder?.description}
            version={data?.plugins?.ip_and_uri_finder?.version}
            name={"ip and uri finder"}
          />
          <SystemsPlugins
            description={data?.plugins?.kernel_config?.description}
            version={data?.plugins?.kernel_config?.version}
            name={"kernel config"}
          />
          <SystemsPlugins
            description={data?.plugins?.known_vulnerabilities?.description}
            version={data?.plugins?.known_vulnerabilities?.version}
            name={"known vulnerabilities"}
          />
          <SystemsPlugins
            description={data?.plugins?.malware_scanner?.description}
            version={data?.plugins?.malware_scanner?.version}
            name={"malware scanner"}
          />
          <SystemsPlugins
            description={data?.plugins?.printable_strings?.description}
            version={data?.plugins?.printable_strings?.version}
            name={"printable strings"}
          />
          <SystemsPlugins
            description={data?.plugins?.qemu_exec?.description}
            version={data?.plugins?.qemu_exec?.version}
            name={"qemu exec"}
          />
          <SystemsPlugins
            description={data?.plugins?.software_components?.description}
            version={data?.plugins?.software_components?.version}
            name={"software components"}
          />
          <SystemsPlugins
            description={data?.plugins?.source_code_analysis?.description}
            version={data?.plugins?.source_code_analysis?.version}
            name={"source code analysis"}
          />
          <SystemsPlugins
            description={data?.plugins?.string_evaluator?.description}
            version={data?.plugins?.string_evaluator?.version}
            name={"string evaluator"}
          />
          <SystemsPlugins
            description={data?.plugins?.tlsh?.description}
            version={data?.plugins?.tlsh?.version}
            name={"tlsh"}
          />
          <SystemsPlugins
            description={data?.plugins?.users_and_passwords?.description}
            version={data?.plugins?.users_and_passwords?.version}
            name={"users and passwords"}
          />
        </Grid>
      </Box>
    </div>
  );
};

export default Plugins;
