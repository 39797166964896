import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { getPatches } from "../api/patchManagement";
import {
    GET_PATCHES,
  } from "../queryKeys";


export const useGetPatches = (options = {}) => {
    const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
      [GET_PATCHES],
      getPatches,
      { ...options }
    );
    return { data, isLoading, error, refetch };
  };