import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import {
  getComparison,
  initiateComparison,
  getComparisons,
  deleteComparison,
} from "../api/comparison";
import { DELETE_COMPARISON } from "../apiUrl";
import {
  GET_COMPARISON,
  CREATE_COMPARISON,
  GET_COMPARISONS,
} from "../queryKeys";

export const useInitiateComparison = (options = {}) => {
  const { mutate, isLoading } = useMutation(initiateComparison, {
    mutationKey: CREATE_COMPARISON,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetComparison = (
  page: number,
  ids: any[],
  redo: boolean,
  options = {}
) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [GET_COMPARISON, page, ids, redo],
    getComparison,
    { ...options }
  );
  return { data, isLoading, error, refetch };
};

export const useGetComparisons = (
  limit: number,
  offset: number,
  options = {}
) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [GET_COMPARISONS, limit, offset],
    getComparisons,
    { ...options }
  );
  return { data, isLoading, error, refetch };
};

export const useDeleteComparison = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteComparison, {
    mutationKey: DELETE_COMPARISON,
    ...options,
  });

  return { mutate, isLoading };
};
