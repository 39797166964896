import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
const mode = localStorage.getItem("chakra-ui-color-mode") || "light";

const config: ThemeConfig = {
  // initialColorMode: mode === "light" ? "light" : "dark",
  initialColorMode: "dark",
  useSystemColorMode: false,
};
export const theme = extendTheme({
  fonts: {
    heading: "IBM Plex Sans, sans-serif",
    body: "IBM Plex Sans, sans-serif",
  },
  config,
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                transform: "scale(0.85) translateY(-24px)",
                color: "#000000",
                borderRadius: "5px",
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
              {
                transform: "scale(0.85) translateY(-24px)",
                color: "#000000",
                borderRadius: "5px",
              },
            label: {
              color: "#C4C4C4",
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
    Button: {
      variants: {
        primary: {
          bgColor: "lightMode.btnBgColor",
          size: "md",
          color: "lightMode.white",
          fontWeight: "700",
          _hover: {
            bgColor: "lightMode.btnBgColor",
          },
          _focus: {
            bgColor: "lightMode.btnBgColor",
          },
          _active: {
            bgColor: "lightMode.btnBgColor",
          },
        },
        dark: {

        },
        blue: {
          bgColor: "lightMode.blue",
          size: "md",
          color: "lightMode.white",
          fontWeight: "700",
          _hover: {
            bgColor: "lightMode.blue",
          },
          _focus: {
            bgColor: "lightMode.blue",
          },
          _active: {
            bgColor: "lightMode.blue",
          },
        },
        danger: {
          bgColor: "red.500",
          color: "#f3f3f3",
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },
  },
  colors: {
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
    darkMode: {
      mainBgColor: "#21262D",
      navBgColor: "#161B22",
      secBgColor: "#323541",
      miniBgColor: "#33384CCC",
      inputBgColor: "#FFFFFF",
      btnTextColor: "#FFFFFF",
      darkTextColor: "#000000",
      mainTextColor: "#FFFFFF",
      inputTextColor: "black",
      labelTextColor: "#D2D2D240",
      grayBackground: "#4F5776",
      cardBgColor: "#33384A",
      labelBgColor: "#1991DD",
    },
    lightMode: {
      // mainBgColor: "#cbd5e1",
      // navBgColor: "#00609C",
      // secBgColor: "#D9E3EB",
      // inputBgColor: "white",
      // miniBgColor: "#FFFFFF",
      // darkTextColor: "#000000",
      // mainTextColor: "#000000",
      // btnTextColor: "black",
      // labelTextColor: "#00000040",
      // cardBgColor: "#FFFFFF",
      // grayBackground: "#4F5776",
      // btnBgColor: "#59A9E9",
      // labelBgColor: "#7BB4E3",
      mainBgColor: "#21262D",
      navBgColor: "#161B22",
      secBgColor: "#323541",
      miniBgColor: "#33384CCC",
      inputBgColor: "#FFFFFF",
      btnTextColor: "#FFFFFF",
      darkTextColor: "#000000",
      mainTextColor: "#FFFFFF",
      inputTextColor: "black",
      labelTextColor: "#D2D2D240",
      grayBackground: "#4F5776",
      cardBgColor: "#33384A",
      labelBgColor: "#1991DD",
    },
  },
});
