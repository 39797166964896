import { Box, Grid, Tooltip, Spinner, Text, Center, GridItem } from "@chakra-ui/react";
import React, { IframeHTMLAttributes, useState, useEffect } from "react";
import AccordionComponent from "../../Analysis/Accordion";
import {
  HybridAccordion,
  Intezer,
  Lookup,
  MiddleAccordion,
  Unpacker,
  VirusAccordion,
} from "../InitCard";
import useCustomToast from "../../../../utils/notification";
import CodeAnalysisTable from "./CodeAnalysisTable";

const BottomData = ({ data, hybridData, factData, threatData, codeAnalysisData, sha, hybridOverview, codeAnalysisHtml}) => {
  // const secBgColorMode = useColorModeValue(
  //   "lightMode.secBgColor",
  //   "darkMode.cardBgColor"
  // );

  const secBgColor = "darkMode.secBgColor"
  const cardBgColor = "darkMode.cardBgColor"

  const fact = factData?.message?.firmware?.analysis;
  const plugin = threatData?.file_analysis?.analysis?.plugins;
  const secBgColorMode = "darkMode.cardBgColor"

  console.log(data)
  console.log(hybridData)
  console.log(threatData)
  console.log(codeAnalysisData)
  console.log(hybridOverview)

  const { errorToast, successToast, infoToast } = useCustomToast();
  
  // const [reportShow, setReportShow] = useState(false)
  const [spinners, setSpinners] = useState( 
    [
      {name:"YARA Tag List",      loaded: false},
      {name:"YARA Matches",       loaded: false},
      {name:"MITRE Attack",       loaded: false},
      {name:"XREFS",              loaded: false},
      {name:"Behaviour Intel",    loaded: false},
      {name:"Credentials",        loaded: false},
      {name:"IPv4",               loaded: false},
      {name:"Emails",             loaded: false},
      {name:"Links",              loaded: false},
      {name:"Tags",               loaded: false},
      {name:"HEX",                loaded: false},
      {name:"Suspicious Strings", loaded: false},
    ]
  )

  const [options, setOptions] = useState( 
    [
      {
        name:"YARA Tag List",      
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "YARA rules are used to classify and identify malware samples by creating descriptions of malware families based on textual or binary patterns.",
      },
      {
        name:"YARA Matches",       
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "YARA rules that have coincided in this analysis.",
      },
      {
        name:"MITRE Attack",       
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "Is a globally-accessible knowledge base of adversary tactics and techniques based on real-world observations. The ATT&CK knowledge base is used as a foundation for the development of specific threat models and methodologies in the private sector, in government, and in the cybersecurity product and service community.",
      },
      {
        name:"XREFS",              
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "",
      },
      {
        name:"Behaviour Intel",    
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "Is the application of skills and abilities to accurately explain existing behaviors",
      },
      {
        name:"Credentials",        
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "Displays passwords found",
      },
      {
        name:"IPv4",               
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "Displays ips found",
      },
      {
        name:"Emails",             
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "Displays emails found",
      },
      {
        name:"Links",              
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "",
      },
      {
        name:"Tags",               
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "",
      },
      {
        name:"HEX",                
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "",
      },
      {
        name:"Suspicious Strings", 
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "Detects strings that are found suspicious",
      },
      {
        name:"Threat Intel",       
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "What the file does if someone runs it",
      },
      // {name:"Hybrid Results",     hide: true, color: "darkMode.grayBackground"},
      {
        name:"Unpacker",           
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "Scans the drive archive",
      },
      {
        name:"Antivirus Results",  
        hide: true, 
        color: "darkMode.grayBackground",
        hoverText: "AVG, Acronis, Aracabit, avast, avira, baidu, bitdefender, bkav, clamAV, comodo, cylance, cynet, cyren, Drweb, emsisoft",
      },
      // {name:"Hybrid Data",        hide: true, color: "darkMode.grayBackground"},
    ]
  )
  const displayScreen = (key) => {
    const _options = options.map((item, _key) =>{
      if (key === _key) {
        // if (item.type === "quef-report") {
        //   console.log(item)
        //   const iframe = document.getElementById("quef-report") as any
        //   if (iframe) {
        //     console.log(iframe?.contentWindow)
        //   }

        //   setReportShow(true)
        // } else {
        //   setReportShow(false)
        // }
        item.hide = item.hide  ? false: true
        item.color = "darkMode.grayBackground"
      } else {
        item.hide = true
        item.color = "none"
      }
      return  item
    })
    setOptions(_options)
  }

  const severityToColor = (severity: number) => {
    if (severity < 4) {
      return "#edff6155"
    } else {
      if (severity < 7) {
        return "#ffc56155"
      } else {
        return "#ff796155"
      }
    }
  }

  function checkIframe(iframe: HTMLIFrameElement, index: number) {
    iframe.style.display = "none"
    spinners[index].loaded = false;
    // const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    iframe.addEventListener("load", (ev)=>{
      iframe.style.display = "flex"
      spinners[index].loaded = true;
      setSpinners(spinners)
      successToast("XREFs Graph Loaded")
    })
  }

  useEffect(() => {
    console.log('Component mounted');
    const iframes = document.getElementsByClassName("iframe")

    for (let i = 0; i < iframes.length; i++) {
      checkIframe(iframes[i] as HTMLIFrameElement, i)
    }

    if (window.location.hash === "#threat-intel") {
      options[12].hide = false;
      options[12].color = "darkMode.grayBackground"
      setOptions(options)
    } 
    return () => {
        console.log('Component will be unmount');
    }
  }, []);

  return (
    <Box p="0em" mt="8" mb="18" 
      borderRadius="0.75em" bgColor={secBgColor}>
      {/* {codeCaveData?.message  && 
        codeCaveData?.message?.length > 0 &&
        // codeCaveData?.message[0] != "No Results" && // Remove this to not hide code cave when dont have items
        <AccordionComponent
          name="Code Caves"
          bgCustomBColor="2px solid blue "
          linkDataItems={codeCaveData?.message.map(
            (item: any, index: number) => ({
              index,
              link: item,
            })
          )}
        />
      } */}

      <Box p="2em" mt="8" mb="18" borderRadius="0.75em" bgColor={secBgColorMode}>
        <Grid
          flexDirection={'row'}
          templateColumns='repeat(5, 1fr)'
          gap='2'
          >
            {options.map((title , key)=>{
              return <Box 
                // w='12vw' 
                h='6vh' 
                borderWidth='1px' 
                marginBottom={'1rem'}
                background={title.color}
                cursor={'pointer'}
                textAlign="center"
                onClick={displayScreen.bind(this, key)}
                key={key}

                className="title">
                  <Tooltip label={title.hoverText}>
                    <Center h='6vh'>{title.name}</Center>
                  </Tooltip>
              </Box>
            })}
        </Grid>

      </Box>


      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[0].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="Yara Tags List"
            renderData={codeAnalysisData?.yara_tags_list}
          />
          {/* <iframe
          className="iframe" 
          title={codeAnalysisHtml} 
          src={codeAnalysisHtml+"/yara-tags-list"}
          id="iframe-yara-tags-list"
          style={{
            width: "100%",
            height: "100%",
            minHeight: "500px",
            marginTop: 20,
            marginBottom: 20,
            borderRadius: 10,
            // display: (reportShow ? "block" : "none"),
            padding: 5,
            backgroundColor: "#33384A"
          }}
          ></iframe>
          {spinners[0].loaded === false &&
            <Spinner></Spinner>
          } */}
          
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[1].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="Yara Matches List"
            renderData={codeAnalysisData?.yara_matches}
          />
          {/* <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/yara-matches"}
            id="iframe-yara-matches"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[1].loaded === false &&
            <Spinner></Spinner>
          } */}
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[2].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="MITRE Attack"
            renderData={codeAnalysisData?.mitre_attack}
          />
        {/* <iframe
        className="iframe" 
          title={codeAnalysisHtml} 
          src={codeAnalysisHtml+"/mitre-attack"}
          id="iframe-mitre-attack"
          style={{
            width: "100%",
            height: "100%",
            minHeight: "500px",
            marginTop: 20,
            marginBottom: 20,
            borderRadius: 10,
            // display: (reportShow ? "block" : "none"),
            padding: 5,
            backgroundColor: "#33384A"
          }}
        ></iframe>
        {spinners[2].loaded === false &&
          <Spinner></Spinner>
        } */}
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[3].hide ? "none" : "block"}
        >
          {/* <XREFSGraph
            xrefsData={mappedCodeAnalysis?.XREFS?.TEXT}
            ></XREFSGraph> */}
          <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/xrefs"}
            id="iframe-xrefs"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[3].loaded === false &&
            <Spinner></Spinner>
          }
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[4].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="Behaviour Intel"
            renderData={codeAnalysisData?.behavior_intel}
          />
        {/* <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/behavior-intel"}
            id="iframe-behavior-intel"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[4].loaded === false &&
            <Spinner></Spinner>
          } */}
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[5].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="Credentials"
            renderData={codeAnalysisData?.creds_logins}
          />
          {/* <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/creds-logins"}
            id="iframe-creds-logins"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[5].loaded === false &&
            <Spinner></Spinner>
          } */}
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[6].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="Credentials"
            renderData={codeAnalysisData?.patterns_ip4s}
          />
          {/* <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/patterns-ip4s"}
            id="iframe-patterns-ip4s"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[6].loaded === false &&
            <Spinner></Spinner>
          } */}
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[7].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="Emails"
            renderData={codeAnalysisData?.patterns_emails}
          />
          {/* <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/patterns-emails"}
            id="iframe-patterns-emails"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[7].loaded === false &&
            <Spinner></Spinner>
          } */}
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[8].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="Links"
            renderData={codeAnalysisData?.patterns_links}
          />
          {/* <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/patterns-links"}
            id="iframe-patterns-links"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[8].loaded === false &&
            <Spinner></Spinner>
          } */}
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[9].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="Tags"
            renderData={codeAnalysisData?.patterns_tags}
          />
          {/* <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/patterns-tags"}
            id="iframe-patterns-tags"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[9].loaded === false &&
            <Spinner></Spinner>
          } */}
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[10].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="HEX"
            renderData={codeAnalysisData?.patterns_hex}
          />
          {/* <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/patterns-hex"}
            id="iframe-patterns-hex"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[10].loaded === false &&
            <Spinner></Spinner>
          } */}
      </Box>
      <Box p="2em" mt="8" mb="18" 
        borderRadius="0.75em" 
        bgColor={secBgColorMode}
        display={options[11].hide ? "none" : "block"}
        >
          <CodeAnalysisTable
            title="Suspicious Strings"
            renderData={codeAnalysisData?.suspicious_strings}
          />
          {/* <iframe
          className="iframe" 
            title={codeAnalysisHtml} 
            src={codeAnalysisHtml+"/suspicious-strings"}
            id="iframe-suspicious-strings"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "500px",
              marginTop: 20,
              marginBottom: 20,
              borderRadius: 10,
              // display: (reportShow ? "block" : "none"),
              padding: 5,
              backgroundColor: "#33384A"
            }}
          ></iframe>
          {spinners[11].loaded === false &&
            <Spinner></Spinner>
          } */}
      </Box>
      {/* <Lookup analysis={fact} sha={sha} /> */}

      <Box
        bgColor={cardBgColor}
        p="2em"
        borderRadius="0.75em"
        mb="18"
        display={options[12].hide ? "none" : "block"}
        >
        <Grid
          flexDirection={'row'}
          templateColumns='repeat(2, 1fr)'
          gap='2'
          
          >
          {plugin?.cuckoo?.result?.signatures?.length >= 1 &&
            plugin?.cuckoo?.result?.signatures?.map((alert, i) => (
              <GridItem
                key={i + 1}
                bgColor="darkMode.grayBackground"
                borderRadius="10px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                h="100%"
                p="20px"
                textAlign="center"
              >
                <Center mb="10px" fontSize={"16"}>{alert.name}</Center>
                <Center>{alert.description}</Center>
                <Center m="0px auto" mt="10px" width="30px" p="3px 10px" borderRadius="10px"
                  backgroundColor={severityToColor(alert.severity)}
                >{alert.severity}</Center>
              </GridItem>
            ))
          }
        </Grid>
      </Box>

      {/* <Box
        bgColor={cardBgColor}
        p="2em"
        borderRadius="0.75em"
        mb="18"
        display={options[13].hide ? "none" : "block"}
      >
        <Grid
          flexDirection={'row'}
          templateColumns='repeat(2, 1fr)'
          gap='2'
          
          >
          {hybridData?.scanners?.length >= 1 &&
            hybridData?.scanners?.map((item, i) => {
            if (item?.status !== "clean") {
              return (
                <Box
                  p="10px"
                  borderRadius="0.75em"
                  bgColor={item.status === "malicious" ? "#ff616166": "transparent"}
                  >
                  <Text fontSize="16">{item.name}</Text>
                  <Text fontSize="12">{item.status}</Text>
                </Box>
              )
            }})
          }
        </Grid>
      </Box> */}
      

      <Box
        bgColor={cardBgColor}
        p="2em"
        borderRadius="0.75em"
        mb="18"
        display={options[13].hide ? "none" : "block"}
        >
        <Text>{fact?.unpacker?.output}</Text>
        {/* <Unpacker analysis={fact} /> */}
      </Box>

      
      {/* //   <AccordionComponent
      //     name="Signatures"
      //     bgCustomBColor="4px solid red "
      //     linkDataItems={plugin?.cuckoo?.result?.signatures?.map(
      //       (item: any, index: number) => ({
      //         index,
      //         link: item?.description,
      //       })
      //     )}
      //   />

      //   <Intezer
      //     bgCustomBColor="4px solid red"
      //     analysis={plugin?.intezer?.results?.analysis?.result}
      //   />
      // </Box> */}


      <Box
        bgColor={cardBgColor}
        p="2em"
        borderRadius="0.75em"
        mb="18"
        display={options[14].hide ? "none" : "block"}
      >
        <Grid
          flexDirection={'row'}
          templateColumns='repeat(2, 1fr)'
          gap='2'
          
          >
          <VirusAccordion name="ALYac" analysis={data?.ALYac} />
          <VirusAccordion name="AVG" analysis={data?.AVG} />
          <VirusAccordion name="Acronis" analysis={data?.Acronis} />
          <VirusAccordion name="Arcabit" analysis={data?.Arcabit} />
          <VirusAccordion name="Avast" analysis={data?.Avast} />
          <VirusAccordion name="Avira" analysis={data?.Avira} />
          <VirusAccordion name="Baidu" analysis={data?.Baidu} />
          <VirusAccordion name="BitDefender" analysis={data?.BitDefender} />
          <VirusAccordion name="Bkav" analysis={data?.Bkav} />
          <VirusAccordion name="CMC" analysis={data?.CMC} />
          <VirusAccordion name="ClamAV" analysis={data?.ClamAV} />
          <VirusAccordion name="Comodo" analysis={data?.Comodo} />
          <VirusAccordion name="Cylance" analysis={data?.Cylance} />
          <VirusAccordion name="Cynet" analysis={data?.Cynet} />
          <VirusAccordion name="Cyren" analysis={data?.Cyren} />
          <VirusAccordion name="DrWeb" analysis={data?.DrWeb} />
          <VirusAccordion name="Emsisoft" analysis={data?.Emsisoft} />
          <VirusAccordion name="FireEye" analysis={data?.FireEye} />
          <VirusAccordion name="Fortinet" analysis={data?.Fortinet} />
          <VirusAccordion name="GData" analysis={data?.GData} />
          <VirusAccordion name="Google" analysis={data?.Google} />
          <VirusAccordion name="Gridinsoft" analysis={data?.Gridinsoft} />
          <VirusAccordion name="Ikarus" analysis={data?.Ikarus} />
          <VirusAccordion name="Jiangmin" analysis={data?.Jiangmin} />
          <VirusAccordion name="K7AntiVirus" analysis={data?.K7AntiVirus} />
          <VirusAccordion name="K7GW" analysis={data?.K7GW} />
          <VirusAccordion name="Kaspersky" analysis={data?.Kaspersky} />
          <VirusAccordion name="Kingsoft" analysis={data?.Kingsoft} />
          <VirusAccordion name="Lionic" analysis={data?.Lionic} />
          <VirusAccordion name="MAX" analysis={data?.MAX} />
          <VirusAccordion name="Malwarebytes" analysis={data?.Malwarebytes} />
          <VirusAccordion name="MaxSecure" analysis={data?.MaxSecure} />
          <VirusAccordion name="McAfee" analysis={data?.McAfee} />
          <VirusAccordion name="Microsoft" analysis={data?.Microsoft} />
          <VirusAccordion name="Panda" analysis={data?.Panda} />
          <VirusAccordion name="Rising" analysis={data?.Rising} />
          <VirusAccordion
            name="SUPERAntiSpyware"
            analysis={data?.SUPERAntiSpyware}
          />
          <VirusAccordion name="Sangfor" analysis={data?.Sangfor} />
          <VirusAccordion name="SentinelOne" analysis={data?.SentinelOne} />
          <VirusAccordion name="Sophos" analysis={data?.Sophos} />
          <VirusAccordion name="Symantec" analysis={data?.Symantec} />
          <VirusAccordion name="Tencent" analysis={data?.Tencent} />
          <VirusAccordion name="TrendMicro" analysis={data?.TrendMicro} />
          <VirusAccordion name="VBA32" analysis={data?.VBA32} />
          <VirusAccordion name="VIPRE" analysis={data?.VIPRE} />
          <VirusAccordion name="VirIT" analysis={data?.VirIT} />
          <VirusAccordion name="Yandex" analysis={data?.Yandex} />
          <VirusAccordion name="Zillya" analysis={data?.Zillya} />
          <VirusAccordion name="ZoneAlarm" analysis={data?.ZoneAlarm} />
          <VirusAccordion name="Zoner" analysis={data?.Zoner} />
        </Grid>
      </Box>



      <Box
        bgColor={cardBgColor}
        p="2em"
        borderRadius="0.75em"
        mb="18"
        // display={options[15].hide ? "none" : "block"}
        display={"none"}
        >
        <MiddleAccordion
          analysis={hybridData?.sha256 || "No Result"}
          title={"sha256"}
          name="Sha 256"
        />
        <MiddleAccordion
          analysis={hybridData?.type || "No Result"}
          title={"type"}
          name="Type"
        />
        <MiddleAccordion
          analysis={hybridData?.verdict || "No Result"}
          title={"verdict"}
          name="Verdict"
        />
        <MiddleAccordion
          analysis={hybridData?.analysis_start_time || "No Result"}
          title={"analysis_start_time"}
          name="Analysis Start Time"
        />
        <MiddleAccordion
          analysis={hybridData?.last_file_name || "No Result"}
          title={"last_file_name"}
          name="Last File Name"
        />
        
        {/* <MiddleAccordion
          analysis={
            JSON.stringify(
              hybridData?.other_file_name?.length === 0 ||
              hybridData?.other_file_name === undefined ||
              hybridData?.other_file_name === null 
                ? "No Result"
                : hybridData?.other_file_name
            ) || "No Result"
          }
          title={"other_file_name"}
          name="Other File Name"
        /> */}
        <Text
          mb="18"
          fontWeight="500"
          fontSize="16px"
          >
          Other File Name
        </Text>
        <Text
          p="3" 
          borderRadius="5px" 
          bgColor="darkMode.grayBackground" 
          fontSize="14px" fontWeight="200"
          mb="10px"
          >{
            JSON.stringify(
              hybridData?.other_file_name?.length === 0 ||
              hybridData?.other_file_name === undefined ||
              hybridData?.other_file_name === null 
                ? "No Result"
                : hybridData?.other_file_name
            ) || "No Result"
          }
        </Text>
        <MiddleAccordion
          analysis={JSON.stringify(hybridData?.url_analysis) || "No Result"}
          title={"url_analysis"}
          name="Url Analysis"
        />
        <MiddleAccordion
          analysis={hybridData?.size || "No Result"}
          title={"size"}
          name="Size"
        />
        <MiddleAccordion
          analysis={
            JSON.stringify(
              hybridData?.architecture?.length === 0 ||
              hybridData?.architecture === null ||
              hybridData?.architecture === undefined
                ? "No Result"
                : hybridData?.architecture
            ) || "No Result"
          }
          title={"architecture"}
          name="Architecture"
        />
        <MiddleAccordion
          analysis={
            JSON.stringify(
              hybridData?.threat_score?.length === 0 ||
              hybridData?.threat_score === null 
                ? "No Result"
                : hybridData?.threat_score
            ) || "No Result"
          }
          title={"threat_score"}
          name="Threat Score"
        />
        <MiddleAccordion
          analysis={JSON.stringify(hybridData?.whitelisted) || "No Result"}
          title={"whitelisted"}
          name="Whitelisted"
        />
        {/* <MiddleAccordion
          analysis={
            JSON.stringify(hybridData?.children_in_progress) || "No Result"
          }
          title={"children_in_progress"}
          name="Children In Progress"
        /> */}
        {/* <MiddleAccordion
          analysis={
            JSON.stringify(hybridData?.children_in_queue) || "No Result"
          }
          title={"children_in_queue"}
          name="Children in Queue"
        />
        <MiddleAccordion
          analysis={
            JSON.stringify(
              hybridData?.related_children_hashes?.length === 0
                ? "No Result"
                : hybridData?.related_children_hashes
            ) || "No Result"
          }
          title={"related_children_hashes"}
          name="Related Children Hashes"
        />
        <MiddleAccordion
          analysis={
            JSON.stringify(
              hybridData?.related_parent_hashes?.length === 0
                ? "No Result"
                : hybridData?.related_parent_hashes
            ) || "No Result"
          }
          title={"related_parent_hashes"}
          name="Related Parent Hashes"
        />
        <MiddleAccordion
          analysis={JSON.stringify(hybridData?.reports) || "No Result"}
          title={"reports"}
          name="Reports"
        />
        <MiddleAccordion
          analysis={
            JSON.stringify(
              hybridData?.submit_context?.length === 0
                ? "No Result"
                : hybridData?.submit_context
            ) || "No Result"
          }
          title={"submit_context"}
          name="Submit Context"
        /> */}
        {/* <MiddleAccordion
          analysis={
            JSON.stringify(
              hybridData?.tags?.length === 0 ? "No Result" : hybridData?.tags
            ) || "No Result"
          }
          title={"tags"}
          name="Tags"
        /> */}
        <Text
          mb="18"
          fontWeight="800"
          fontSize="18px"
          >
          Tags
        </Text>
        <Text
          p="3" 
          borderRadius="5px" 
          bgColor="darkMode.grayBackground" 
          fontSize="14px" fontWeight="200"
          mb="10px"
          >{
            JSON.stringify(
              hybridData?.tags?.length === 0 ? "No Result" : hybridData?.tags
            ) || "No Result"
          }
        </Text>

        <MiddleAccordion
          analysis={hybridData?.type_short || "No Result"}
          title={"type_short"}
          name="Type short"
        />
      </Box>
    </Box>
  );
};

export default BottomData;
