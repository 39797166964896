import React from "react";
import { Box, Button } from "@chakra-ui/react";

function Pagination({ setCurrentEndpoint, next, prev }) {
  const bgColor = "darkMode.grayBackground"
  return (
    <Box textAlign="center">
      <Button
        bgColor={bgColor}
        disabled={!prev}
        onClick={() => {
          const trimStr = prev?.replace("http://193.122.65.123/api/", "");
          setCurrentEndpoint(trimStr);
        }}
      >
        Prev
      </Button>
      <Button
        bgColor={bgColor}
        ml="16px"
        disabled={!next}
        onClick={() => {
          const trimStr = next?.replace("http://193.122.65.123/api/", "");
          setCurrentEndpoint(trimStr);
        }}
      >
        Next
      </Button>
    </Box>
  );
}

export default Pagination;
