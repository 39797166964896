import { Box, Center, Grid, Table, Tbody, Td, Th, Thead, Tr, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useCodeCaveDetails } from "../../../../services/query/codeCave";
import { useGetExploitMitigation } from "../../../../services/query/exploitMitigation";
// import { HybridAccordion } from "../InitCard";

import { CVE, CWE, CodeCave } from "../../../data/Analysis/BottomComponent/InitCard";
import { Exploit } from "../../../data/Analysis/BottomComponent/SecCard";
import { Leaks } from "../../../data/Analysis/BottomComponent/ThirdCards";
import AccordionComponent from "../../Analysis/Accordion";
import AccordionLinkDataItemComponent from "../../Analysis/Accordion/AccordionLinkDataItemComponent";

const BottomData = ({ data }) => {
  // const secBgColorMode = useColorModeValue(
  //   "lightMode.secBgColor",
  //   "darkMode.cardBgColor"
  // );

  const secBgColorMode = "darkMode.cardBgColor"

  const { scan_uid } = useParams();

  const { data: exploitData } = useGetExploitMitigation(scan_uid as string);

  const analysis = data?.message?.firmware?.analysis;

  const { data: codeCaveData } = useCodeCaveDetails(scan_uid as string)
  const [options, setOptions] = useState( 
    [
      {
        name:"CVE Lookup", 
        hide: false, 
        color:"",
        hoverText: "Lookup CVE vulnerabilities",
      },
      {
        name:"CWE Checker", 
        hide: true, 
        color:"",
        hoverText: "This plugin checks ELF binaries for several CWEs (Common Weakness Enumeration) likeCWE-243 (Creation of chroot Jail Without Changing Working Directory) andCWE-676 (Use of Potentially Dangerous Function).Due to the nature of static analysis, this plugin may run for a long time.",
      },
      {
        name:"Exploit Mitigation", 
        hide: true, 
        color:"",
        hoverText: "Analyzes ELF binaries within a firmware for present exploit mitigation techniques",
      },
      {
        name:"Leaks", 
        hide:true , 
        color:"",
        hoverText: "Any encrypted environment variables that you create in an organization, repository, or repository environment",
      },
      {
        name:"Code Caves", 
        hide:true , 
        color:"",
        hoverText: "A tool that helps you find code caves inside a given file",
      },
    ]
  )
  const displayScreen = (key) => {
    const _options = options.map((item, _key) =>{
      if (key === _key) {
        item.hide = item.hide  ? false: true
        item.color = "darkMode.grayBackground"
      } else {
        item.hide = true
        item.color = "none"
      }
      return  item
    })
    setOptions(_options)
  }
  return (
    <Box p="2em" mt="8" mb="18" borderRadius="0.75em" bgColor={secBgColorMode}>
      <Grid
        flexDirection={'row'}
        templateColumns='repeat(5, 1fr)'
        gap='2'
        >
          {options.map((title , key)=>{
            return <Box 
                // w='12vw' 
                h='6vh' 
                borderWidth='1px' 
                marginBottom={'1rem'}
                background={title.color}
                cursor={'pointer'}
                textAlign="center"
                onClick={displayScreen.bind(this, key)}
                className="title">
                  <Tooltip label={title.hoverText}>
                    <Center h='6vh'>{title.name}</Center>
                  </Tooltip>
              </Box>
          })}
      </Grid>

      <CVE analysis={analysis} hide={options[0].hide} />
      <CWE analysis={analysis} hide={options[1].hide}/>
      <Exploit analysis={analysis} hide={options[2].hide} exploit={exploitData?.exploits} />
      <Leaks analysis={analysis} hide={options[3].hide}/>
      <div style={{display: options[4].hide ? "none":"flex"}}>
        {codeCaveData?.message[0] === "No Results" ? 
         <Box bg={"darkMode.grayBackground"} w="100%" h="6vh" padding="1rem" fontWeight="600">No Result</Box>:<Table variant='simple'>
              <Thead>
                <Tr bg={"darkMode.grayBackground"}>
                  <Th>#</Th>
                  <Th>Name</Th>
                  <Th>Size</Th>
                  <Th>RAW ADDRESS BYTE</Th>
                  <Th>VIRTUAL ADDRESS BYTE</Th>
                </Tr>
              </Thead>
              <Tbody p="1em" 
                  background="darkMode.grayBackground" 
                  _hover={{ background: "darkMode.grayBackground"  }}>
                    {codeCaveData?.message?.map((item, index) => (
                      <>
                      <CodeCave link={item}  index={index}/>
                      </>
                    ))}
              </Tbody>
            </Table>
        }
      </div>
    </Box>
  );
};

export default BottomData;
