import * as Yup from "yup";
import { passwordRegex } from "../../../utils/helpers";

export const validationSchema = Yup.object({
  first_name: Yup.string()
    .required("First name is required")
    .max(20, "Name field should be less than 20 characters"),
  last_name: Yup.string()
    .required("Last name is required")
    .max(20, "Name field should be less than 20 characters"),
  username: Yup.string()
    .required("Username is required")
    .max(20, "Name field should be less than 20 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  country: Yup.string().required("Country is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .min(5, "Phone Number field should be more than 5 characters"),
  password: Yup.string()
    .required(
      "Password must be 8 chars or more; lowercase, uppercase, special characters and digits"
    )
    .matches(
      passwordRegex,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  organization: Yup.string()
    .required("Required")
    .max(20, "Name field should be less than 20 characters"),
});

export const initialValue = {
  first_name: "",
  last_name: "",
  username: "",
  email: "",
  country: "",
  phone_number: "",
  password: "",
  organization: "",
};
