import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return (
    <Flex justify={"center"} align="center" width={"100%"} py={4}>
      <Text
        fontWeight={600}
        // color={useColorModeValue(
        //   "lightMode.mainTextColor",
        //   "darkMode.mainTextColor"
        // )}
        color="darkMode.mainTextColor"
      >
        Powered by Trusted Partners
      </Text>
    </Flex>
  );
};

export default Footer;
