import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { dateFormat } from "../../../utils/helpers";

const Results = ({ initSearch }) => {
  return (
    <Box
      marginTop="46px"
      w={["100%", "100%", "100%", "70%"]}
      maxW={["100%", "100%", "100%", "70%"]}
      h={["50%", "20rem", "20rem", "20rem"]}
      maxH={["50%", "20rem", "20rem", "20rem"]}
      mx="auto"
      bg={useColorModeValue("lightMode.secBgColor", "darkMode.secBgColor")}
      borderRadius="10px"
      padding={{
        base: "34px 8px 34px 8px",
        lg: "34px 68px 70px 68px",
      }}
      fontSize={{ base: "14px", "2xl": "24px" }}
      fontWeight="semibold"
      filter="drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))"
    >
      {initSearch.length === 0 ? (
        <Flex fontSize="16px" justifyContent="space-between">
          <Box w="50%">
            <Text py={5}>Search ID:</Text>
            <Text>Status:</Text>
            <Text py={5}>Device Name:</Text>
          </Box>
          <Box>
            <Text py={5}>Device Classs:</Text>
            <Text>Vendor:</Text>
            <Text py={5}>Description:</Text>
            <Text>Created at:</Text>
          </Box>
        </Flex>
      ) : (
        <Link to={`/binary-search/${initSearch?.search_id}`}>
          <Flex
            _hover={{
              boxShadow: "lg",
              padding: "5px",
              cursor: "pointer",
            }}
            fontSize="16px"
            justifyContent="space-between"
          >
            <Box w="50%">
              <Text py={5}>Search ID: {initSearch?.search_id}</Text>
              <Text>Status: {initSearch?.status}</Text>
              {initSearch?.firmware === null ? (
                <Text></Text>
              ) : (
                <Text py={5}>
                  Device Name: {initSearch?.firmware?.device_name}
                </Text>
              )}
            </Box>
            <Box>
              {initSearch?.firmware === null ? (
                <Text></Text>
              ) : (
                <>
                  <Text py={5}>
                    Device Class: {initSearch?.firmware?.device_class}
                  </Text>
                  <Text py={5}>Vendor: {initSearch?.firmware?.vendor}</Text>
                </>
              )}
              <Text py={5}>Description: {initSearch?.short_description}</Text>
              <Text>Created at: {dateFormat(initSearch?.created_at)}</Text>
            </Box>
          </Flex>
        </Link>
      )}
    </Box>
  );
};

export default Results;
