import React from "react";
import { TimeFormater } from "../../../common/TimeFormater";
import Accordions from "../Accordion";

export const Config = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="Kernel config"
      dataItems={[
        {
          title: "Summary",
          data:
            analysis?.kernel_config?.summary?.length > 0
              ? JSON.stringify(analysis?.kernel_config?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};

export const Software = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="Software components"
      dataItems={[
        {
          title: "Summary",
          data:
            analysis?.software_components?.summary?.length > 0
              ? JSON.stringify(analysis?.software_components?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};

export const Unpacker = ({ analysis }) => {
  return (
    <Accordions
      name="Unpacker"
      dataItems={[
        {
          title: "Time of Analysis",
          data:
            (
              <TimeFormater
                date={analysis?.software_components?.analysis_date}
              />
            ) || "No Result",
        },
        {
          title: "Plugin Version",
          data: analysis?.unpacker?.plugin_version || "No Result",
        },
        {
          title: "Entropy",
          data: analysis?.unpacker?.entropy || "No Result",
        },
        {
          title: "Number of excluded files",
          data: analysis?.unpacker?.number_of_excluded_files || "No Result",
        },
        {
          title: "Number of unpacked directories",
          data:
            analysis?.unpacker?.number_of_unpacked_directories || "No Result",
        },
        {
          title: "Number of unpacked files",
          data: analysis?.unpacker?.number_of_unpacked_files || "No Result",
        },
        {
          title: "Plugin used",
          data: analysis?.unpacker?.plugin_used || "No Result",
        },
        {
          title: "Size packed",
          data: analysis?.unpacker?.size_packed || "No Result",
        },
        {
          title: "Size unpacked",
          data: analysis?.unpacker?.size_unpacked || "No Result",
        },
        {
          title: "Output",
          data: analysis?.unpacker?.output || "No Result",
        },
        {
          title: "Summary",
          data:
            analysis?.unpacker?.summary?.length > 0
              ? JSON.stringify(analysis?.unpacker?.summary)
              : "No Summary",
        },
      ]}
    />
  );
};
