import React, { useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { useUploadTicket } from "../../services/query/uploadTicket";
import useCustomToast from "../../utils/notification";
import { IoTicketOutline } from "react-icons/io5";

const ZendeskModal = ({ isOpen, onClose }) => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const { errorToast, successToast } = useCustomToast();
  const { mutate: createMutate, isLoading: isCreating } = useUploadTicket({
    onSuccess: () => {
      successToast("Ticket created successfully");
      setSubject("");
      setBody("");

      setTimeout(() => {
        onClose();
      }, 500);
    },
    onError: (err) => {
      errorToast(err.response.data.message);
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    createMutate({
      subject: subject,
      body: body,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent 
          bgColor="darkMode.secBgColor"
          color="white">
        <Box as="form" onSubmit={handleSubmit}>
          <ModalHeader 
            display="flex" 
            alignItems="center"
            borderBottom="1px solid rgb(255, 255, 255, 0.3)"
            >
            Ticket
          </ModalHeader>
          <ModalCloseButton color="red" />
          <ModalBody mb="2rem">
            <FormLabel>Subject:</FormLabel>
            <Input
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              mb="20px"
            />
            <FormLabel>Body:</FormLabel>
            <Textarea value={body} onChange={(e) => setBody(e.target.value)} />
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              isLoading={isCreating}
              type="submit"
              color="#fff"
              mr={3}
              bgColor="darkMode.grayBackground"
              _hover={{background: "darkMode.grayBackground"}}
            >
              Create a Ticket
            </Button>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default ZendeskModal;
