import { Box, GridItem, Heading, Text } from "@chakra-ui/react";
export const SystemsPlugins = ({ name, description, version }: any) => {
  const bgColor = "darkMode.cardBgColor"
  return (
    <GridItem
      border="1px"
      borderColor="gray.400"
      p={[3, 3, 5, 5]}
      borderRadius="0.25rem"
      bgColor={bgColor}
      h="auto"
      w="100%"
      transition="ease-in-out"
      transitionDuration="2000"
    >
      <Box mb="5">
        <Heading as="h2" fontSize="1.25rem" fontWeight="500" lineHeight="1.2">
          {name}
        </Heading>
        <Text fontSize="1rem" fontWeight="500" opacity="0.8">
          {version}
        </Text>
        <Text>{description}</Text>
      </Box>
    </GridItem>
  );
};
