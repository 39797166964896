import React, { useState } from "react";
import {
  Box,
  Flex,
  Spinner,
  useColorModeValue,
  Link,
  Avatar,
  Input,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Link as ReachLink } from "react-router-dom";
import {
  useGetUserProfile,
  useEditUserProfile,
} from "../../services/query/user";
import CustomHeader from "../common/CustomHeader";
import useCustomToast from "../../utils/notification";
import { adminRoutes, routes } from "../common/constants";
import { IProps } from "../../types";

const SettingsLayout = (props: IProps) => {
  const { children } = props;
  const location = useLocation();
  const { successToast, errorToast } = useCustomToast();
  const [fileType, setFileType] = useState<any>();
  const { data: userInfo } = useGetUserProfile();
  const { mutate: uploadMutate, isLoading: isImageLoading } =
    useEditUserProfile({
      onSuccess: () => {
        successToast("Update Successful");
      },
      onError: () => {
        errorToast("Upload Failed, Please try again");
      },
    });

  const handleUpload = (e) => {
    setFileType(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("profile_picture", e.target.files[0]);
    uploadMutate(formData);
  };
  return (
    <Box>
      <style>
        {`.avatar_con:hover .change_con {
                display: flex;
            }
            .loading {
              display: flex;
              color: white;
            }

            `}
      </style>
      <CustomHeader title="User Profile" />
      <Box
        m="auto"
        mt={10}
        mb="20"
        w="100%"
        display={["block", "block", "flex", "flex"]}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Box
          display="block"
          width={["100%", "100%", "25%", "25%"]}
          h="fit-content"
          // border="1px"
          bgColor="darkMode.secBgColor"
          borderColor="none"
          position="relative"
          mb="5"
        >
          {/* <Box as="form" position="relative">
            <Input
              id="image_upload"
              onChange={handleUpload}
              type="file"
              display="none"
              borderColor="black"
            />
            <label htmlFor="image_upload">
              <Box
                mx={7}
                className="avatar_icon"
                position="relative"
                cursor="pointer"
                borderRadius="50px"
              >
                <Flex w="100%" justifyContent="center">
                  <Avatar
                    w={["120px", "150px", "100px", "150px"]}
                    h={["120px", "150px", "100px", "150px"]}
                    maxH={["120px", "150px", "100px", "150px"]}
                    maxW={["120px", "150px", "100px", "150px"]}
                    borderRadius="50%"
                    mx={"auto"}
                    my={["5", "5", "10", "10"]}
                    src={fileType || userInfo?.profile_picture}
                  />
                </Flex>
                <Flex
                  position="absolute"
                  pointerEvents="none"
                  left="45%"
                  top="40%"
                  width="100%"
                  height="100%"
                  borderRadius="rgba(255, 255, 255, 0.8)"
                  gap="12px"
                  display="none"
                  className={isImageLoading ? "loading" : "change_con"}
                >
                  {isImageLoading && <Spinner />}
                </Flex>
              </Box>
            </label>
          </Box> */}
          <Box width="100%" textAlign="left" pl="25">
            {(userInfo?.is_admin === true ? adminRoutes : routes).map(
              (item) => (
                <Link
                  key={item.id}
                  to={item.route}
                  py="4"
                  pl="4"
                  borderLeft={"3px solid transparent"}
                  borderColor={"transparent"}
                  bgColor="darkMode.secBgColor"
                  fontWeight="500"
                  textDecoration={"none"}
                  fontSize="lg"
                  display="block"
                  cursor="pointer"
                  _hover={{
                    backgroundColor: "darkMode.grayBackground",
                    borderLeft: "3px solid white"
                  }}
                  as={ReachLink}
                >
                  {item.title}
                </Link>
              )
            )}
          </Box>
        </Box>
        <Box
          width={["100%", "100%", "72%", "72%"]}
          h={"auto"}
          border="none"
          bgColor="darkMode.cardBgColor"
          color={"white"}
          borderColor="transparent"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsLayout;
