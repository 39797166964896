import { Box, GridItem, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { CompareProps } from "../../../types";

export const ComparedListComponent = ({
  uid1,
  uid2,
  name1,
  name2,
}: CompareProps) => {
  const { colorMode } = useColorMode();

  return (
    <GridItem>
      <Link to={`/analysis/compare/result/${uid1}/${uid2}`}>
        <Box
          my="9px"
          p="10px"
          borderRadius="0.5em"
          bgColor={
            colorMode === "light"
              ? "lightMode.secBgColor"
              : "darkMode.cardBgColor"
          }
          borderColor={
            colorMode === "light"
              ? "lightMode.BgColor"
              : "darkMode.mainTextColor"
          }
        >
          <Box fontWeight="500" fontSize={{ lg: "15px" }}>
            {name1 || "No Result"}{" "}
            <Text m="0" p="0" color="blue">
              VS
            </Text>{" "}
            {name2 || "No Result"}
          </Box>
          {/* <Text fontSize={["15px", "15px", "12px", "12px"]}>
            {item?.created_at || "No Result"}
          </Text> */}
        </Box>
      </Link>
    </GridItem>
  );
};
