import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { getSystemStatus } from "../api/systemStatus";
import { SYSTEM_STATUS } from "../queryKeys";

export const useGetSystemStatus = (options = {}) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [SYSTEM_STATUS],
    getSystemStatus,
    { ...options }
  );

  return { data, isLoading, error, refetch };
};
