import axiosInstance from "../axiosInstance";

export const patchManager = async () => {
    const res = await axiosInstance.get(
        '/patches/downloaded-patches'
    )
    return res.data;
}

export const patchUpdate = async () => {
    const res = await axiosInstance.get(
        '/patches/update-user-patches'
    )
    return res.data;
}

export const patchManagerApproveService = async (data) => {
    const res = await axiosInstance.post(
        '/patches/approve-patch',
        data
    )
    return res.data;
}

export const patchManagerDisapproveService = async (data) => {
    const res = await axiosInstance.post(
        '/patches/unapprove-patch',
        data
    )
    return res.data;
}

export const patchManagerRunFullAnalysisService = async (data) => {
    const res = await axiosInstance.post(
        '/patches/run-full-analysis',
        data
    )
    return res.data;
}

export const patchManagerScanForMalwareService = async (data) => {
    const res = await axiosInstance.post(
        '/patches/run-malware-scan',
        data
    )
    return res.data;
}

export const sendOTPService = async () => {
    try {
        const res = await axiosInstance.post(
            '/patches/send-me-otp'
        )
        return res;
        
    } catch (error) {
        return error
    }
    
}

export const authenticateOTPService = async (otp) => {
    try {
        const res = await axiosInstance.post(
            '/patches/verify-me-otp',
            {otp}
        )
        return res;
        
    } catch (error) {
        return error
    }
    
}

export const checkOTPService = async () => {
    try {
        const res = await axiosInstance.get(
            '/patches/check-token-expiry'
        )
        return res;
        
    } catch (error) {
        return error
    }
    
}

