import { Box, GridItem, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const ModelItemComponent = ({ item }: { item: any }) => {
  const { colorMode } = useColorMode();

  const bgColor = "darkMode.cardBgColor"

  return (
    <GridItem>
      <Link onClick={()=>{
        localStorage.setItem("scan_uid", item?.scan_uid)
      }}to={`/analysis/${item.scan_uid}`}>
        <Box
          my="9px"
          p="10px"
          width={"100%"}
          borderRadius="0.5em"
          bgColor={bgColor}
          borderColor={"none"}
        >
          <Text fontWeight="500" fontSize={{ lg: "20px" }}>
            {item.device_name ? item.device_name : "No device name"}
          </Text>
          <Text fontSize={["15px", "15px", "12px", "12px"]}>
            {item.created_at}
          </Text>
        </Box>
      </Link>
    </GridItem>
  );
};

export default ModelItemComponent;
