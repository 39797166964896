import {
  GET_ANALYSIS,
  GET_MODELS,
  UPDATE_ANALYSIS,
  GET_COMMENTS,
  GET_ANALYSIS_COUNT,
  POST_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  GET_MISSING_ANALYSIS,
  CODE_ANALYSIS
} from "../apiUrl";
import axiosInstance from "../axiosInstance";

export const getModels = async ({ queryKey }) => {
  const [, url] = queryKey;
  const res = await axiosInstance.get(url || GET_MODELS);
  return res.data;
};

export const getAnalysis = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${GET_ANALYSIS}?scan_uid=${id}`);
  return res.data;
};

export const getAnalysisCount = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${GET_ANALYSIS_COUNT}?scan_uid=${id}`);
  return res.data;
};

export const updateAnalysis = async (body) => {
  const res = await axiosInstance.put(`${UPDATE_ANALYSIS}`, body);
  return res.data;
};

export const getComments = async () => {
  const res = await axiosInstance.get(GET_COMMENTS);
  return res.data;
};

export const postComment = async (body) => {
  const res = await axiosInstance.post(POST_COMMENT, body);
  return res.data;
};

export const editComment = async (body) => {
  const res = await axiosInstance.put(`${EDIT_COMMENT}/${body.id}`, body);
  return res.data;
};

export const deleteComment = async (body) => {
  const res = await axiosInstance.delete(`${DELETE_COMMENT}/${body.id}`);
  return res.data;
};

export const getMissingAnalysis = async () => {
  const res = await axiosInstance.get(GET_MISSING_ANALYSIS);
  return res.data;
};

export const getCodeAnalysis = async (scanUid) => {
  const uid = scanUid.queryKey[1]
  const res = await axiosInstance.get(`${CODE_ANALYSIS}${uid}`)
  return res.data
}
