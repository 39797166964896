import { useQuery } from "react-query";
import { getFirmwareStatistics } from "../api/firmwareStatistics";
import { GET_FIRMWARE_STATISTICS } from "../queryKeys";

export const useFirmwareStatistics = () => {
  const { data, isLoading, error } = useQuery(
    [GET_FIRMWARE_STATISTICS],
    getFirmwareStatistics
  );

  return { data, isLoading, error };
};
