import React from "react";
import { Box, useDisclosure, Text, useColorModeValue, Image } from "@chakra-ui/react";
import ZendeskModal from "../Modals/ZendeskModal";
import { IoTicketOutline } from "react-icons/io5";
import { Support } from "../../assets";

function Zendesk() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const borderColorMode = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.mainTextColor"
  );

  return (
    <Box
      m="10"
      style={{
        position: "fixed",
        right: "-10px",
        bottom: "-15px",
        border: "none",
        borderRadius: "100%",
        backgroundColor: "white",
      }}
      
    >
      <Box onClick={onOpen} cursor="pointer" >
        {/* <Text fontSize={"1.5em"} padding={".5em"} >
          Support
        </Text> */}
        <Image src={Support} alt='support'/>
      </Box>

      <ZendeskModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

export default Zendesk;
