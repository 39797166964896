import React from "react";
import { Flex, Grid, Text } from "@chakra-ui/react";
import { IAccordionDataItem } from "../../../../types";

const AccordionDataItemComponent = ({ title, data }: IAccordionDataItem) => {
  return (
    <Grid 
      templateRows={'repeat(2, 1fr)'}
      w="100%"
    >
      <Text
        p="2"
        width="100%"
        fontSize="18"
        background="darkMode.grayBackground"
        fontWeight={600}
        color="white"
      >
        {title}
      </Text>
      <Text
        p="2"
        width="100%"
        fontSize="14"
        flexGrow={1}
        background="darkMode.grayBackground"
        color="white"
      >
        {data}
      </Text>
    </Grid>
  );
};

export default AccordionDataItemComponent;
