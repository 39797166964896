import { Box, Input, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export const UserInput = ({
  label,
  type,
  value,
  onChange,
  isDisabled,
  testid,
}) => {
  const inputStyle = {
    bgColor: "white",
    border: "1px",
    borderColor: "rgba(0, 0, 0, 0.25)",
    width: ["100%", "100%", "70%", "70%"],
    color: "black",
  };
  const inputBoxStyle = {
    display: ["block", "block", "flex", "flex"],
    justifyContent: "space-between",
    width: "100%",
    mb: "5",
  };

  return (
    <Box sx={inputBoxStyle}>
      <Text py="2" lineHeight="16px" color={"white"}>
        {label}:
      </Text>
      <Input
        type={type}
        isDisabled={isDisabled}
        value={value}
        onChange={onChange}
        sx={inputStyle}
        data-testid={testid}
        isRequired
      />
    </Box>
  );
};

export const Info = ({ data, label, testid }) => {
  const inputStyle = {
    bgColor: "white",
    border: "1px",
    borderColor: "rgba(0, 0, 0, 0.25)",
    width: ["100%", "100%", "70%", "70%"],
    p: 2,
    borderRadius: "5px",
    color: "black",
  };
  const inputBoxStyle = {
    display: ["block", "block", "flex", "flex"],
    justifyContent: "space-between",
    width: "100%",
    mb: "5",
  };

  return (
    <Box data-testid={testid} sx={inputBoxStyle}>
      <Text py="2" lineHeight="16px" color="white">
        {label}:
      </Text>
      <Text sx={inputStyle}>{data}</Text>
    </Box>
  );
};
