import {
  FormControl,
  FormHelperText,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

export const UploadInput1 = ({ value, helper, label, onChange }) => {
  const inputStyle = {
    // px: "3",
    border: "1px",
    bgColor: useColorModeValue("lightMode.BgColor", "darkMode.BgColor"),
    borderColor: useColorModeValue(
      "lightMode.BgColor",
      "darkMode.mainTextColor"
    ),
  };

  const labelHead = {
    color: useColorModeValue("lightMode.btnBgColor", "darkMode.btnBgColor"),
    position: "absolute",
    top: "-13px",
    left: "13px",
    zIndex: "2",
    bgColor: useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor"),
    width: "fit-content",
    fontSize: "13.6px",
    px: 2,
  };

  return (
    <FormControl
      mb={[10, 0, 0, 0]}
      variant="floating"
      id={label}
      isRequired
      w={["100%", "75%", "75%", "65%"]}
      // px={[2, 9, 10, "200px"]}
      position="relative"
    >
      <Input
        type="text"
        value={value}
        sx={inputStyle}
        onChange={onChange}
        placeholder=""
      />
      <Text sx={labelHead}>{label}</Text>
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  );
};

const UploadInput = ({ value, helper, label, onChange }) => {
  const inputStyle = {
    px: "3",
    border: "1px",
    // bgColor: useColorModeValue("lightMode.BgColor", "darkMode.cardBgColor"),
    bgColor: "darkMode.cardBgColor",
    borderColor: useColorModeValue(
      "lightMode.BgColor",
      "darkMode.mainTextColor"
    ),
  };

  const labelHead = {
    // color: useColorModeValue("lightMode.btnBgColor", "darkMode.btnBgColor"),
    color: "darkMode.btnBgColor",
    position: "absolute",
    top: "-13px",
    left: "13px",
    zIndex: "2",
    // bgColor: useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor"),
    bgColor: "darkMode.grayBackground",
    // borderRadiuos: "5px",
    width: "fit-content",
    fontSize: "13.6px",
    px: 2,
  };

  return (
    <FormControl
      mb={[10, 0, 0, 0]}
      variant="floating"
      id={label}
      isRequired
      w={["100%", "45%", "45%", "45%"]}
      position="relative"
    >
      <Input
        type="text"
        value={value}
        sx={inputStyle}
        onChange={onChange}
        placeholder=""
      />
      <Text sx={labelHead}>{label}</Text>
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  );
};

export default UploadInput;
