import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

import { useNavigate } from 'react-router-dom';
import { useGetThreatSummary } from "../../../../services/query/dashboard";
import { scrollBarStyle } from "../../../../utils/helpers";
import PageLoader from "../../../Loaders/PageLoader";

const ThreatIntelligence = () => {
  const {
    data: threats,
    isLoading: isThreatLoading,
    refetch,
  } = useGetThreatSummary();

  const navigate = useNavigate();

  // const bgColor = useColorModeValue("lightMode.secBgColor", "darkMode.secBgColor")
  const bgColor = "darkMode.mainBgColor"
  // const cardColor = useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor")
  const cardColor = "darkMode.cardBgColor"

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Box>
      <Box
        bg={bgColor}
        p="10px 0px 16px 17px"
        borderTopRadius="10px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        Threat Intelligence
      </Box>

      <Box
        justifyContent="space-between"
        flexDir={{ base: "column", lg: "row" }}
        marginTop="-8px"
        bg={cardColor}
        p="14px 30px 14px 17px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="10px"
        h={{ base: "330px", xl: "310px" }}
        overflowY="auto"
        sx={scrollBarStyle}
      >
        {isThreatLoading && <PageLoader lineNo={2} />}
        {threats?.results?.length >= 1 ? (
          threats?.results?.map((item: any, i: number) => (
            <React.Fragment key={i}>
              {item?.file_analysis?.analysis?.plugins?.adobemalwareclassifier
                ?.results?.alerts?.[0] === "Malware detected" && (
                // <Link to={`/threat-intelligence/${item?.firmware}`}>
                <Flex
                  justifyContent="space-between"
                  mb="12px"
                  bg={"red.700" || "gray.500"}
                  color="white"
                  px="5px"
                  py="5px"
                  borderRadius={"5px"}
                  cursor="pointer"
                >
                  <Box onClick={() => {
                      localStorage.setItem("scan_uid", item?.firmware)
                      navigate("/malware/report/"+item?.firmware+"#threat-intel")}
                    }>
                    <Box fontSize={16}>{item?.device_name}</Box>
                    {/* {!item?.file_analysis?.signatures?.length && "No Alerts"} */}
                    {/* {item?.file_analysis?.signatures?.map(
                        (data: any, i: number) => (
                          <Box
                            color="gray.100"
                            key={i}
                            px="6px"
                            fontSize="12px"
                            bg={colorPallet[i] || "gray.500"}
                          >
                            {data}
                          </Box>
                        )
                      )} */}

                    <Box fontWeight="bold" fontSize={12}>
                      Malware Detected
                    </Box>
                  </Box>
                </Flex>
                // </Link>
              )}
            </React.Fragment>
          ))
        ) : (
          <Text>No threats at the moment</Text>
        )}
      </Box>
    </Box>
  );
};

export default ThreatIntelligence;
