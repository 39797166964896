import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import {
  useEditUserProfile,
  useGetUserProfile,
} from "../../../services/query/user";
import useCustomToast from "../../../utils/notification";
import ChangePassword from "./ChangePassword";
import { UserInput } from "../../CustomInput/UserInput";
import { Btn, Divide, Footer, Header } from "./Layout";
import ManageEngineSettings from "./ManageEngine";

const UserSettings = () => {
  const { data: userInfo, refetch } = useGetUserProfile();
  const { errorToast, successToast } = useCustomToast();
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const { mutate, isLoading: isCreateLoading } = useEditUserProfile({
    onSuccess: () => {
      successToast("Update successful");
      refetch();
    },
    onError: (err) => {
      errorToast(err.message);
    },
  });

  const userInfoUpdate = async (event) => {
    event.preventDefault();

    mutate({
      username: userName,
      email: email,
      phone_number: phoneNumber,
      country: country,
      organization: companyName,
    });
  };

  useEffect(() => {
    if (userInfo) {
      setUserName(userInfo?.username);
      setPhoneNumber(userInfo?.phone_number);
      setCountry(userInfo?.country);
      setEmail(userInfo?.email);
      setCompanyName(userInfo?.organization);
    }
  }, [userInfo]);

  return (
    <div>
      <Box>
        <Header title="Settings" label="Edit User Information" />

        <Divide />
        <Box
          px={["20px", "20px", "20px", "20"]}
          py={["20px", "20px", "20px", "10"]}
        >
          <Box as="form" onSubmit={userInfoUpdate}>
            <UserInput
              type="text"
              label="Username"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              isDisabled={userInfo?.is_admin === true ? false : true}
              testid="username"
            />
            <UserInput
              label="Phone Number"
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              isDisabled={false}
              testid="number"
            />
            <UserInput
              label="Country"
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              isDisabled={false}
              testid="country"
            />
            <UserInput
              label="Email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isDisabled={userInfo?.is_admin === true ? false : true}
              testid="email"
            />
            <UserInput
              label="Company Name"
              value={companyName}
              type="text"
              onChange={(e) => setCompanyName(e.target.value)}
              isDisabled={false}
              testid="company"
            />
            <Btn isLoading={isCreateLoading} />
          </Box>
        </Box>
        <Header title="Change Password" label="Security Update" />

        <Divide />
        <ChangePassword />
        
        <ManageEngineSettings></ManageEngineSettings>
      </Box>
    </div>
  );
};

export default UserSettings;
