import { Image, MenuItem, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuListItemType } from "../../../types";
import { useLogOut } from "../../../utils/helpers";

export const HeaderMenuItem = ({ item }: { item: MenuListItemType }) => {
  const logOut = useLogOut();
  const location = useLocation();

  const { colorMode, toggleColorMode } = useColorMode();

  const textColor = "white"

  return (
    <Link
      to={item.path ?? location.pathname}
      state={item.action === "SETTINGS" ? "settings" : "user"}
      onClick={
        (item.action === "LOG_OUT" && logOut) ||
        (item.action === "COLOR_MODE" && toggleColorMode) ||
        undefined
      }
    >
      <MenuItem 
        color="white" 
        display="flex"
        _active={{bgColor: "darkMode.secBgColor"}}
        _focus={{bgColor: "darkMode.secBgColor"}}
        _hover={{bgColor: "darkMode.secBgColor"}}
        >
        <Image
          width="17px"
          color="black"
          alt="dashboard"
          // src={colorMode === "dark" ? item.darkIcon : item.lightIcon}
          src={item.darkIcon}
        />
        <Text
          ml="2"
          color={textColor}
        >
          {item.action !== "COLOR_MODE"
            ? item.label
            : colorMode === "dark"
            ? "Light Mode"
            : "Dark Mode"}
        </Text>
      </MenuItem>
    </Link>
  );
};
