import { useState } from "react";
import { Box } from "@chakra-ui/react";
import useCustomToast from "../../../utils/notification";
import { useChangePassword } from "../../../services/query/user";
import { UserInput } from "../../CustomInput/UserInput";
import { Btn } from "./Layout";
import { useLogOut } from "../../../utils/helpers";

const ChangePassword = () => {
  const { errorToast, successToast } = useCustomToast();
  const logOut = useLogOut();
  const [oldPassword, setOldPasssword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const { mutate, isLoading: isSubmitting } = useChangePassword({
    onSuccess: () => {
      successToast("Update successful");
      setOldPasssword("");
      setNewPassword("");
      logOut();
    },
    onError: (err) => {
      errorToast(err?.response?.data?.new_password[0] || err.message);
    },
  });

  const changePasswordHandler = async (event) => {
    event.preventDefault();

    mutate({
      old_password: oldPassword,
      new_password: newPassword,
    });
  };

  return (
    <div>
      <Box
        px={["20px", "20px", "10", "20"]}
        py={["20px", "20px", "10", "10"]}
        as="form"
        onSubmit={changePasswordHandler}
      >
        <UserInput
          label="Old Password"
          value={oldPassword}
          type="password"
          onChange={(e) => setOldPasssword(e.target.value)}
          isDisabled={false}
          testid="old"
        />
        <UserInput
          label="New Password"
          value={newPassword}
          type="password"
          onChange={(e) => setNewPassword(e.target.value)}
          isDisabled={false}
          testid="new"
        />
        <Btn isLoading={isSubmitting} />
      </Box>
    </div>
  );
};

export default ChangePassword;
