import React from "react";
import Moment from "react-moment";
import { Box, Text } from "@chakra-ui/react";

export const TimeFormater = ({ date }) => {
  console.log("date", date);
  return (
    <Box>
      {" "}
      <Text>
        <Moment unix>{date}</Moment>
      </Text>
    </Box>
  );
};
