import UserSettings from "../../components/data/UserSettings/UserSettings";
import SettingsLayout from "../../components/Layouts/SettingsLayout";

const Settings = () => {
  return (
    <SettingsLayout>
      <UserSettings />
    </SettingsLayout>
  );
};

export default Settings;
