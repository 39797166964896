import React from "react";
import { Box, Link, Button, Checkbox, Flex, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export const AccountExist = ({ isLoading, disabled }) => {
  return (
    <Flex
      mt={4}
      columnGap={4}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Flex my={4} columnGap={1}>
        <Text fontSize={13} fontWeight={600}>
          Already have an account?
        </Text>
        <Box fontSize={13} fontWeight={600} color="#59A9E9">
          <RouterLink to="/login">Login</RouterLink>
        </Box>
      </Flex>

      <Button
        type="submit"
        colorScheme="blue"
        isLoading={isLoading}
        disabled={disabled}
      >
        Sign up
      </Button>
    </Flex>
  );
};

export const Terms = ({ onChange, isChecked }) => {
  return (
    <Checkbox isChecked={isChecked} onChange={onChange}>
      <Text fontSize={13} fontWeight={500}>
        I agree to Trusted Partners <Link color="blue">Terms Of Service</Link>{" "}
        and <Link color="blue">Privacy Policy</Link>
      </Text>
    </Checkbox>
  );
};
