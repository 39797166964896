import React from "react";
import {
  Box,
  GridItem,
  Image,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

const Card = ({ lightSrc, darkSrc, label, data }) => {
  const { colorMode } = useColorMode();

  // const bgStyle = useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor")
  const bgStyle = "darkMode.mainBgColor"
  const textStyle = "darkMode.mainTextColor"
  // const imageStyle = colorMode === "light" ? lightSrc : darkSrc
  const imageStyle = darkSrc

  return (
    <GridItem
      pos="relative"
      h="100px"
      w="100%"
      bg={bgStyle}
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="25px"
      color={textStyle}
    >
      <Box pos="absolute" bottom="16px" left="16px">
        <Box fontWeight="400" fontSize="28px" color={textStyle}>
          {data}
        </Box>
        <Box color={textStyle}>{label}</Box>
      </Box>
      <Box pos="absolute" top="16px" right="16px">
        <Image
          boxSize="42px"
          objectFit="cover"
          src={imageStyle}
          alt="computer"
        />
      </Box>
    </GridItem>
  );
};

export default Card;
