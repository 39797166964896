import { FormControl, Input, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const UpdateLayout = ({ onChange, value, disabled, testId, label }) => {
  const inputStyle = {
    px: "3",
    border: "1px",
    bgColor: useColorModeValue("lightMode.BgColor", "darkMode.BgColor"),
    borderColor: useColorModeValue(
      "lightMode.BgColor",
      "darkMode.mainTextColor"
    ),
  };

  const labelHead = {
    color: useColorModeValue("lightMode.btnBgColor", "darkMode.btnBgColor"),
    position: "absolute",
    top: "-13px",
    left: "13px",
    zIndex: "2",
    bgColor: useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor"),
    width: "fit-content",
    fontSize: "13.6px",
    px: 2,
  };

  return (
    <FormControl
      mb="10"
      variant="floating"
      id="UID"
      w="100%"
      position="relative"
    >
      <Input
        type="text"
        sx={inputStyle}
        disabled={disabled}
        value={value}
        onChange={onChange}
        data-testid={testId}
      />
      <Text sx={labelHead}>{label}</Text>
    </FormControl>
  );
};

export default UpdateLayout;
