import axios from "axios";
import { apiURL } from "../../environment";
import { LOGIN, INVITE_LOGIN } from "../apiUrl";

export const getLogin = async (body) => {
  const res = await axios.post(apiURL + LOGIN, body);
  return res.data;
};

export const getInviteLogin = async (body) => {
  const res = await axios.post(
    `${apiURL}${INVITE_LOGIN}${body.token}/`,
    body.values
  );
  return res.data;
};
