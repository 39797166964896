import {
  DELETE_COMPARISON,
  GET_COMPARISON,
  GET_COMPARISONS,
  INITIATE_COMPARISON,
} from "../apiUrl";
import axiosInstance from "../axiosInstance";

export const initiateComparison = async (body) => {
  const res = await axiosInstance.post(INITIATE_COMPARISON, body);
  return res.data;
};

export const getComparison = async ({ queryKey }) => {
  const [, page, ids, redo] = queryKey;

  const first = ids[0];
  const second = ids[1];

  const com = `${first},${second}`;

  const res = await axiosInstance.get(
    `${GET_COMPARISON}?page=${page}&firmwares=${com}&redo=${redo}`
  );
  return res.data;
};

export const getComparisons = async ({ queryKey }) => {
  const [, limit, offset] = queryKey;

  const res = await axiosInstance.get(
    `${GET_COMPARISONS}?limit=${limit}&offset=${offset}`
  );
  return res.data;
};

export const deleteComparison = async (body) => {
  const res = await axiosInstance.delete(`${DELETE_COMPARISON}/${body.id}`);
  return res.data;
};
