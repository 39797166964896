import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { InfoItemComponent } from "../../DropDownButtonComponent";

const TopData = ({ data, name, safe }) => {
  const navigate = useNavigate();

  const textColorMode = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.mainTextColor"
  );
  const cardBgColor = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );

  const btnBgColorMode = useColorModeValue(
    "lightMode.secBgColor",
    "lightMode.btnBgColor"
  );

  return (
    <Flex
      //   gap={4}
      justifyContent={"space-between"}
      flexDirection={["column", "column", "row", "row"]}
    >
      {/* <Box width={["100%", "100%", "45%", "45%"]}>
        <Text
          color={textColorMode}
          fontWeight={"semibold"}
          fontSize={[15, 15, "26px", "26px"]}
        >
          {name}
        </Text>

        <Text
          color={textColorMode}
          fontWeight={"semibold"}
          fontSize={[12, 12, "18px", "18px"]}
        >
          {data?.scan_id}
        </Text>

        <Flex gap={4} my="14px">
          <Button
            py="4"
            as={Button}
            fontWeight={"700"}
            bgColor={btnBgColorMode}
            fontSize={[10, 10, "13px", "13px"]}
            height={["30px", "30px", "40px", "40px"]}
            onClick={() => navigate("/binary-search")}
          >
            Yara Search
          </Button>
          
        </Flex>
      </Box> */}

      <Box
        p="2em 20px"
        shadow="lg"
        background="#d9e3ee"
        borderRadius={"15px"}
        bgColor={cardBgColor}
        width={["100%", "100%", "100%", "100%"]}
      >
        <Text
          pb="1em"
          fontWeight="600"
          color={textColorMode}
          fontSize={["13px", "13px", "18px", "18px"]}
        >
          General Information
        </Text>
        <Text
          color={textColorMode}
          fontWeight={"semibold"}
          fontSize={[13, 13, "18px", "18px"]}
        >
          {name}
        </Text>

        <Text
          color={textColorMode}
          fontWeight={"semibold"}
          fontSize={[11, 11, "15px", "15px"]}
        >
          {data?.scan_id}
        </Text>
        {safe &&
          <Text
            color={textColorMode}
            fontSize={[13, 13, "18px", "18px"]}
            marginTop={4}
            fontWeight={500}
            padding={2}
            backgroundColor="rgba(100, 255, 150, 0.2)"
            border="1px solid rgba(255, 255, 255, 0.5)"
            borderLeft="3px solid rgba(255, 255, 255, 0.5)"
            borderRadius={5}
          >
            Safe to execute/deploy -- no malware found
          </Text>
        }
        {!safe &&
          <Text
            color={textColorMode}
            fontSize={[13, 13, "18px", "18px"]}
            marginTop={4}
            fontWeight={500}
            padding={2}
            backgroundColor="rgba(255, 120, 150, 0.2)"
            border="1px solid rgba(255, 255, 255, 0.5)"
            borderLeft="3px solid rgba(255, 255, 255, 0.5)"
            borderRadius={5}
          >
            Not Safe to execute/deploy -- malware found
          </Text>
        }
        <br></br>

        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          
          <InfoItemComponent text="Response Code" data={data?.response_code} />
          <InfoItemComponent text="Verbose Message" data={data?.verbose_msg} />
          <InfoItemComponent text="Resource" data={data?.resource} />
          <InfoItemComponent text="Md5" data={data?.md5} />
          <InfoItemComponent text="Sha1" data={data?.sha1} />
          <InfoItemComponent text="Sha256" data={data?.sha256} />
          <InfoItemComponent text="Scan Date" data={data?.scan_date} />
          <InfoItemComponent text="Perma Link" perma={data?.permalink} />
          <InfoItemComponent text="Positives" data={data?.positives} />
          <InfoItemComponent text="Total" data={data?.total} />
        </Grid>
      </Box>
    </Flex>
  );
};

export default TopData;
