import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { MalwareSettings } from "../../../../assets";
import BoxDesign from "./BoxDesign";
import { Layout1 } from "./PdfLayout";

const MalwareData = ({ data }) => {
  return (
    <Box w="95%" mx="auto" mt="20px">
      <Text fontSize="35px" mt="50px" fontWeight="700">
        General Information
      </Text>
      <BoxDesign />
      <Box mt={"50px"}>
        <Box display="flex" alignItems="center">
          <Image src={MalwareSettings} alt="analysis" w="57px" h="50px" />

          <Text fontSize="30px" ml="20px" fontWeight="600">
            Malware Data
          </Text>
        </Box>
        <Layout1 color="white">
          <Text w="30%" pl="9px">
            Response_code:
          </Text>
          <Text w="65%">{data?.response_code || "No Result"}</Text>
        </Layout1>

        <Layout1 color="black">
          <Text w="30%" pl="9px">
            Verbose_message:
          </Text>
          <Text w="65%">{data?.verbose_msg || "No Result"} </Text>
        </Layout1>

        <Layout1 color="white">
          <Text w="30%" pl="9px">
            Resource:
          </Text>
          <Text w="65%">{data?.resource || "No Result"}</Text>
        </Layout1>

        <Layout1 color="black">
          <Text w="30%" pl="9px">
            Scan_id:
          </Text>
          <Text w="65%">{data?.scan_id || "No Result"}</Text>
        </Layout1>

        <Layout1 color="white">
          <Text w="30%" pl="9px">
            Md5:
          </Text>
          <Text w="65%">{data?.md5 || "No Result"} </Text>
        </Layout1>

        <Layout1 color="black">
          <Text w="30%" pl="9px">
            Sha1:
          </Text>
          <Text w="65%">{data?.sha1 || "No Result"} </Text>
        </Layout1>

        <Layout1 color="white">
          <Text w="30%" pl="9px">
            Sha256:
          </Text>
          <Text w="65%">{data?.sha256 || "No Result"}</Text>
        </Layout1>

        <Layout1 color="black">
          <Text w="30%" pl="9px">
            Scan_date:
          </Text>
          <Text w="65%">{data?.scan_date || "No Result"}</Text>
        </Layout1>
        {/* 
        <Layout1 color="white">
          <Text w="30%" pl="9px">
            Permalink:
          </Text>
          <Text w="65%" as={"a"} href={data?.permalink}>
            {data?.permalink || "No Result"}
          </Text>
        </Layout1> */}

        <Layout1 color="black">
          <Text w="30%" pl="9px">
            Positives:
          </Text>
          <Text w="65%">{data?.positives || "No Result"}</Text>
        </Layout1>

        <Layout1 color="white">
          <Text w="30%" pl="9px">
            Total:
          </Text>
          <Text w="65%">{data?.total || "No Result"}</Text>
        </Layout1>
      </Box>
    </Box>
  );
};

export default MalwareData;
