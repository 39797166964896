import { useQuery, useMutation } from "react-query";
import { getFirmwareScanList, deleteFirmware } from "../api/firmware";
import { GET_FIRMWARE_SCAN_LIST, DELETE_FIRMWARE } from "../queryKeys";

export const useFirmwareScanList = () => {
  const { data, isLoading, error } = useQuery(
    [GET_FIRMWARE_SCAN_LIST],
    getFirmwareScanList
  );

  return { data, isLoading, error };
};

export const useDeleteFirmware = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteFirmware, {
    mutationKey: DELETE_FIRMWARE,
    ...options,
  });

  return { mutate, isLoading };
};
