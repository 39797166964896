// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="file"]::file-selector-button {
    border: 2px solid white;
    padding: 0.2em 0.4em;
    border-radius: 0.2em;
    background-color: #4F5776;
    transition: 1s;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/FileUpload/FileUpload.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,oBAAoB;IACpB,oBAAoB;IACpB,yBAAyB;IACzB,cAAc;IACd,YAAY;AAChB","sourcesContent":["input[type=\"file\"]::file-selector-button {\n    border: 2px solid white;\n    padding: 0.2em 0.4em;\n    border-radius: 0.2em;\n    background-color: #4F5776;\n    transition: 1s;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
