export const PUBLIC_PATHS = {
  LOGIN: "/login",
  INVITE_LOGIN: "/users/invitation/:token",
  SIGNUP: "/signup",
};

export const PRIVATE_PATHS = {
  MANAGE_ENGINE: "/patch-manager",
  BINARY_SEARCH: "/binary-search",
  BINARY_SEARCH_LIST: "/binary-search/list",
  BINARY_SEARCH_DETAILS: "/binary-search/:search_id",
  ANALYSIS: "/analysis/:scan_uid",
  TEAMS: "/teams",
  TEAM_INFO: "/team/:id",
  EMULATOR_SCAN_DETAILS: "/emulator/:id",
  ANALYSIS_UPDATE: "/analysis/update/:scan_uid",
  ANALYSIS_RESULT: "/analysis/compare/result/:uid1/:uid2",
  MODEL: "/analysis",
  USERPROFILE: "/user-profile",
  SETTINGS: "/settings",
  FILEUPLOAD: "/file-upload",
  HONEYPOT: "/honeypot",
  DASHBOARD: "/dashboard",
  SEARCH: "/dashboard/search",
  EMULATOR: "/emulator",
  ALL_THREAT: "/all-threat",
  COMPARED_FRAMEWORKS: "/analysis/compared-list",
  INFO: "/info",
  BASIC_SEARCH: "/basic-search",
  ADVANCED_SEARCH: "/advanced-search",
  SYSTEMS_HEALTH: "/systems-health",
  ERROR_LOGS: "/error-logs",
  THREAT: "/threat-intelligence/:uid",
  BROWSE_FIRMWARE: "/browse-firmware",
  ANALYSIS_PDF: "/analysis-pdf/:id",
  SCAN_SUMMARY: "/security/scan-summary/:id",
  SCAN_OVERVIEW: "/security/scan-overview/:scan_uid",
  MALWARE_REPORT: "/malware/report/:id",
  MALWARE_RESULT: "/malware/result/:id",
  UPLOAD_VIRUS: "/file-upload/virus",
  PATCH_MANAGEMENT: "/patch-management",
  VIRUS: "/malware",
  HYBRID_UPLOAD: "/security/file-upload",
  HYBRID: "/security",
};
