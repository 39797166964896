import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import ErrorComponent from "../../components/data/ErrorComponent";
import CustomHeader from "../../components/common/CustomHeader";
import LoadingComponent from "../../components/Loaders/LoadingComponent";
import { useGetAnalysis } from "../../services/query/analysis";
import { useUpdateAnalysis } from "../../services/query/analysis";
import useCustomToast from "../../utils/notification";
import UpdateLayout from "../../components/CustomInput/UpdateLayout";
import { Btn } from "../../components/data/UserSettings/Layout";

const AnalysisUpdate = () => {
  const holdingBox = {
    px: [2, 9, 10, "200px"],
    py: [2, 3, 5, 5],
  };

  const { scan_uid } = useParams();
  const { data, isLoading, error, refetch } = useGetAnalysis(
    scan_uid as string
  );
  const detail = data?.message?.firmware?.meta_data;
  const { errorToast, successToast } = useCustomToast();
  const [deviceName, setDeviceName] = useState("");
  const [deviceClass, setDeviceClass] = useState("");
  const [vendor, setVendor] = useState("");
  const [uid, setUid] = useState(scan_uid);
  const { mutate, isLoading: isSubmitting } = useUpdateAnalysis({
    onSuccess: () => {
      successToast("Update successful");
    },
    onError: (err) => {
      errorToast(err.message);
    },
  });

  useEffect(() => {
    if (data) {
      setDeviceName(detail?.device_name);
      setDeviceClass(detail?.device_class);
      setVendor(detail?.vendor);
    }
  }, [data]);

  const updateAnalysisHandler = async (event) => {
    event.preventDefault();
    mutate({
      scan_uid: uid,
      device_class: deviceClass,
      device_name: deviceName,
      vendor: vendor,
    });
  };

  return (
    <div>
      <CustomHeader title=" Update Firmware" />
      <Box>
        {isLoading && <LoadingComponent />}
        <Box as="form" onSubmit={updateAnalysisHandler}>
          {error && <ErrorComponent message={error.message} reload={refetch} />}
          <Box sx={holdingBox}>
            <UpdateLayout
              value={scan_uid}
              disabled={true}
              onChange={(e) => setUid(e.target.value)}
              testId={"uid"}
              label="UID"
            />
            <UpdateLayout
              value={deviceName}
              disabled={false}
              onChange={(e) => setDeviceName(e.target.value)}
              testId={"class"}
              label="Device Name"
            />
            <UpdateLayout
              value={deviceClass}
              disabled={false}
              onChange={(e) => setDeviceClass(e.target.value)}
              testId={"part"}
              label="Device Class"
            />
            <UpdateLayout
              value={vendor}
              disabled={false}
              onChange={(e) => setVendor(e.target.value)}
              testId={"name"}
              label="Vendor"
            />
          </Box>
          <Box px={[2, 9, 10, "200px"]}>
            <Btn isLoading={isSubmitting} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AnalysisUpdate;
