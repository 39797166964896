import { Grid, Box } from "@chakra-ui/react";
import FirmwareBox from "./FirmwareBox";
import {
  Architecture,
  DataLost,
  DeviceClasses,
  ExploitMitigationChart,
  ElfExecutables,
  FirmwareContainer,
  FirmwareDoughnut,
  FirmwareTable,
  UnpackerStats,
  ReleaseDateBarChart,
  SoftwareComponent,
  UnpackerUsage,
} from "./FirmwareDatas";
import { Info, General } from "../../../assets";
import ErrorComponent from "../ErrorComponent";
import LoadingComponent from "../../Loaders/LoadingComponent";

const FirmwareSoho = ({ data, isLoading, error }) => {
  return (
    <Box>
      {isLoading && <LoadingComponent />}
      {error && <ErrorComponent message={"error"} />}
      {data && (
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ]}
          gap={6}
          px={[2, 9, 10, 10]}
          py={[2, 3, 5, 5]}
        >
          <FirmwareBox title="General" icon={General}>
            <FirmwareTable data={data} />
          </FirmwareBox>
          <FirmwareBox title="Unpacker Stats" icon={General}>
            <UnpackerStats data={data} />
          </FirmwareBox>
          <FirmwareBox title="Vendors" icon={General}>
            <Box mx="auto" w="90%" d="flex" alignItems="center">
              <FirmwareDoughnut data={data} />
            </Box>
          </FirmwareBox>
          <FirmwareBox title="Device Classes" icon={General}>
            <Box mx="auto" w="90%" d="flex" alignItems="center">
              <DeviceClasses data={data} />
            </Box>
          </FirmwareBox>
          <FirmwareBox title="Firmware Container" icon={General}>
            <Box mx="auto" w="90%" d="flex" alignItems="center">
              <FirmwareContainer data={data} />
            </Box>
          </FirmwareBox>
          <FirmwareBox title="Unpacker Usage" icon={General}>
            <Box mx="auto" w="90%" d="flex" alignItems="center">
              <UnpackerUsage data={data} />
            </Box>
          </FirmwareBox>
          <FirmwareBox title="Data Lost File Types" icon={Info}>
            <Box mx="auto" w="90%" d="flex" alignItems="center">
              <DataLost data={data} />
            </Box>
          </FirmwareBox>
          <FirmwareBox title="Architectures" icon={Info}>
            <Box mx="auto" w="90%" d="flex" alignItems="center">
              <Architecture data={data} />
            </Box>
          </FirmwareBox>
          <FirmwareBox title="Software Component" icon={Info}>
            <Box mx="auto" w="90%" d="flex" alignItems="center">
              <SoftwareComponent data={data} />
            </Box>
          </FirmwareBox>
          <FirmwareBox title="Exploit Mitigation" icon={Info}>
            <ExploitMitigationChart data={data} />
          </FirmwareBox>
          <FirmwareBox title="ELF Executables" icon={Info}>
            <ElfExecutables data={data} />
          </FirmwareBox>

          <FirmwareBox title="Release Dates" icon={Info}>
            <ReleaseDateBarChart data={data} />
          </FirmwareBox>
        </Grid>
      )}
    </Box>
  );
};

export default FirmwareSoho;
