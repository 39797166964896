import {
  BsFunnel,
  //  BsInfoCircleFill
} from "react-icons/bs";
import { FaHeartbeat } from "react-icons/fa";
//Removing this  for now until the honeypot feature is implemented
// import { GiHoneycomb } from "react-icons/gi";
import {
  MdDashboard,
  MdOutlineAnalytics,
  MdOutlineReportGmailerrorred,
  // MdReportGmailerrorred,
} from "react-icons/md";

// Removing the Honeycombs for now until the honeypot feature is implemented
import {
  Analysis,
  Combo,
  Disturb,
  Error,
  Funnel,
  // Honeycombs,
  Logout,
  LogoutDark,
  Settings,
  SettingsDark,
  Sun,
  User,
  UserDark,
} from "../../assets";
import { MenuListItemType } from "../../types";

export const sideLinks = [
  {
    path: "/dashboard",
    image: Combo,
    name: "Dashboard",
    lightIcon: <MdDashboard />,
    darkIcon: <MdDashboard color="#5D8EB0" />,
    hoverText: "",
  },
  {
    path: "/analysis",
    image: Analysis,
    name: "Firmware Analysis",
    lightIcon: <MdOutlineAnalytics />,
    darkIcon: <MdOutlineAnalytics color="#5D8EB0" />,
    hoverText: "Analyze and extract firmware images and help in identifying code, files, and other information embedded in the binary image of firmware",
  },
  //Removing this  for now until the honeypot feature is implemented
  // {
  //   path: "/honeypot",
  //   image: Honeycombs,
  //   name: "Honeypots",
  //   lightIcon: <GiHoneycomb />,
  //   darkIcon: <GiHoneycomb color="#5D8EB0" />,
  // hoverText: "",
  // },
  // {
  //   path: "/emulator",
  //   image: Funnel,
  //   name: "Emulator",
  //   lightIcon: <BsFunnel />,
  //   darkIcon: <BsFunnel color="#5D8EB0" />,
  // hoverText: "",
  // },
  {
    path: "/malware",
    image: Funnel,
    name: "Malware Analysis",
    lightIcon: <BsFunnel />,
    darkIcon: <BsFunnel color="#5D8EB0" />,
    hoverText: "The uploaded file will be checked by malware scanners.",
  },
  // {
  //   path: "/info",
  //   image: Combo,
  //   name: "Info",
  //   lightIcon: <BsInfoCircleFill />,
  //   darkIcon: <BsInfoCircleFill color="#5D8EB0" />,
  // hoverText: "",
  // },
  {
    path: "/security",
    image: Funnel,
    name: "Security Analysis",
    lightIcon: <BsFunnel />,
    darkIcon: <BsFunnel color="#5D8EB0" />,
    hoverText: "Scans are performed in search of vulnerabilities in the firmware",
  },
  {
    path: "/systems-health",
    image: Analysis,
    name: "Health",
    lightIcon: <FaHeartbeat />,
    darkIcon: <FaHeartbeat color="#5D8EB0" />,
    hoverText: "",
  },
  {
    path: "/error-logs",
    image: Error,
    name: "Error Logs",
    lightIcon: <MdOutlineReportGmailerrorred />,
    darkIcon: <MdOutlineReportGmailerrorred color="#5D8EB0" />,
    hoverText: "",
  },
];

export const navLinks = [
  {
    path: "/dashboard",
    image: Combo,
    name: "Dashboard",
    lightIcon: <MdDashboard />,
    darkIcon: <MdDashboard color="#5D8EB0" />,
    hoverText: "",
  },
  {
    path: "/analysis",
    image: Analysis,
    name: "Firmware Analysis",
    lightIcon: <MdOutlineAnalytics />,
    darkIcon: <MdOutlineAnalytics color="#5D8EB0" />,
    hoverText: "Analyze and extract firmware images and help in identifying code, files, and other information embedded in the binary image of firmware.",
  },

  {
    path: "/security",
    image: Funnel,
    name: "Security Analysis",
    lightIcon: <MdOutlineAnalytics />,
    darkIcon: <MdOutlineAnalytics color="#5D8EB0" />,
    hoverText: "Scans are performed in search of vulnerabilities in the firmware",
  },

  {
    path: "/analysis",
    image: Analysis,
    name: "Dynamic Analysis",
    lightIcon: <MdOutlineAnalytics />,
    darkIcon: <MdOutlineAnalytics color="#5D8EB0" />,
    hoverText: "",
  },
  //Removing this  for now until the honeypot feature is implemented
  // {
  //   path: "/honeypot",
  //   image: Honeycombs,
  //   name: "Honeypots",
  //   lightIcon: <GiHoneycomb />,
  //   darkIcon: <GiHoneycomb color="#5D8EB0" />,
  // hoverText: "",
  // },
  // {
  //   path: "/emulator",
  //   image: Funnel,
  //   name: "Emulator",
  //   lightIcon: <BsFunnel />,
  //   darkIcon: <BsFunnel color="#5D8EB0" />,
  // hoverText: "",
  // },
  // {
  //   path: "/info",
  //   image: Combo,
  //   name: "Info",
  //   lightIcon: <BsInfoCircleFill />,
  //   darkIcon: <BsInfoCircleFill color="#5D8EB0" />,
  // hoverText: "",
  // },
  {
    path: "/systems-health",
    image: Analysis,
    name: "Health",
    lightIcon: <FaHeartbeat />,
    darkIcon: <FaHeartbeat color="#5D8EB0" />,
    hoverText: "",
  },
  {
    path: "/error-logs",
    image: Error,
    name: "Error Logs",
    lightIcon: <MdOutlineReportGmailerrorred />,
    darkIcon: <MdOutlineReportGmailerrorred color="#5D8EB0" />,
    hoverText: "",
  },
  {
    path: "/emulator",
    image: Funnel,
    name: "Emulator",
  },
  {
    path: "/scan-summary/:id",
    image: Funnel,
    name: "Scan Summary",
  },
  {
    path: "/scan-overview/:id",
    image: Funnel,
    name: "Scan Overview",
  },

  {
    path: "/malware",
    image: Funnel,
    name: "Malware Analysis",
    lightIcon: <MdOutlineAnalytics />,
    darkIcon: <MdOutlineAnalytics color="#5D8EB0" />,
    hoverText: "The uploaded file will be checked by malware scanners.",
  },
  {
    path: "/patch-management",
    image: Funnel,
    name: "Patch Management",
    lightIcon: <MdOutlineAnalytics />,
    darkIcon: <MdOutlineAnalytics color="#5D8EB0" />,
    hoverText: "Patch management page",
  },
];

export const menuLists: MenuListItemType[] = [
  {
    darkIcon: UserDark,
    // hoverText: "",
    lightIcon: User,
    label: "User Profile",
    path: "/user-profile",
  },
  {
    darkIcon: SettingsDark,
    // hoverText: "",
    lightIcon: Settings,
    label: "Settings",
    path: "/settings",
    action: "SETTINGS",
  },
  // {
  //   darkIcon: Sun,
  // hoverText: "",
  //   lightIcon: Disturb,
  //   label: "Dark Mode",
  //   action: "COLOR_MODE",
  // },
  {
    darkIcon: LogoutDark,
    // hoverText: "",
    lightIcon: Logout,
    label: "Logout",
    action: "LOG_OUT",
  },
];

export const adminLists: MenuListItemType[] = [
  {
    darkIcon: UserDark,
    // hoverText: "",
    lightIcon: User,
    label: "User Profile",
    path: "/user-profile",
  },
  {
    darkIcon: SettingsDark,
    // hoverText: "",
    lightIcon: Settings,
    label: "Settings",
    path: "/settings",
    action: "SETTINGS",
  },
  // {
  //   darkIcon: UserDark,
  // hoverText: "",
  //   lightIcon: User,
  //   label: "Teams",
  //   path: "/teams",
  // },
  // {
  //   darkIcon: Sun,
  // hoverText: "",
  //   lightIcon: Disturb,
  //   label: "Dark Mode",
  //   action: "COLOR_MODE",
  // },
  {
    darkIcon: LogoutDark,
    // hoverText: "",
    lightIcon: Logout,
    label: "Logout",
    action: "LOG_OUT",
  },
];

export const adminRoutes = [
  { id: 1, title: "User Profile", route: "/user-profile" },
  { id: 2, title: "Settings", route: "/settings" },
  // { id: 3, title: "Teams", route: "/teams" },
];

export const routes = [
  { id: 1, title: "User Profile", route: "/user-profile" },
  { id: 2, title: "Settings", route: "/settings" },
];

export const data = [
  {
    key: "device_class",
    val: "Python",
  },
  {
    key: "device_name",
    val: "windows",
  },
  {
    key: "device_part",
    val: "",
  },
  {
    key: "hid",
    val: "Python windows v. 8.0.10",
  },
  {
    key: "included_files",
    val: "['f3109977125d4a3a3ffa17462cfc31799589f466a51d226d1d1f87df2f267627_57891','9b407d19166490e6b1e25dd0fd9cee8782c78894ce200958d13a2f2e452fc715_10469','55c6f1ef4650181bca5daee09dcb4d185303ca2f8db690cc9456e263529cb85a_97710','92ee4133a6d471140c15f5911c3eef0bf2f85276bc51450d1c5a9233d84aee5d_85732','b619ae6e9c7e352624f0b491aff9588d7b8c9e83092b2b6b84d1de8f719962d4_12028','6b7b1e52073662c53cba79d44e2dff6ef19ee3f7cd045ffce06243556c8ce34c_617984']",
  },
  {
    key: "number_of_included_files",
    val: "6",
  },
  {
    key: "release_date",
    val: "2019-06-11",
  },
  {
    key: "size",
    val: "27505064",
  },
  {
    key: "total_files_in_firmware",
    val: "6",
  },
  {
    key: "vendor",
    val: "Python",
  },
  {
    key: "vendor",
    val: "8.0.10",
  },
];

export const data1 = [
  {
    key: "URI",
    value: 33,
  },
  {
    key: "IPV4",
    value: 7,
  },
  {
    key: "IPV6",
    value: 15,
  },
];

export const domainTableHead = [
  "Domain",
  "Dangerous",
  "Ip",
  "Country",
  "City",
  "Region",
  "Latitude",
  "Longitude",
];
