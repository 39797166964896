import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { printDocument } from "../../components/data/Analysis/PDF";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { useGetAnalysis } from "../../services/query/analysis";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/Loaders/PageLoader";
import ErrorComponent from "../../components/data/ErrorComponent";
import CustomHeader from "../../components/common/CustomHeader";
import MalwareData from "../../components/data/Analysis/Pdf/MalwareData";
import PdfScans from "../../components/data/Analysis/Pdf/PdfScans";
import GeneralInfo from "../../components/data/Analysis/Pdf/GeneralInfo";
import { useGetHybridReport } from "../../services/query/hybrid";
import { useGetVirusReport } from "../../services/query/virus";

const AnalysisPDF = () => {
  const [para, setPara] = React.useState("");

  const { id } = useParams();

  React.useEffect(() => {
    const myId = id?.split("_");

    setPara(!myId ? `${id}` : myId![0]);
  });

  const {
    data: analysisData,
    isLoading,
    error,
    refetch,
  } = useGetAnalysis(id as string);

  const { data: hybridData } = useGetHybridReport(para as string);

  const { data: virusData } = useGetVirusReport(para as string);

  const meta = analysisData?.message?.firmware?.meta_data;
  const analysis = analysisData?.message?.firmware?.analysis;

  return (
    <Box>
      <Box>
        <CustomHeader title="Analysis Pdf" />

        <Button my="15px" onClick={() => printDocument()}>
          <AiOutlineCloudDownload size="1.2rem" />
          Download
        </Button>

        {isLoading && <PageLoader />}
        {error && (
          <ErrorComponent
            message="There was an error Loading the files"
            reload={refetch}
          />
        )}
        <Box id="pdf" bg="#162734" w="100%" pb="40px">
          <Box mx="auto" color="white">
            {analysisData && <GeneralInfo meta={meta} analysis={analysis} />}

            {virusData && <MalwareData data={virusData?.data} />}

            {hybridData && <PdfScans data={hybridData?.data?.scanners} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AnalysisPDF;
