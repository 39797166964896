import { useQuery, useMutation } from "react-query";
import { AxiosError } from "axios";
import {} from "../api/comparison";
import {
  postHybrid,
  getHybridList,
  getHybridReport,
  getHybridReportSummary,
  getHybridOverview,
} from "../api/hybrid";

import {
  HYBRID_UPLOAD,
  HYBRID_LIST,
  SCAN_OVERVIEW,
  SCAN_SUMMARY,
} from "../queryKeys";
import { HYBRID_OVERVIEW } from "../apiUrl";

export const useUploadHybrid = (options = {}) => {
  const { mutate, isLoading } = useMutation(postHybrid, {
    mutationKey: HYBRID_UPLOAD,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetHybridReport = (sha: string, options = {}) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [SCAN_OVERVIEW, sha],
    getHybridReport,
    { ...options }
  );
  return { data, isLoading, error, refetch };
};

export const useGetHybridOverview = (scan_uid: string, options = {}) => {
  console.log("Using get hybrid overview")
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [HYBRID_OVERVIEW, scan_uid],
    getHybridOverview,
    { ...options }
  );
  console.log(data)
  console.log(error)
  return { data, isLoading, error, refetch };
};

export const useGetHybridReportSummary = (sha: string, options = {}) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [SCAN_SUMMARY, sha],
    getHybridReportSummary,
    { ...options }
  );
  return { data, isLoading, error, refetch };
};

export const useGetHybridList = (options = {}) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [HYBRID_LIST],
    getHybridList,
    { ...options }
  );
  return { data, isLoading, error, refetch };
};
