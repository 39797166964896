import React, { FC } from "react";
import { FormControl, Input, Text, useColorModeValue } from "@chakra-ui/react";
import { InputProps } from "../../types";

const InputField: FC<InputProps> = ({ onChange, type, value, label }): any => {
  const inputStyle = {
    px: "3",
    border: "1px",
    h: "10vh",
    bgColor: useColorModeValue("lightMode.BgColor", "darkMode.BgColor"),
    borderColor: useColorModeValue(
      "lightMode.BgColor",
      "darkMode.mainTextColor"
    ),
  };

  const labelHead = {
    color: useColorModeValue("lightMode.btnBgColor", "darkMode.btnBgColor"),
    position: "absolute",
    top: "-13px",
    left: "13px",
    zIndex: "2",
    bgColor: useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor"),
    width: "fit-content",
    fontSize: "13.6px",
    px: 2,
  };

  return (
    <FormControl
      mb={[10, 0, 0, 0]}
      variant="floating"
      id="Device Name"
      isRequired
      w={["100%", "45%", "45%", "65%"]}
      position="relative"
    >
      <Input
        type={type}
        value={value}
        sx={inputStyle}
        onChange={onChange}
        placeholder=""
        isRequired
      />
      <Text sx={labelHead}>{label}</Text>
    </FormControl>
  );
};

export default InputField;
