import React from "react";
import { Box, Button, Text } from "@chakra-ui/react";

const ErrorComponent = ({
  message,
  reload,
  header,
}: {
  message: string;
  reload?: () => void;
  header?;
}) => {
  return (
    <Box p="2em" mt="1em" mb="1em" borderWidth={2} borderRadius="0.75em">
      {header && <Text color="black">{header}</Text>}
      <Text color="red">{message}</Text>
      {reload && (
        <Button onClick={reload} mt="1em">
          Reload
        </Button>
      )}
    </Box>
  );
};

export default ErrorComponent;
