import React from "react";
import { TimeFormater } from "../../../common/TimeFormater";
import Accordions from "../Accordion";

export const Type = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="File type"
      dataItems={[
        {
          title: "Full",
          data: analysis?.file_type?.full,
        },
        {
          title: "MIME",
          data: analysis?.file_type?.mime,
        },
        {
          title: "Summary",
          data:
            analysis?.file_type?.summary?.length > 0
              ? JSON.stringify(analysis?.file_type?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};

export const Lookup = ({ analysis }) => {
  return (
    <Accordions
      name="Hash lookup"
      dataItems={[
        // {
        //   title: "Time of Analysis",
        //   data: <TimeFormater date={analysis?.hashlookup?.analysis_date} />,
        // },
        // {
        //   title: "Plugin Version",
        //   data: analysis?.hashlookup?.plugin_version,
        // },
        {
          title: "Skipped",
          data: analysis?.hashlookup?.skipped,
        },
        {
          title: "Summary",
          data:
            analysis?.hashlookup?.summary?.length > 0
              ? JSON.stringify(analysis?.hashlookup?.summary)
              : "No Summary",
        },
      ]}
    />
  );
};

export const Leaks = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="Github Secrets"
      dataItems={[
        // {
        //   title: "Time of Analysis",
        //   data: (
        //     <TimeFormater date={analysis?.information_leaks?.analysis_date} />
        //   ),
        // },
        // {
        //   title: "Plugin Version",
        //   data: analysis?.information_leaks?.plugin_version,
        // },
        {
          title: "Skipped",
          data: analysis?.information_leaks?.skipped,
        },
        {
          title: "Summary",
          data:
            analysis?.information_leaks?.summary?.length > 0
              ? JSON.stringify(analysis?.information_leaks?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};

export const Init = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="Init systems"
      dataItems={[
        {
          title: "Summary",
          data:
            analysis?.init_systems?.summary?.length > 0
              ? JSON.stringify(analysis?.init_systems?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};
