import React from "react";
import { Text, Box, Heading, Flex } from "@chakra-ui/react";
import { holdingBox } from "../../styles/styles";

export const Structure = () => {
  const common = [
    {
      text1: "_id",
      text2: "UID of file",
    },
    {
      text1: "analysis_tags",
      text2: 'Dynamically generated tags such as "Linux 2.7',
    },
    {
      text1: "depth",
      text2:
        "Level of extraction: Outer container has 0, SquashFS inside container has 1",
    },
    {
      text1: "file_name",
      text2:
        " Name of file, can for example be busybox if extracted by a high level tool, can also be smth. like 49913.bin if carved",
    },
    {
      text1: "file_path ",
      text2: "Path on backend file system",
    },
    {
      text1: "files_included",
      text2: "UIDs of included files (next level of extraction downwards)",
    },
    {
      text1: "parent_firmware_uids",
      text2: "UIDs for outer firmware container files containing this file",
    },
    {
      text1: "parents",
      text2:
        "UIDs of files containing this file (next level of extraction upwards)",
    },
    {
      text1: "processed_analysis",
      text2:
        "Dictionary containing all analysis results. See below for structure",
    },
    {
      text1: "sha256",
      text2: "SHA-2 hash of file",
    },
    {
      text1: "size",
      text2: "file size in bytes",
    },
    {
      text1: "virtual_file_path     ",
      text2:
        "Full path of file in outer container with human readable format (e.g. |<Vendor> 32-Route-2000 v.1.1|rootfs.bin|/bin/bash)",
    },
  ];

  const additional = [
    {
      text1: "device_class",
      text2: "e.g. Router",
    },
    {
      text1: "device_name",
      text2: "e.g. Speedport W724",
    },
    {
      text1: "device_part",
      text2: "e.g. Kernel",
    },

    {
      text1: "md5 ",
      text2: "MD5 hash of file",
    },
    {
      text1: "release_date",
      text2: "Release date in seconds since epoch (1970-01-01)",
    },
    {
      text1: "submission_date",
      text2: "Submission date in seconds since epoch (1970-01-01)",
    },
    {
      text1: "tags",
      text2: "User defined tags, e.g. Partial, Crawled",
    },
    {
      text1: "vendor",
      text2: "e.g. EvilCorp",
    },
    {
      text1: "version",
      text2: "e.g 1.0.0.312",
    },
  ];

  return (
    <Box sx={holdingBox}>
      <Heading
        as="h4"
        fontSize="23px"
        fontWeight="semibold"
        textAlign="center"
        my={"16px"}
      >
        Structure of file objects in database
      </Heading>

      <Box fontSize={"13px"}>
        <Text my="8px">Common fields</Text>

        <>
          {common.map((item, i) => {
            return (
              <Flex
                border={"1px"}
                borderColor="gray.300"
                key={i}
                alignItems="center"
              >
                <Text width={"30%"} pl="5px" py="3px">
                  {item.text1}
                </Text>
                <Text
                  width={"70%"}
                  borderLeft="1px"
                  py="3px"
                  pl="5px"
                  borderColor="gray.300"
                >
                  {item.text2}
                </Text>
              </Flex>
            );
          })}
        </>
      </Box>

      <Box fontSize={"13px"}>
        <Text my="8px">
          Additional fields for outer container: (Mostly set by user, so depend
          on submission policy)
        </Text>

        <>
          {additional.map((item, i) => {
            return (
              <Flex
                border={"1px"}
                borderColor="gray.300"
                key={i}
                alignItems="center"
              >
                <Text width={"30%"} pl="5px" py="3px">
                  {item.text1}
                </Text>
                <Text
                  width={"70%"}
                  borderLeft="1px"
                  py="3px"
                  pl="5px"
                  borderColor="gray.300"
                >
                  {item.text2}
                </Text>
              </Flex>
            );
          })}
        </>
      </Box>
    </Box>
  );
};
