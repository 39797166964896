import React from "react";
import { Flex, Heading, useColorModeValue } from "@chakra-ui/react";
import GoBack from "./GoBack";

const CustomHeader = ({ title }: { title: string }) => {
  // const textColorMode = useColorModeValue(
  //   "lightMode.mainTextColor",
  //   "darkMode.mainTextColor"
  // );

  const textColorMode = "darkMode.mainTextColor"
  return (
    <Flex height={"72px"} cursor={"pointer"} alignItems="center">
      <GoBack />
      <Heading
        fontSize={{ base: "25px", md: "36px" }}
        my="1em"
        color={textColorMode}
      >
        {title}
      </Heading>
    </Flex>
  );
};

export default CustomHeader;
