import axiosInstance from "../axiosInstance";
import { GET_MODELS, DELETE_FIRMWARE } from "../apiUrl";

export const getFirmwareScanList = async () => {
  const res = await axiosInstance.get(GET_MODELS);
  return res.data;
};

export const deleteFirmware = async (body) => {
  const res = await axiosInstance.delete(`${DELETE_FIRMWARE}/${body.scan_uid}`);
  return res.data;
};
