import { Box, Center, Grid } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import BottomData from "../../components/data/Virus/BottomData/BottomData";
import TopData from "../../components/data/Virus/TopData/TopData";
import LoadingComponent from "../../components/Loaders/LoadingComponent";
import { useGetPatches } from "../../services/query/patchManagement";


const PatchManagement = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [patches, setPatches] = useState([])

  useEffect(() => {
    try {
        const { data } = useGetPatches();
        setPatches(data)
        setIsLoading(false)
    } catch (e) {
        console.log(e)
        setIsLoading(false)
    }
    
  }, [patches]);

  return (
    <Box>
      <CustomHeader title="Patch Management" />

      {isLoading && <LoadingComponent />}

      {patches && (
        <>
            <pre>{JSON.stringify(patches, null, 2)}</pre>
            {/* <TopData data={data?.data} name={threatData?.device_name} />
            <BottomData
                hybridData={scanners}
                data={data?.data?.scans}
                factData={factData}
                threatData={threatData}
                codeAnalysisData={codeAnalysisData}
                codeAnalysisHtml={codeAnalysisHtml}
                sha={para}
            /> */}
        </>
      )}
    </Box>
  );
};

export default PatchManagement;
