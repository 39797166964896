import React from "react";
import { TimeFormater } from "../../../common/TimeFormater";
import Accordions from "../Accordion";
import { TableLinkDataItem, linkItems } from "../../../../types";
import { Td, Th, Tr } from "@chakra-ui/react";
export const Analysis = ({ analysis, hide }) => {

  return (
    <span style={{display: hide ? "none":"flex"}}>
        <Accordions
      name="CPU Architecture"
      dataItems={[
        {
          title: "Summary",
          data:
            analysis?.cpu_architecture?.summary?.length > 0
              ? JSON.stringify(analysis?.cpu_architecture?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};

export const CVE = ({ analysis, hide }) => {
  return (

    <span style={{display: hide ? "none":"flex"}}>
        <Accordions
          name="CVE lookup"
          dataItems={[
            {
              title: "Info",
              data: analysis?.cve_lookup?.info || "No Result",
            },
          ]}
        />
    </span>
    
  );
};

export const Crypto = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="Crypto Hints"
      dataItems={[
        {
          title: "Summary",
          data:
            analysis?.crypto_hints?.summary?.length > 0
              ? JSON.stringify(analysis?.crypto_hints?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};

export const Materials = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="Crypto Material"
      dataItems={[
        {
          title: "Summary",
          data:
            analysis?.crypto_material?.summary?.length > 0
              ? JSON.stringify(analysis?.crypto_material?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};

export const CWE = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="CWE checker"
      dataItems={[
        {
          title: "Summary",
          data:
            analysis?.cwe_checker?.summary?.length > 0
              ? JSON.stringify(analysis?.cwe_checker?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};

export const CodeCave = ({link, index}:TableLinkDataItem)=>{
  console.log(link)
  return (
      <Tr>
        <Td>{index+1}</Td>
        <Td>{link.name}</Td>
        <Td>{link.size}</Td>
        <Td>{link.raw_address_bytes}</Td>
        <Td>{link.virtual_address_bytes}</Td>
      </Tr>
  );
};
