import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Logo, Wave } from "../../assets";
import Zendesk from "../common/Zendesk";

interface IChildren {
  children: React.ReactNode;
}
export const AuthPageLayout = ({ children }: IChildren) => {
  return (
    <Box
      display={"flex"}
      position={"relative"}
      flexDirection="column"
      w="100%"
      minHeight="100vh"
      backgroundColor="darkMode.secBgColor"
      color="white"
    >
      <Header />
      <Box px={[6, 6, 12, 12]} color="white">{children}</Box>
      <Zendesk />
      <Box mt={"auto"}>
        <Footer />
      </Box>
    </Box>
  );
};

export const NonAuthPageLayout = ({ children }: IChildren) => {
  return (
    <Box display={"flex"} bgColor="darkMode.secBgColor" flexDirection="column" w="100%" minH="100vh">
      <Flex justify={"flex-start"} bgColor="darkMode.navBgColor" h={55} py={2}>
        <Image src={Logo} width="60px" objectFit="contain" alt="logo" />
      </Flex>
      {children}
      <Box pos="fixed" bottom="0" zIndex="2">
        <Image src={Wave} alt="wave" />
      </Box>
    </Box>
  );
};
