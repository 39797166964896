import React, { useState } from "react";
import { Box, Heading, Flex } from "@chakra-ui/layout";
import { Image, useColorModeValue } from "@chakra-ui/react";
import { Logo } from "../../assets";
import useCustomToast from "../../utils/notification";
import { useNavigate } from "react-router-dom";
import { useCreateNewUser } from "../../services/query/register";
import { Formik, Form } from "formik";
import { formatError } from "../../utils/helpers";
import { AuthInput } from "../../components/CustomInput/AuthInput";
import { AccountExist, Terms } from "../../components/data/Auth/Register";
import {
  initialValue,
  validationSchema,
} from "../../components/data/Auth/Validation";

const SignUp = () => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const { errorToast, successToast } = useCustomToast();
  const navigate = useNavigate();

  const { mutate, isLoading: isCreateLoading } = useCreateNewUser({
    onSuccess: () => {
      successToast("Sign up successful");
      navigate("/login");
    },
    onError: (err: any) => {
      formatError(err.response.data.error).forEach((data: any) => {
        errorToast(
          `${Object.keys(data)[0]}: ${data[Object.keys(data)[0]][0]}` ||
            err.message
        );
      });
    },
  });

  const handleSubmit = (values: Record<string, any>) => {
    mutate(values);
  };

  const textColorMode = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.mainTextColor"
  );

  const bgColorMode = useColorModeValue(
    "lightMode.mainBgColor",
    "darkMode.mainBgColor"
  );

  return (
    <Flex
      p="2em"
      w="100vw"
      rowGap={2}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      display={{ base: "block", md: "flex" }}
    >
      <Box
        p={4}
        w={{ md: "40em" }}
        borderRadius={10}
        color={textColorMode}
        bgColor={bgColorMode}
      >
        <Box mb={4}>
          <Image height={130} width={143} mx="auto" src={Logo} />
          <Heading fontSize={20} fontWeight={500}>
            Signup
          </Heading>
        </Box>

        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValue}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Flex columnGap={2} display={{ base: "block", lg: "flex" }}>
                <AuthInput
                  label="First name"
                  value={values?.first_name}
                  onChange={handleChange}
                  name="first_name"
                  onBlur={handleBlur}
                  error={
                    errors?.first_name &&
                    touched?.first_name &&
                    errors?.first_name
                  }
                />
                <AuthInput
                  label="Last name"
                  value={values?.last_name}
                  onChange={handleChange}
                  name="last_name"
                  onBlur={handleBlur}
                  error={
                    errors?.last_name && touched?.last_name && errors?.last_name
                  }
                />
              </Flex>
              <Flex columnGap={2} display={{ base: "block", lg: "flex" }}>
                <AuthInput
                  label="Username"
                  value={values?.username}
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors?.username && touched?.username && errors?.username
                  }
                />
                <AuthInput
                  label="Email"
                  value={values?.email}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors?.email && touched?.email && errors?.email}
                />
              </Flex>
              <Flex columnGap={2} display={{ base: "block", lg: "flex" }}>
                <AuthInput
                  label="Password"
                  value={values?.password}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors?.password && touched?.password && errors?.password
                  }
                />
                <AuthInput
                  label="Country"
                  value={values?.country}
                  name="country"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors?.country && touched?.country && errors?.country}
                />
              </Flex>
              <Flex columnGap={2} display={{ base: "block", lg: "flex" }}>
                <AuthInput
                  label="Phone"
                  value={values?.phone_number}
                  onChange={handleChange}
                  name="phone_number"
                  onBlur={handleBlur}
                  error={
                    errors?.phone_number &&
                    touched?.phone_number &&
                    errors?.phone_number
                  }
                />
                <AuthInput
                  label="Company"
                  value={values?.organization}
                  name="organization"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors?.organization &&
                    touched?.organization &&
                    errors?.organization
                  }
                />
              </Flex>
              <Terms
                isChecked={isTermsAccepted}
                onChange={() => setIsTermsAccepted((prev) => !prev)}
              />
              <AccountExist
                isLoading={isCreateLoading}
                disabled={!isValid || !dirty || !isTermsAccepted}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default SignUp;
