import React from "react";
import { ListItem, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AccordionLinkDataItem } from "../../../../types";

const AccordionLinkDataItemComponent = ({ link }: AccordionLinkDataItem) => {
  return (
    <ListItem p="1em" 
      background="darkMode.grayBackground" 
      _hover={{ background: "darkMode.grayBackground"  }}>
      <Text>{link}</Text>
    </ListItem>
  );
};

export default AccordionLinkDataItemComponent;
