import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useRecentFirmwareList } from "../../../../services/query/dashboard";
import { scrollBarStyle } from "../../../../utils/helpers";
import PageLoader from "../../../Loaders/PageLoader";

const FirmwareUpload = () => {
  const { data, isLoading } = useRecentFirmwareList();
  // const bgColor = useColorModeValue("lightMode.secBgColor", "darkMode.secBgColor")
  const bgColor = "darkMode.mainBgColor"
  // const cardColor = useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor")
  const cardColor = "darkMode.cardBgColor"

  return (
    <Box mb="16px">
      <Box
        bg={bgColor}
        p="10px 0px 16px 17px"
        borderTopRadius="10px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        Firmware Uploads
      </Box>
      <Box
        marginTop="-8px"
        bg={cardColor}
        p="14px 40px 28px 17px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        h={{ base: "155px" }}
        borderRadius="10px"
        overflowY="scroll"
        sx={scrollBarStyle}
      >
        {data?.results.length === 0 && <Text>No Firmware uploaded yet</Text>}
        {isLoading && <PageLoader lineNo={2} />}
        {data?.results.map((item, i) => (
          <Link onClick={()=>{
            localStorage.setItem("scan_uid", item?.scan_uid);
            }} 
            to={`/analysis/${item?.scan_uid}`} key={i + 1}>
            <Flex
              justifyContent="space-between"
              mt="8px"
              pl="5px"
              borderRadius={"5px"}
              _hover={{ background: "gray.600" }}
            >
              <Box>
                <Box>{item?.device_name}</Box>
                <Box color="#909090">{item?.created_at.slice(0, 10)}</Box>
              </Box>
            </Flex>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default FirmwareUpload;
