import React from "react";
import { Box, Text } from "@chakra-ui/react";

const ListEmptyComponent = ({ message }: { message: string }) => {
  return (
    <Box p="2em" mt="1em" mb="1em" borderWidth={2} borderRadius="0.75em">
      <Text fontWeight="bold" fontSize="2xl">
        {message}
      </Text>
    </Box>
  );
};

export default ListEmptyComponent;
