import { Box, Button, Flex } from "@chakra-ui/react";
import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import ErrorComponent from "../../components/data/ErrorComponent";
import BottomData from "../../components/data/Hybrid/BottomData/BottomData";
// import Domain from "../../components/data/Hybrid/Domain/Domain";
import TopData from "../../components/data/Hybrid/TopData/TopData";
import LoadingComponent from "../../components/Loaders/LoadingComponent";
import { useGetAnalysis } from "../../services/query/analysis";
import { useRecentFirmwareList } from "../../services/query/dashboard";
import { useDeleteFirmware } from "../../services/query/firmware";
import useCustomToast from "../../utils/notification";
// import { RadareLink, RawDownload } from "../../components/data/Hybrid/TopData/Buttons";
// import { useGetRadareUrl } from "../../services/query/radare";
// import { useGetAnalysisCount } from "../../services/query/analysis";
import { downloadFile } from "../../services/api/fileDownload";

const ScanOverview = () => {
  const { scan_uid } = useParams();
  const { errorToast, successToast } = useCustomToast();
  const navigate = useNavigate();
  const { refetch: refetchRecentFirmwareList } = useRecentFirmwareList();
  const { mutate } = useDeleteFirmware({
    onSuccess: () => {
      successToast("Firmware deleted successfully");
      refetchRecentFirmwareList();
      setTimeout(() => {
        console.log("Navigating to dashboard")
        navigate("/dashboard");
      }, 2000);
    },
    onError: (err) => {
      errorToast(err.response.data.message);
    },
  });

  const { data, isLoading, error, refetch } = useGetAnalysis(
    scan_uid as string
  );

  const deleteFirmware = () => {
    mutate({
      scan_uid: scan_uid,
    });
  };

  // const { data: radareData, isLoading: isRadareLoading } =
  //   useGetRadareUrl(scan_uid);
  // const { data: analysisCount } = useGetAnalysisCount(scan_uid as string);
  const metaData = data?.message?.firmware?.meta_data;
  const bgColor = "darkMode.grayBackground"

  return (
    <Box>
      <Flex justifyContent={"space-between"} alignItems="center">
        <CustomHeader title="Security Analysis" />

        <Box>
          {/* <RawDownload scan={scan_uid} meta={metaData?.device_name} />

          <RadareLink
            isLoading={isRadareLoading}
            radare={radareData?.message?.radare_url}
            scan={scan_uid}
          /> */}
          <Button
            bgColor={bgColor}
            mr="10px"
            disabled={true}
            cursor={"pointer"}
            onClick={() => {
              // Apply patch
            }}
          >
            Apply Patch
          </Button>
          <Button
            bgColor={bgColor}
            mr="10px"
            cursor={"pointer"}
            onClick={() => {
              downloadFile(scan_uid, metaData?.device_name)
            }}
          >
            Raw File
          </Button>
          <Button
            variant="danger"
            cursor={"pointer"}
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this Firmware?")
              ) {
                deleteFirmware();
              }
            }}
            rightIcon={<AiFillDelete />}
          >
            Delete
          </Button>
        </Box>
      </Flex>

      {isLoading && <LoadingComponent />}

      {error && (
        <ErrorComponent
          message="This firmware cannot be processed."
          header={scan_uid && scan_uid}
          reload={refetch}
        />
      )}

      {data && (
        <>
          <TopData data={data} />
          <BottomData data={data} />
          {/* <Domain /> */}
        </>
      )}
    </Box>
  );
};

export default ScanOverview;
