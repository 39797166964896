import { Box, Flex, useColorMode } from "@chakra-ui/react";
import React from "react";
import CustomHeader from "../../components/common/CustomHeader";
import { useParams } from "react-router-dom";
import { useGetHybridReportSummary } from "../../services/query/hybrid";
import LoadingComponent from "../../components/Loaders/LoadingComponent";
import ErrorComponent from "../../components/data/ErrorComponent";

const MalwareLayout = ({ first, value, color }) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      minH="40px"
      bg={
        colorMode === "light"
          ? color === "light"
            ? "transparent"
            : "#D9E3EB"
          : color === "black"
          ? "#1C2A35"
          : "transparent"
      }
    >
      <Flex pl="10px" w="30%" alignItems="center">
        {first}
      </Flex>
      <Flex
        pl="10px"
        w="70%"
        alignItems="center"
        borderLeft="1px solid #D9E3EB"
      >
        {value}
      </Flex>
    </Flex>
  );
};

const ScanSummary = () => {
  const { id } = useParams();

  const { data, isLoading, error, refetch } = useGetHybridReportSummary(
    id as string
  );

  return (
    <Box>
      <CustomHeader title="Scan Summary" />

      {isLoading && <LoadingComponent />}

      {error && (
        <ErrorComponent
          message="This firmware cannot be processed."
          header={id && id}
          reload={refetch}
        />
      )}
      {data && (
        <Box border="1px solid #D9E3EB" mt="20px">
          <MalwareLayout
            color="light"
            first={"sha256"}
            value={data?.data?.sha256}
          />
          <MalwareLayout
            color="black"
            first={"Threat_Score"}
            value={data?.data?.threat_score}
          />
          <MalwareLayout
            color="light"
            first={"Verdict"}
            value={data?.data?.verdict}
          />
          <MalwareLayout
            color="black"
            first={"Analysis_start_time"}
            value={data?.data?.analysis_start_time}
          />
          <MalwareLayout
            color="light"
            first={"last_multi_scan"}
            value="2021-10-03T22:34:35+00:00"
          />
          <MalwareLayout
            color="black"
            first={"multiscan_result"}
            value={data?.data?.multiscan_result}
          />
        </Box>
      )}
    </Box>
  );
};

export default ScanSummary;
