import React from "react";
import { Box, Flex, Grid, GridItem, Text, Center } from "@chakra-ui/react";

export const Layout = ({ data, label }) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" mb="16px">
      <Box fontSize="14px" fontWeight="bold">
        {label}
      </Box>
      <Box
        width="70%"
        fontSize="12px"
        whiteSpace={{ base: "normal", lg: "nowrap" }}
      >
        {data}
      </Box>
    </Flex>
  );
};

const ThreatMiniInfo = ({ data }) => {
  const severityToColor = (severity: number) => {
    if (severity < 4) {
      return "#edff6155"
    } else {
      if (severity < 7) {
        return "#ffc56155"
      } else {
        return "#ff796155"
      }
    }
  }
  return (
    <Box>
      <Box>
        {data?.file_analysis?.length <= 0 && (
          <Text>No threats details at the moment</Text>
        )}
        <Box fontSize="28px" fontWeight="bold">
          {data?.device_name || "No Result"}
        </Box>
        <Box color="#909090" fontSize="12px">
          FLEHASH- {data?.file_analysis?.analysis?.hash || "No Result"}
        </Box>
        <Box mb="12px" fontWeight="bold">
          {data?.firmware || "No Result"}
        </Box>
      </Box>
      <Flex alignItems="start" flexDirection={{ 
          base: "column", 
          // "2xl": "row" 
        }}>
        <Box width="100%" mr="56px">
          <Box textAlign="left">
            <Layout
              label="File Type"
              data={
                data?.file_analysis?.analysis?.info?.results?.file_type ||
                "No Result"
              }
            />
            <Layout
              label="Size"
              data={
                data?.file_analysis?.analysis?.info?.results?.filesize ||
                "No Result"
              }
            />
            <Layout
              label="MD5"
              data={
                data?.file_analysis?.analysis?.info?.results.md5 || "No Result"
              }
            />
            <Layout
              label="SHA1"
              data={
                data?.file_analysis?.analysis?.info?.results?.sha1 ||
                "No Result"
              }
            />
            <Layout
              label="SHA256"
              data={
                data?.file_analysis?.analysis?.info?.results?.sha256 ||
                "No Result"
              }
            />
            <Layout
              label="Alert"
              data={
                data?.file_analysis?.analysis?.plugins?.cuckoo?.result
                  ?.signatures?.length || 0
              }
            />
            <Layout
              label="Compilation Date"
              data={data?.file_analysis?.analysis?.datetime_int?.slice(0, 10)}
            />
          </Box>
        </Box>
        <br></br>
        <Text style={{
          fontWeight: 900,
          fontSize: 22,
          marginBottom: 20,
        }}>Signatures</Text>
        <Grid
          gridTemplateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }}
          gap="16px"
          alignItems="start"
          fontSize="14px"
          width="100%"
        >
          {data?.file_analysis?.analysis?.plugins?.cuckoo?.result?.signatures?.map(
            (alert, i) => (
              <GridItem
                key={i + 1}
                bgColor="darkMode.grayBackground"
                borderRadius="10px"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                h="100%"
                p="20px"
                textAlign="center"
              >
                <Center mb="10px" fontSize={"16"}>{alert.name}</Center>
                <Center>{alert.description}</Center>
                <Center m="0px auto" mt="10px" width="30px" p="3px 10px" borderRadius="10px"
                  backgroundColor={severityToColor(alert.severity)}
                >{alert.severity}</Center>
              </GridItem>
            )
          )}
        </Grid>
      </Flex>
    </Box>
  );
};

export default ThreatMiniInfo;
