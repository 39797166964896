import {
  HYBRID_UPLOAD,
  HYBRID_LIST,
  SCAN_OVERVIEW,
  SCAN_SUMMARY,
  HYBRID_OVERVIEW,
} from "../apiUrl";
import axiosInstance from "../axiosInstance";

export const getHybridReport = async ({ queryKey }) => {
  const [, sha] = queryKey;

  const body = {
    file_hash: sha,
  };

  const res = await axiosInstance.post(SCAN_OVERVIEW, body);
  return res.data;
};

export const getHybridOverview = async ({ queryKey }) => {
  const [, scan_uid] = queryKey;
  console.log("Doing hybrid overview request")
  const res = await axiosInstance.get(`${HYBRID_OVERVIEW}?scan_uid=${scan_uid}`);
  console.log(res)
  return res.data;
};

export const getHybridReportSummary = async ({ queryKey }) => {
  const [, sha] = queryKey;

  const body = {
    file_hash: sha,
  };

  const res = await axiosInstance.post(SCAN_SUMMARY, body);
  return res.data;
};

export const postHybrid = async (body) => {
  const res = await axiosInstance.post(HYBRID_UPLOAD, body);
  return res.data;
};

export const getHybridList = async () => {
  const res = await axiosInstance.get(HYBRID_LIST);
  return res.data;
};
