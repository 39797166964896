import React from "react";
import { FormControl, Select, useColorModeValue } from "@chakra-ui/react";

const BasicSearchInput = ({ children, value, placeholder, onChange }) => {
  const inputStyle = {
    px: "3",
    border: "1px",
    // bgColor: useColorModeValue("lightMode.BgColor", "darkMode.BgColor"),
    bgColor: "darkMode.secBgColor",
    // borderColor: useColorModeValue(
    //   "lightMode.BgColor",
    //   "darkMode.mainTextColor"
    // ),
    borderColor: "white",
  };
  const bgColor = "darkMode.secBgColor"

  return (
    <FormControl
      mb={[10, 0, 0, 0]}
      variant="floating"
      id="Device Name"
      isRequired
      w={["100%", "45%", "45%", "45%"]}
      position="relative"
      bgColor={bgColor}
      style={{backgroundColor: "#323541"}}
      _hover={{backgroundColor: "#323541"}}
    >
      <Select
        value={value}
        sx={inputStyle}
        style={{backgroundColor: "#323541"}}
        onChange={onChange}
        placeholder={placeholder}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default BasicSearchInput;
