import { Box, Center, Grid, useColorModeValue, Tooltip } from "@chakra-ui/react";
// import Accordions from "../Accordion";
import Comment from "../Comment";
import { Analysis, Crypto, Materials } from "./InitCard";
import { Hashes, Tree } from "./SecCard";
import { Init, Type } from "./ThirdCards";
import { Config, Software } from "./EndCard";
import { useState } from "react";

const AnalysisBottomComponent = ({ data }) => {
  const secBgColorMode = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );

  const analysis = data?.message?.firmware?.analysis;
  const metaData = data?.message?.firmware?.meta_data;
  const [options, setOptions] =useState( 
    [
      {
        name:"File tree", 
        hide: false, 
        color:"",
        hoverText: "Get the device tree in text from the device tree blob",
      },
      {
        name:"CPU Architecture", 
        hide: true, 
        color:"",
        hoverText: "Identify CPU architecture",
      },
      {
        name:"Crypto Hints", 
        hide: true, 
        color:"",
        hoverText: "Find indicators of specific crypto algorithms",
      },
      {
        name:"Crypto Material", 
        hide:true , 
        color:"",
        hoverText: "Detects crypto material like SSH keys and SSL certificates",
      },
      {
        name:"Device tree", 
        hide: true, 
        color:"",
        hoverText: "",
      },
      {
        name:"File type", 
        hide: true, 
        color:"",
        hoverText: "Identify the file type",
      },
      {
        name:"File hashes", 
        hide: true, 
        color:"",
        hoverText: "Calculate different hash values of the file",
      },
      {
        name:"Init systems", 
        hide: true, 
        color:"",
        hoverText: "Detect and analyze auto start services",
      },
      {
        name:"Kernel config", 
        hide: true, 
        color:"",
        hoverText: "Heuristics to find and analyze Linux Kernel configurations via checksec and config-hardened-check",
      },
      {
        name:"Software components", 
        hide: true, 
        color:"",
        hoverText: "Identify software components",
      },
    ]
  )

  const displayScreen = (key) => {
    const _options = options.map((item, _key) =>{
        if (key === _key) {
          item.hide = item.hide  ? false: true
          item.color = "darkMode.grayBackground"
        }else{
          item.hide = true
          item.color = "none"
        }
        
        return  item
    })
    setOptions(_options)
  }

  return (

    <Box p="2em" mt="8" mb="18" borderRadius="0.75em" bgColor={secBgColorMode}>
      <Grid
        flexDirection={'row'}
        templateColumns='repeat(5, 1fr)'
        gap='2'
        >
        {options.map((title , key)=>{
          return (<Box 
            // w='12vw' 
            h='6vh' 
            borderWidth='1px' 
            marginBottom={'1rem'}
            background={title.color}
            cursor={'pointer'}
            textAlign="center"
            onClick={displayScreen.bind(this, key)}

            className="title">
              <Tooltip label={title.hoverText}>
                <Center h='6vh'>{title.name}</Center>
              </Tooltip>
          </Box>)
        })}
      </Grid>
      <span style={{display:options[0].hide ? "none":"flex", backgroundColor:"darkMode.grayBackground"}}>
        {/* <Accordions
        name="File tree"
        linkDataItems={metaData?.included_files?.map(
          (item: any, index: number) => ({
            index,
            link: item,
          }))
        }
        /> */}
        <Grid
          flexDirection={'row'}
          templateColumns='repeat(1, 1fr)'
          w='100%'
          maxW='100%'
          bg="darkMode.grayBackground"
          gap='2'
          >
          {metaData?.included_files?.map((item: any, index: number) =>{
            console.log(metaData)
            console.log(analysis)
            return (
                <Center h='6vh'>{item}</Center>
              )
          })}
        </Grid>
      </span>
      

      <Analysis analysis={analysis} hide={options[1].hide}/>
      <Crypto analysis={analysis} hide={options[2].hide} />
      <Materials analysis={analysis} hide={options[3].hide} />
      <Tree analysis={analysis}  hide={options[4].hide} />
      <Hashes analysis={analysis}  hide={options[6].hide} />
      <Type analysis={analysis} hide={options[5].hide} />
      <Init analysis={analysis} hide={options[7].hide} />
      <Config analysis={analysis} hide={options[8].hide} />
      <Software analysis={analysis}  hide={options[9].hide} />

      <Comment />
    </Box>
  );
};

export default AnalysisBottomComponent;
