import React from "react";
import { Box, Flex, Heading, useColorModeValue } from "@chakra-ui/react";
import GoBack from "../../components/common/GoBack";
import { useThreatDetails } from "../../services/query/threat";
import { useParams } from "react-router-dom";
import ThreatMiniInfo from "../../components/data/Threat/ThreatMiniInfo";
import LoadingComponent from "../../components/Loaders/LoadingComponent";

const Threat = () => {
  const { uid } = useParams();
  const { data, isLoading } = useThreatDetails(uid);

  return (
    <Box
      color={useColorModeValue(
        "lightMode.mainTextColor",
        "darkMode.mainTextColor"
      )}
    >
      <Box
        px={{ base: "16px", xl: 41 }}
        pt={{ base: "20px", "2xl": "40px" }}
        paddingBottom={{ lg: "70px", "2xl": "100px" }}
      >
        <Flex
          mb={{ base: "15px", "2xl": "105px" }}
          justify={"space-between"}
          align="center"
        >
          <Heading
            as="h4"
            fontSize="32"
            fontWeight="semibold"
            display="flex"
            alignItems="center"
          >
            <GoBack />
            Threat Intelligence
          </Heading>
        </Flex>
        {isLoading && <LoadingComponent />}
        {data && (
          <>
            <ThreatMiniInfo data={data} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default Threat;
