import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import {
  getModels,
  getAnalysis,
  getComments,
  updateAnalysis,
  getAnalysisCount,
  postComment,
  editComment,
  deleteComment,
  getMissingAnalysis,
  getCodeAnalysis
} from "../api/analysis";
import {
  GET_MODELS,
  GET_ANALYSIS_DATA,
  GET_ANALYSIS_COUNT,
  GET_COMMENTS,
  POST_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  UPDATE_ANALYSIS,
  GET_MISSING_ANALYSIS,
  CODE_ANALYSIS
} from "../queryKeys";

export const useGetModels = (url?, options = {}) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [GET_MODELS, url],
    getModels,
    { ...options }
  );

  return { data, isLoading, error, refetch };
};

export const useGetAnalysis = (id: string, options = {}) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [GET_ANALYSIS_DATA, id],
    getAnalysis,
    { ...options }
  );
  return { data, isLoading, error, refetch };
};

export const useGetAnalysisCount = (id: string, options = {}) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [GET_ANALYSIS_COUNT, id],
    getAnalysisCount,
    { ...options }
  );
  return { data, isLoading, error, refetch };
};

export const useUpdateAnalysis = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateAnalysis, {
    mutationKey: UPDATE_ANALYSIS,
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreateComment = (options = {}) => {
  const { mutate, isLoading } = useMutation(postComment, {
    mutationKey: POST_COMMENT,
    ...options,
  });

  return { mutate, isLoading };
};
export const useGetComments = (options = {}) => {
  const { data, isLoading, error, refetch } = useQuery(
    [GET_COMMENTS],
    getComments,
    {
      ...options,
    }
  );

  return { data, isLoading, error, refetch };
};

export const useEditComment = (options = {}) => {
  const { mutate, isLoading } = useMutation(editComment, {
    mutationKey: EDIT_COMMENT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useDeleteComment = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteComment, {
    mutationKey: DELETE_COMMENT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetMissingAnalysis = (options = {}) => {
  const { data, isLoading } = useQuery(
    [GET_MISSING_ANALYSIS],
    getMissingAnalysis,
    {
      ...options,
    }
  );
  return { data, isLoading };
};

export const useCodeAnalysisDetails = (scan_uid, options = {}) => {
  const { data, isLoading, error } = useQuery(
    [CODE_ANALYSIS, scan_uid],
    getCodeAnalysis,
    {
      ...options,
    }
  );
  return { data, isLoading, error };
};
