import { Box } from "@chakra-ui/react";
import { useGetUserProfile } from "../../services/query/user";
import SettingsLayout from "../../components/Layouts/SettingsLayout";
import {
  Divide,
  Footer,
  Header,
} from "../../components/data/UserSettings/Layout";
import { Info } from "../../components/CustomInput/UserInput";

const UserProfile = () => {
  const { data: userInfo } = useGetUserProfile();

  return (
    <SettingsLayout>
      <Box>
        <Header title="Personal" label="General User Information" />

        <Divide />
        <Box
          px={["20px", "20px", "20px", "20"]}
          py={["20px", "20px", "20px", "10"]}
        >
          <Info testid="user" label="Username" data={userInfo?.username} />
          <Info testid="phone" label="Phone Number" data={userInfo?.phone_number} />
          <Info testid="email" label="Email" data={userInfo?.email} />
          <Info testid="country" label="Country" data={userInfo?.country} />

          <Footer label="Company" subLabel="General Company Information" />
        </Box>
        <Divide />

        <Box
          px={["20px", "20px", "10", "20"]}
          py={["20px", "20px", "10", "10"]}
        >
          <Info testid="company_name" label="Company Name" data={userInfo?.organization} />
          <Info testid="company_email" label="Company Email" data={userInfo?.email} />
          <Info testid="location" label="Location" data={userInfo?.country} />
        </Box>
      </Box>
    </SettingsLayout>
  );
};

export default UserProfile;
