export const GET_FIRMWARE_SCAN_LIST = "GET_FIRMWARE_SCAN_LIST";
export const GET_ALL_PEOPLE = "GET_ALL_PEOPLE";
export const GET_MODELS = "GET_MODELS";
export const GET_ANALYSIS_DATA = "GET_ANALYSIS_DATA";
export const GET_ANALYSIS_COUNT = "GET_ANALYSIS_COUNT";
export const createDataKey = "create-data-key";
export const INITIAL_BINARY_SEARCH = "INITIAL_BINARY_SEARCH";
export const INITIAL_BINARY = "INITIAL_BINARY";
export const GET_BINARY_LIST = "GET_BINARY_LIST";
export const GET_BINARY_LIST_DETAILS = "GET_BINARY_LIST_DETAILS";
export const BINARY_SEARCH = "BINARY_SEARCH";
export const BINARY_SEARCH_HISTORY = "BINARY_SEARCH_HISTORY";
export const GET_THREAT_INTELLIGENCE = "GET_THREAT_INTELLIGENCE";
export const GET_ERROR_LOGS = "api/logs/";
export const LOGIN_KEY = "login-key";
export const INVITE_LOGIN_KEY = "invite_login-key";
export const GET_ALL_USER = "GET_ALL_USER";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const UPLOAD_FIRM_WARE = "UPLOAD_FIRM_WARE";
export const GET_RECENT_FIRMWARES = "GET_RECENT_FIRMWARES";
export const GET_RECENT_MODELS = "GET_RECENT_MODELS";
export const GET_GENERAL_ITEMS = "GET_GENERAL_ITEMS";
export const GET_COMMENTS = "GET_COMMENTS";
export const POST_COMMENT = "POST_COMMENT";
export const GET_FIRMWARE_NEWS = "GET_FIRMWARE_NEWS";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const EDIT_USER_INFO = "EDIT_USER_INFO";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_MANAGE_ENGINE_CREDS = "CHANGE_MANAGE_ENGINE_CREDS";
export const DOWNLOAD_PDF = "DOWNLOAD_PDF";
export const UPDATE_ANALYSIS = "UPDATE_ANALYSIS";
export const GET_COMPARISON = "GET_COMPARISON";
export const GET_COMPARISONS = "GET_COMPARISONS";
export const CREATE_COMPARISON = "CREATE_COMPARISON";
export const DELETE_COMPARISON = "DELETE_COMPARISON";
export const GET_RADARE_URL = "GET_RADARE_URL";
export const BASIC_SEARCH = "BASIC_SEARCH";
export const ADVANCED_FIRMWARE_SEARCH = "ADVANCED_FIRMWARE_SEARCH";
export const GET_RECENT_COMPARISONS = "GET_RECENT_COMPARISONS";
export const SYSTEM_STATUS = "SYSTEM_STATUS";
export const DELETE_FIRMWARE = "DELETE_FIRMWARE";
export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";
export const DELETE_NOTIFICATIONS_ID = "DELETE_NOTIFICATIONS_ID";
export const GET_THREAT_COUNT = "GET_THREAT_COUNT";
export const GET_MISSING_ANALYSIS = "GET_MISSING_ANALYSIS";
export const GET_THREAT_DETAILS = "GET_THREAT_DETAILS";
// export const GET_Q_ANALYSIS_REPORT = "GET_Q_ANALYSIS_REPORT"
export const CODE_CAVE_OBJECTS = "CODE_CAVE_OBJECTS"
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";
export const MARK_ALL_NOTIFICATIONS_AS_READ = "MARK_ALL_NOTIFICATIONS_AS_READ";
export const GET_FIRMWARE_STATISTICS = "GET_FIRMWARE_STATISTICS";
export const GET_EMULATOR_SCANS = "GET_EMULATOR_SCANS";
export const GET_EMULATOR_SCANS_ID = "GET_EMULATOR_SCANS_ID";
export const GET_EMULATOR_ROOTS = "GET_EMULATOR_ROOTS";
export const CREATE_EMULATOR_ROOTS = "CREATE_EMULATOR_ROOTS";
export const GET_TEAMS = "GET_TEAMS";
export const CREATE_TEAM = "REATE_TEAM";
export const SEND_INVITE = "SEND_INVITE";
export const DELETE_TEAM = "DELETE_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const GET_INVITED_LIST = "GET_INVITED_LIST";
export const CANCEL_INVITATION = "CANCEL_INVITATION";
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER";
export const UPLOAD_TICKET = "UPLOAD_TICKET";
export const VIRUS_REPORT = "VIRUS_REPORT";
export const UPLOAD_VIRUS = "UPLOAD_VIRUS";
export const HYBRID_UPLOAD = "HYBRID_UPLOAD";
export const VIRUS_LIST = "VIRUS_LIST";
export const HYBRID_LIST = "HYBRID_LIST";
export const SCAN_OVERVIEW = "SCAN_OVERVIEW";
export const HYBRID_OVERVIEW = "HYBRID_OVERVIEW";
export const SCAN_SUMMARY = "SCAN_SUMMARY";
export const DOMAIN_CHECK = "DOMAIN_CHECK";
export const EXPLOIT_MITIGATION = "EXPLOIT_MITIGATION";
export const CODE_ANALYSIS = "CODE_ANALYSIS";

export const GET_PATCHES = "GET_PATCHES";
export const PATCH_MANAGER = "PATCH_MANAGER";
export const PATCH_UPDATE = "PATCH_UPDATE";
export const PATCH_APPROVE = "PATCH_APPROVE";
export const PATCH_DISAPPROVE = "PATCH_DISAPPROVE";
export const PATCH_RUN_FULL_ANALYSIS = "PATCH_RUN_FULL_ANALYSIS";
export const PATCH_SCAN_MALWARE = "PATCH_SCAN_MALWARE";
