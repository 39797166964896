import { Box, Grid, GridItem } from "@chakra-ui/react";
import StatusCards from "../StatusCards";

export const Layout = ({ children }) => {
  const bgColor = "darkMode.cardBgColor"
  
  return (
    <GridItem
      w="100%"
      border="1px"
      borderColor="gray.400"
      p={[3, 3, 5, 5]}
      borderRadius="0.25rem"
      bgColor={bgColor}
    >
      {children}
    </GridItem>
  );
};
const SystemsHealth = ({ data }: any) => {
  const bgColor = "darkMode.cardBgColor"

  return (
    <Box textAlign="center">
      <Grid
        py={[2, 3, 5, 5]}
        w={"100%"}
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          // "repeat(3, 1fr)",
        ]}
        gap={6}
      >
        <Layout>
          <StatusCards
            text1={data?.system_status?.backend?.name}
            text2={data?.system_status?.backend?.status}
            text3={data?.system_status?.backend?.platform?.os}
            text4={data?.system_status?.backend?.platform?.python}
            text5={data?.system_status?.backend?.platform?.fact_version}
            text6={data?.system_status?.backend?.system?.cpu_cores}
            text7={data?.system_status?.backend?.system?.virtual_cpu_cores}
            text8={data?.system_status?.backend?.system?.disk_used}
            text9={data?.system_status?.backend?.system?.disk_total}
            text10={data?.system_status?.backend?.system?.memory_used}
            text11={data?.system_status?.backend?.system?.memory_total}
          />
        </Layout>
        <Layout>
          <StatusCards
            text1={data?.system_status?.frontend?.name}
            text2={data?.system_status?.frontend?.status}
            text3={data?.system_status?.frontend?.platform?.os}
            text4={data?.system_status?.frontend?.platform?.python}
            text5={data?.system_status?.frontend?.platform?.fact_version}
            text6={data?.system_status?.frontend?.system?.cpu_cores}
            text7={data?.system_status?.frontend?.system?.virtual_cpu_cores}
            text8={data?.system_status?.frontend?.system?.disk_used}
            text9={data?.system_status?.frontend?.system?.disk_total}
            text10={data?.system_status?.frontend?.system?.memory_used}
            text11={data?.system_status?.frontend?.system?.memory_total}
          />
        </Layout>
        {/* <Layout>
          <StatusCards
            text1={data?.system_status?.database?.name}
            text2={data?.system_status?.database?.status}
            text3={data?.system_status?.database?.platform?.os}
            text4={data?.system_status?.database?.platform?.python}
            text5={data?.system_status?.database?.platform?.fact_version}
            text6={data?.system_status?.database?.system?.cpu_cores}
            text7={data?.system_status?.database?.system?.virtual_cpu_cores}
            text8={data?.system_status?.database?.system?.disk_used}
            text9={data?.system_status?.database?.system?.disk_total}
            text10={data?.system_status?.database?.system?.memory_used}
            text11={data?.system_status?.database?.system?.memory_total}
          />
        </Layout> */}
      </Grid>
    </Box>
  );
};

export default SystemsHealth;
