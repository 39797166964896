import React from "react";
import { TimeFormater } from "../../../common/TimeFormater";
import Accordions from "../Accordion";

export const Tree = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="Device tree"
      dataItems={[
 
        {
          title: "Skipped",
          data: analysis?.device_tree?.skipped || "Non Result",
        },
        {
          title: "Summary",
          data:
            analysis?.device_tree?.summary?.length > 0
              ? JSON.stringify(analysis?.device_tree?.summary)
              : "No Summary",
        },
      ]}
    />
    </span>
    
  );
};

export const Exploit = ({ analysis, exploit, hide}) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="Exploit mitigations"
      dataItems={[
        {
          title: "ASLR",
          data: exploit?.ASLR || "No Result",
        },
        {
          title: "CFG",
          data: exploit?.CFG || "No Result",
        },
        {
          title: "DEP",
          data: exploit?.DEP || "No Result",
        },
        {
          title: "SEH",
          data: exploit?.SEH || "No Result",
        },
      ]}
    />
    </span>
    
  );
};

export const Hashes = ({ analysis, hide }) => {
  return (
    <span style={{display: hide ? "none":"flex"}}>
      <Accordions
      name="File hashes"
      dataItems={[
        
        {
          title: "MD5",
          data: analysis?.file_hashes?.md5 || "Non Result",
        },
        {
          title: "imphash",
          data: analysis?.file_hashes?.imphash || "Non Result",
        },
        {
          title: "ripemd160",
          data: analysis?.file_hashes?.ripemd160 || "Non Result",
        },
        {
          title: "SHA-1",
          data: analysis?.file_hashes?.sha1 || "Non Result",
        },
        {
          title: "SHA-256",
          data: analysis?.file_hashes?.sha256 || "Non Result",
        },
        {
          title: "SHA-512",
          data: analysis?.file_hashes?.sha512 || "Non Result",
        },
        {
          title: "ssdeep",
          data: analysis?.file_hashes?.ssdeep || "Non Result",
        },
        {
          title: "tlsh",
          data: analysis?.file_hashes?.tlsh || "Non Result",
        },
        {
          title: "whirlpool",
          data: analysis?.file_hashes?.whirlpool || "Non Result",
        },
      ]}
    />
    </span>
    
  );
};
