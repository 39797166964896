import { useMutation } from "react-query";
import { createFirmware } from "../api/uploadFirmware";
import { UPLOAD_FIRM_WARE } from "../queryKeys";

export const useCreateNewFirmware = (options = {}) => {
  const { mutate, isLoading } = useMutation(createFirmware, {
    mutationKey: UPLOAD_FIRM_WARE,
    ...options,
  });

  return { mutate, isLoading };
};
