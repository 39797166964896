import React from "react"; // ,{ lazy }
// import WithSuspense from "../components/Loaders/WithSuspense";
import { PRIVATE_PATHS, PUBLIC_PATHS } from "./constants";
import { AppRoute } from "../types";
import { Navigate } from "react-router-dom";
import AllThreat from "../pages/AllThreat";
import Analysis from "../pages/Analysis/AnalysisPage";
import ComparedFrameworks from "../pages/Compare/ComparedList";
import InviteLogin from "../pages/Authentication/InviteLogin";
// import Teams from "../pages/Teams/index";
// import TeamInfo from "../pages/Teams/TeamInfo";
import AnalysisResult from "../pages/Analysis/AnalysisResult";
import AnalysisUpdate from "../pages/Analysis/AnalysisUpdate";
import Model from "../pages/Models";
import Dashboard from "../pages/Dashboard";
import BinarySearchList from "../pages/BinarySearch/SearchList";
import Login from "../pages/Authentication/Login";
import SignUp from "../pages/Authentication/SignUp";
import Search from "../pages/BinarySearch/Search";
import BinarySearchDetails from "../pages/BinarySearch/SearchDetails";
import UserProfile from "../pages/Settings/UserProfile";
import Settings from "../pages/Settings/Settings";
import FileUpload from "../pages/FileUpload/FileUpload";
// import HoneyPotPage from "../pages/HoneyPot/HoneyPotPage";
// import EmulatorPage from "../pages/EmulatorPage/EmulatorPage";
// import EmulatorScanDetails from "../pages/EmulatorPage/EmulatorScansDetails";
import Info from "../pages/Info";
import BasicSearch from "../pages/Search/BasicSearch";
import AdvancedSearch from "../pages/Search/AdvancedSearch";
import SystemsHealth from "../pages/SystemsHealth/SystemsHealth";
import ErrorLogs from "../pages/ErrorLogs";
import BrowseFirmware from "../pages/BrowseFirmware";
import ThreatIntelligence from "../pages/Threat";
import AnalysisPDF from "../pages/PDF/AnalysisPDF";
import ScanSummary from "../pages/Hybrid/ScanSummary";
import ScanOverview from "../pages/Hybrid/ScanOverview";
import MalwareReport from "../pages/Malware/MalwareReport";
import MalwareResult from "../pages/Malware/MalwareResult";
import Hybrid from "../pages/Hybrid/HybridList";
import Virus from "../pages/Malware/MalwareList";
import PatchManagement from "../pages/PatchManagement/PatchManagement";
import ManageEngine from "../pages/ManageEngine/ManageEngine"

const { LOGIN, SIGNUP, INVITE_LOGIN } = PUBLIC_PATHS;

const {
  USERPROFILE,
  SETTINGS,
  // TEAMS,
  // TEAM_INFO,
  FILEUPLOAD,
  // HONEYPOT,
  DASHBOARD,
  BINARY_SEARCH,
  BINARY_SEARCH_LIST,
  BINARY_SEARCH_DETAILS,
  ANALYSIS,
  ANALYSIS_RESULT,
  ANALYSIS_UPDATE,
  MODEL,
  // EMULATOR,
  ALL_THREAT,
  COMPARED_FRAMEWORKS,
  INFO,
  BASIC_SEARCH,
  ADVANCED_SEARCH,
  SYSTEMS_HEALTH,
  ERROR_LOGS,
  // EMULATOR_SCAN_DETAILS,
  BROWSE_FIRMWARE,
  THREAT,
  ANALYSIS_PDF,
  SCAN_SUMMARY,
  SCAN_OVERVIEW,
  MALWARE_REPORT,
  MALWARE_RESULT,
  // UPLOAD_VIRUS,
  VIRUS,
  // HYBRID_UPLOAD,
  HYBRID,
  PATCH_MANAGEMENT,
  MANAGE_ENGINE
} = PRIVATE_PATHS;

// const Analysis = WithSuspense(
//   lazy(() => import("../pages/Analysis/AnalysisPage"))
// );

// const ComparedFrameworks = WithSuspense(
//   lazy(() => import("../pages/Compare/ComparedList"))
// );

// const InviteLogin = WithSuspense(
//   lazy(() => import("../pages/Authentication/InviteLogin"))
// );
// const Teams = WithSuspense(lazy(() => import("../pages/Teams/index")));

// const TeamInfo = WithSuspense(lazy(() => import("../pages/Teams/TeamInfo")));

// const AnalysisResult = WithSuspense(
//   lazy(() => import("../pages/Analysis/AnalysisResult"))
// );
// const AnalysisUpdate = WithSuspense(
//   lazy(() => import("../pages/Analysis/AnalysisUpdate"))
// );
// const Model = WithSuspense(lazy(() => import("../pages/Models")));
// const Dashboard = WithSuspense(lazy(() => import("../pages/Dashboard")));
// const BinarySearchList = WithSuspense(
//   lazy(() => import("../pages/BinarySearch/SearchList"))
// );
// const BinarySearchDetails = WithSuspense(
//   lazy(() => import("../pages/BinarySearch/SearchDetails"))
// );
// const Login = WithSuspense(lazy(() => import("../pages/Authentication/Login")));
// const SignUp = WithSuspense(
//   lazy(() => import("../pages/Authentication/SignUp"))
// );
// const Search = WithSuspense(lazy(() => import("../pages/BinarySearch/Search")));
// const UserProfile = WithSuspense(
//   lazy(() => import("../pages/Settings/UserProfile"))
// );
// const Settings = WithSuspense(lazy(() => import("../pages/Settings/Settings")));
// const FileUpload = WithSuspense(
//   lazy(() => import("../pages/FileUpload/FileUpload"))
// );
// const HoneyPotPage = WithSuspense(
//   lazy(() => import("../pages/HoneyPot/HoneyPotPage"))
// );
// const EmulatorPage = WithSuspense(
//   lazy(() => import("../pages/EmulatorPage/EmulatorPage"))
// );
// const EmulatorScanDetails = WithSuspense(
//   lazy(() => import("../pages/EmulatorPage/EmulatorScansDetails"))
// );

// const Info = WithSuspense(lazy(() => import("../pages/Info")));

// const BasicSearch = WithSuspense(
//   lazy(() => import("../pages/Search/BasicSearch"))
// );

// const AdvancedSearch = WithSuspense(
//   lazy(() => import("../pages/Search/AdvancedSearch"))
// );

// const SystemsHealth = WithSuspense(
//   lazy(() => import("../pages/SystemsHealth/SystemsHealth"))
// );

// const ErrorLogs = WithSuspense(lazy(() => import("../pages/ErrorLogs")));

// const BrowseFirmware = WithSuspense(
//   lazy(() => import("../pages/BrowseFirmware"))
// );

// const ThreatIntelligence = WithSuspense(lazy(() => import("../pages/Threat")));

// const AnalysisPDF = WithSuspense(
//   lazy(() => import("../pages/PDF/AnalysisPDF"))
// );

// const ScanSummary = WithSuspense(
//   lazy(() => import("../pages/Hybrid/ScanSummary"))
// );

// const ScanOverview = WithSuspense(
//   lazy(() => import("../pages/Hybrid/ScanOverview"))
// );
// const MalwareReport = WithSuspense(
//   lazy(() => import("../pages/Malware/MalwareReport"))
// );

// const MalwareResult = WithSuspense(
//   lazy(() => import("../pages/Malware/MalwareResult"))
// );

// const VirusUpload = WithSuspense(
//   lazy(() => import("../pages/FileUpload/VirusUpload"))
// );

// const HybridUpload = WithSuspense(
//   lazy(() => import("../pages/FileUpload/HybridUpload"))
// );

// const Hybrid = WithSuspense(lazy(() => import("../pages/Hybrid/HybridList")));
// const Virus = WithSuspense(lazy(() => import("../pages/Malware/MalwareList")));

export const PUBLIC_ROUTES: AppRoute[] = [
  { path: LOGIN, element: <Login /> },
  { path: INVITE_LOGIN, element: <InviteLogin /> },
  { path: SIGNUP, element: <SignUp /> },
  { path: "*", element: <Navigate to="/login" replace /> },
];

export const PRIVATE_ROUTES: AppRoute[] = [
  { path: ANALYSIS, element: <Analysis /> },
  { path: ANALYSIS_RESULT, element: <AnalysisResult /> },
  { path: ANALYSIS_UPDATE, element: <AnalysisUpdate /> },
  { path: USERPROFILE, element: <UserProfile /> },
  { path: SETTINGS, element: <Settings /> },
  { path: FILEUPLOAD, element: <FileUpload /> },
  // { path: TEAMS, element: <Teams /> },
  // { path: TEAM_INFO, element: <TeamInfo /> },
  { path: DASHBOARD, element: <Dashboard /> },
  { path: BINARY_SEARCH, element: <Search /> },
  { path: BINARY_SEARCH_LIST, element: <BinarySearchList /> },
  { path: BINARY_SEARCH_DETAILS, element: <BinarySearchDetails /> },
  // { path: HONEYPOT, element: <HoneyPotPage /> },
  { path: MODEL, element: <Model /> },
  // { path: EMULATOR, element: <EmulatorPage /> },
  // { path: EMULATOR_SCAN_DETAILS, element: <EmulatorScanDetails /> },
  { path: ALL_THREAT, element: <AllThreat /> },
  { path: COMPARED_FRAMEWORKS, element: <ComparedFrameworks /> },
  { path: INFO, element: <Info /> },
  { path: BASIC_SEARCH, element: <BasicSearch /> },
  { path: ADVANCED_SEARCH, element: <AdvancedSearch /> },
  { path: ERROR_LOGS, element: <ErrorLogs /> },
  { path: SYSTEMS_HEALTH, element: <SystemsHealth /> },
  { path: THREAT, element: <ThreatIntelligence /> },
  { path: BROWSE_FIRMWARE, element: <BrowseFirmware /> },
  { path: ANALYSIS_PDF, element: <AnalysisPDF /> },
  { path: SCAN_SUMMARY, element: <ScanSummary /> },
  { path: SCAN_OVERVIEW, element: <ScanOverview /> },
  { path: MALWARE_REPORT, element: <MalwareReport /> },
  { path: MALWARE_RESULT, element: <MalwareResult /> },
  { path: PATCH_MANAGEMENT, element: <PatchManagement /> },
  // { path: UPLOAD_VIRUS, element: <VirusUpload /> },
  { path: VIRUS, element: <Virus /> },
  // { path: HYBRID_UPLOAD, element: <HybridUpload /> },
  { path: HYBRID, element: <Hybrid /> },
  { path: "*", element: <Navigate to="/dashboard" replace /> },
  { path: MANAGE_ENGINE, element: <ManageEngine />}
];
