import React from "react";
import {
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export const DashboardMenu = () => {
  const navigate = useNavigate();

  return (
    <Menu>
      <MenuButton
        variant={"primary"}
        as={Button}
        rightIcon={<BiChevronDown />}
        // color={useColorModeValue("#030303", "#f3f3f3")}
        color="white"
        backgroundColor="darkMode.grayBackground"
        _hover={{backgroundColor: "darkMode.grayBackground"}}
        _active={{backgroundColor: "darkMode.grayBackground"}}
        _focus={{backgroundColor: "darkMode.grayBackground"}}
      >
        Search
      </MenuButton>
      <MenuList 
        backgroundColor="darkMode.mainBgColor"
        >
        <MenuItem 
          backgroundColor="none"
          _hover={{backgroundColor: "darkMode.grayBackground"}}
          _active={{backgroundColor: "darkMode.grayBackground"}}
          _focus={{backgroundColor: "darkMode.grayBackground"}}
          onClick={() => navigate("/advanced-search")}>
          Advanced Search
        </MenuItem>
        <MenuItem 
          backgroundColor="none"
          _hover={{backgroundColor: "darkMode.grayBackground"}}
          _active={{backgroundColor: "darkMode.grayBackground"}}
          _focus={{backgroundColor: "darkMode.grayBackground"}}
          onClick={() => navigate("/basic-search")}>
          Basic Search
        </MenuItem>
        {/* <MenuItem 
          backgroundColor="darkMode.mainBgColor"
          _hover={{backgroundColor: "darkMode.grayBackground"}}
          onClick={() => navigate("/binary-search")}>
          Binary Search
        </MenuItem> */}
        <MenuItem 
          backgroundColor="none"
          _hover={{backgroundColor: "darkMode.grayBackground"}}
          _active={{backgroundColor: "darkMode.grayBackground"}}
          _focus={{backgroundColor: "darkMode.grayBackground"}}
          onClick={() => navigate("/browse-firmware")}>
          Browse Firmware
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
