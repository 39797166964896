import React from "react";
import {
  Flex,
  FormControl,
  InputGroup,
  Select,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";

export const SelectLayout = ({ onChange, children, value }) => {
  return (
    <Flex height={{ base: "49px", "2xl": "80px" }} width="100%">
      <InputGroup height={"100%"}>
        <Select
          onChange={onChange}
          value={value}
          mx={0.5}
          size="lg"
          placeholder="Choose a Firmware"
          fontSize={{ base: "16px", "2xl": "15px" }}
          border="1px solid rgba(0, 0, 0, 0.44)"
          borderRadius="0px 0px 0px 0px"
          _focus={{
            outline: "none",
          }}
        >
          {children}
        </Select>
      </InputGroup>
    </Flex>
  );
};

export const YaraLayout = ({ onChange, sx, value }) => {
  return (
    <FormControl>
      <Textarea
        w={["100%", "45%", "45%", "65%"]}
        mb={[10, 0, 0, 0]}
        px="3"
        border="1px"
        bgColor={useColorModeValue("lightMode.BgColor", "darkMode.BgColor")}
        borderColor={useColorModeValue(
          "lightMode.BgColor",
          "darkMode.mainTextColor"
        )}
        id="Device Name"
        isRequired
        position="relative"
        onChange={onChange}
        value={value}
      />
      <Text sx={sx}>Yara rule</Text>
    </FormControl>
  );
};

export const FirmwareSelect = ({ onChange, placeholder, children }) => {
  return (
    <Select
      size="lg"
      placeholder={placeholder}
      fontSize={{ base: "16px", "2xl": "14px" }}
      border="1px"
      borderColor="black"
      borderRadius="0px"
      w={["100%", "45%", "45%", "45%"]}
      isRequired
      _focus={{
        outline: "none",
      }}
      onChange={onChange}
    >
      {children}
    </Select>
  );
};
