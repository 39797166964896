import { Box, Button, useColorModeValue } from "@chakra-ui/react";
import React from "react";

const AddComment = ({ show, showBtn, onClick, SecOnClick, FinalOnClick }) => {
  const btn = useColorModeValue("lightMode.secBgColor", "lightMode.btnBgColor");
  return (
    <Box>
      {show === "button" && (
        <Box height={"auto"} alignItems={"center"} borderRadius="4px">
          <Box h={"16px"} />
          <Box
            width={"95%"}
            display="flex"
            mx="auto"
            justifyContent="space-between"
            flexDirection={["column", "row", "row", "row"]}
            alignItems="center"
          >
            <Button
              h={"40px"}
              fontSize={[13, 13, "15px", "15px"]}
              paddingX={"9"}
              py="1"
              fontWeight={"500"}
              bgColor={btn}
              width={"125px"}
              onClick={onClick}
            >
              Comment
            </Button>

            {showBtn ? (
              <Box
                display={"flex"}
                flexDirection={["column", "row", "row", "row"]}
              >
                <Button
                  h={"40px"}
                  fontSize={[13, 13, "15px", "15px"]}
                  paddingX={"5"}
                  py="1"
                  fontWeight={"500"}
                  bgColor={btn}
                  ml={[0, 4, 4, 4]}
                  mt={["13px", 0, 0, 0]}
                  width={"125px"}
                  onClick={SecOnClick}
                >
                  Show Comment
                </Button>

                <Button
                  h={"40px"}
                  fontSize={[13, 13, "15px", "15px"]}
                  paddingX={"5"}
                  py="1"
                  fontWeight={"500"}
                  bgColor={btn}
                  ml={[0, 4, 4, 4]}
                  mt={["13px", 0, 0, 0]}
                  width={"125px"}
                  onClick={FinalOnClick}
                  data-testid="add_button"
                >
                  Add Comments
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box h={"16px"} />
        </Box>
      )}
    </Box>
  );
};

export default AddComment;
