import { Box, useColorMode } from "@chakra-ui/react";
import React from "react";

export const Result = ({ data }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      w="40%"
      borderLeft={"1px"}
      borderColor={
        "gray"
      }
      py="8px"
      px="6px"
      display="flex"
      alignItems="center"
    >
      {data}
    </Box>
  );
};

export const ScanResult = ({ data, onClick }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      w="40%"
      borderLeft={"1px"}
      cursor="pointer"
      onClick={onClick}
      _hover={{ boxShadow: "lg" }}
      borderColor={
        'gray'
      }
      py="8px"
      px="6px"
      display="flex"
      alignItems="center"
      overflowX={"auto"}
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "24px",
        },
      }}
    >
      {data}
    </Box>
  );
};

export const Info = ({ data, w }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      w={w}
      borderLeft={"1px"}
      borderColor={
        'gray'
      }
      py="8px"
      px="6px"
      display="flex"
      alignItems="center"
      overflowX="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "24px",
        },
      }}
    >
      {data}
    </Box>
  );
};

export const Layout = ({ children }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      display={"flex"}
      fontWeight="600"
      textAlign={"left"}
      border={"1px"}
      borderColor="gray" 
    >
      {children}
    </Box>
  );
};

export const GenInfo = ({ children }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      width={"100%"}
      fontWeight="600"
      textAlign={"center"}
      border={"1px"}
      borderColor={'gray'}
      bg="darkMode.grayBackground"
    >
      {children}
    </Box>
  );
};

export const File = ({ label }) => {
  return (
    <Box w="20%" py="8px" px="6px" display="flex" alignItems="center">
      {label}
    </Box>
  );
};
