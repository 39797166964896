import React, { useState } from "react";
import { Box, Button, Flex, Input, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import InputField from "../../components/CustomInput/InputField";
import { useFirmwareScanList } from "../../services/query/firmware";
import { useInitializeBinary } from "../../services/query/search";
import useCustomToast from "../../utils/notification";
import CustomHeader from "../../components/common/CustomHeader";
import {
  SelectLayout,
  YaraLayout,
} from "../../components/CustomInput/SelectLayout";
import Results from "../../components/data/search-components/Results";

const Search = () => {
  const navigate = useNavigate();
  const { errorToast, successToast } = useCustomToast();
  const [scanUId, setScanUId] = useState("");
  const [yaraRule, setYaraRule] = useState("");
  const [description, setDescription] = useState("");
  const [yaraFile, setYaraFile] = useState("");
  const [initSearch, setInitSearch] = useState<any>("");

  const { data: firmwareId } = useFirmwareScanList();

  const { mutate: binaryMutate, isLoading: isBinaryCreating } =
    useInitializeBinary({
      onSuccess: (res) => {
        successToast("Upload successful");
        setInitSearch(res);
      },
      onError: (err) => {
        errorToast(
          err?.response?.data?.message?.error_message ||
            err?.response?.data?.yara_rule?.[0]
        );
      },
    });

  const fileUpload = async (e) => {
    setYaraFile(e.target.files[0]);
  };

  const initializeSearch = async (event) => {
    event.preventDefault();
    const payload = scanUId
      ? {
          yara_rule: yaraRule,
          short_description: description,
          scan_uid: scanUId,
        }
      : {
          yara_rule: yaraRule.length > 1 ? yaraRule : yaraFile,
          short_description: description,
        };

    const formdata = new FormData();

    formdata.append("file_uploaded", yaraFile);
    formdata.append("scan_uid", scanUId);

    binaryMutate(yaraFile ? formdata : payload);
  };

  const labelHead = {
    color: useColorModeValue("lightMode.btnBgColor", "darkMode.btnBgColor"),
    position: "absolute",
    top: "-13px",
    left: "13px",
    zIndex: "2",
    bgColor: useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor"),
    width: "fit-content",
    fontSize: "13.6px",
    px: 2,
  };

  return (
    <Box
      color={useColorModeValue(
        "lightMode.mainTextColor",
        "darkMode.mainTextColor"
      )}
    >
      <Box>
        <Flex
          mb={{ base: "45px", "2xl": "105px" }}
          justify={"space-between"}
          align="center"
          py={4}
        >
          <CustomHeader title="Binary Search" />
          <Button
            variant={"outline"}
            onClick={() => navigate("/binary-search/list")}
          >
            Binary Search List
          </Button>
        </Flex>
        <Box
          w={{ base: "100%", lg: "55%" }}
          mx="auto"
          mb={{ base: "55px", "2xl": "113px" }}
          // as="form"
          // onSubmit={initializeSearch}
        >
          <SelectLayout
            onChange={(e) => setScanUId(e.target.value)}
            value={scanUId}
          >
            {firmwareId?.results.map((data, i) => (
              <option key={i + 1} value={data?.scan_uid}>
                {data?.device_name || "No device name"}
              </option>
            ))}
          </SelectLayout>
          <Flex
            justifyContent="space-between"
            mx="auto"
            width={{ base: "100%" }}
            my="60px"
          >
            <YaraLayout
              value={yaraRule}
              onChange={(e) => setYaraRule(e.target.value)}
              sx={labelHead}
            />
            <InputField
              type="text"
              onChange={(e) => setDescription(e.target.value)}
              label="Short description"
              value={description}
            />
          </Flex>
          <Box my="20px" textAlign="center" fontWeight="bold" fontSize="25px">
            OR
          </Box>
          <Box my="25px" border="1px solid black" borderRadius="7px">
            <Input
              type="file"
              title="Choose a yara file"
              onChange={(e) => {
                fileUpload(e);
              }}
              border="none"
              py="6px"
            />
          </Box>
          <Box>
            <Button
              borderRadius="10px"
              w="100%"
              bg={useColorModeValue(
                "lightMode.labelBgColor",
                "darkMode.labelBgColor"
              )}
              size="lg"
              type="submit"
              isLoading={isBinaryCreating}
              onClick={initializeSearch}
            >
              Search
            </Button>
          </Box>
        </Box>
        <Results initSearch={initSearch} />
      </Box>
    </Box>
  );
};

export default Search;
