import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import {
  getNotifications,
  markAsRead,
  markAllAsRead,
  deleteNotifications,
  deleteNotificationsId,
} from "../api/notification";
import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  DELETE_NOTIFICATIONS,
  DELETE_NOTIFICATIONS_ID,
} from "../queryKeys";

export const useGetNotifications = (options = {}) => {
  const { data, isLoading, error } = useQuery<any, AxiosError>(
    [GET_NOTIFICATIONS],
    getNotifications,
    { ...options, refetchInterval: 60_000 }
  );

  return { data, isLoading, error };
};

export const useDeleteNotifications = (options = {}) => {
  const { mutate, isLoading, isSuccess } = useMutation(deleteNotifications, {
    mutationKey: DELETE_NOTIFICATIONS,
    ...options,
  });
  return { mutate, isLoading, isSuccess };
};

export const useDeleteNotificationsId = (options = {}) => {
  const { mutate, isLoading, isSuccess } = useMutation(deleteNotificationsId, {
    mutationKey: DELETE_NOTIFICATIONS_ID,
    ...options,
  });
  return { mutate, isLoading, isSuccess };
};

export const useMarkAsRead = (options = {}) => {
  const { mutate, isLoading } = useMutation(markAsRead, {
    mutationKey: MARK_NOTIFICATION_AS_READ,
    ...options,
  });

  return { mutate, isLoading };
};

export const useMarkAllAsRead = (options = {}) => {
  const { mutate, isLoading } = useMutation(markAllAsRead, {
    mutationKey: MARK_ALL_NOTIFICATIONS_AS_READ,
    ...options,
  });

  return { mutate, isLoading };
};
