import React from "react";

// Importing pages

import Pages from "./routes";

const App = () => {
  return <Pages />;
};

export default App;
