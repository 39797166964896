import React from "react";
import { Box, Heading, Text, VStack, HStack } from "@chakra-ui/layout";
import {
  FormControl,
  // useColorModeValue,
  FormLabel,
  Image,
} from "@chakra-ui/react";
import { Checkbox } from "@chakra-ui/checkbox";
import { Button } from "@chakra-ui/button";
import { Link as RouterLink } from "react-router-dom";
import useCustomToast from "../../utils/notification";
import { Logo } from "../../assets";
import { useLogin } from "../../services/query/login";
import { Formik } from "formik";
import * as Yup from "yup";
import { LoginInput } from "../../components/CustomInput/AuthInput";

const Login = () => {
  const { errorToast, successToast } = useCustomToast();

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password required"),
  });

  const { mutate, isLoading: isCreateLoading } = useLogin({
    onSuccess: (res) => {
      successToast("Login successful");
      localStorage.setItem("user", JSON.stringify(res));
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 200);
    },
    onError: (err) => {
      errorToast(err?.response?.data?.detail || err.message);
    },
  });

  const handleSubmit = (values: Record<string, any>) => {
    const { username, password } = values;
    mutate({
      username,
      password,
    });
  };

  // const btnStyle = useColorModeValue(
  //   "lightMode.btnTextColor",
  //   "darkMode.btnTextColor"
  // );
  const btnStyle = "darkMode.btnTextColor"

  return (
    <Box
      // color={useColorModeValue(
      //   "lightMode.mainTextColor",
      //   "darkMode.mainTextColor"
      // )}
      // bgColor={useColorModeValue(
      //   "lightMode.mainBgColor",
      //   "darkMode.mainBgColor"
      // )}
      color="darkMode.mainTextColor"
      bgColor="darkMode.mainBgColor"
      w={[350, "md"]}
      p={[8, 10]}
      mt={[90, "15vh"]}
      mx="auto"
      borderRadius={10}
      zIndex="10"
    >
      <VStack spacing={4} align="flex-start" w="full">
        <VStack>
          <Heading>
            <Image height={130} width={143} mt={-6} ml={[75, 115]} src={Logo} />
          </Heading>
        </VStack>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              <Text fontSize={20} fontWeight={500} float="left">
                Login
              </Text>
              <LoginInput
                label="Username"
                my="0"
                mt={[49, 10]}
                value={values?.username}
                name="username"
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched?.username && errors?.username && errors?.username
                }
              />
              <LoginInput
                label="Password"
                my="10px"
                mt={[3, 0]}
                name="password"
                value={values?.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched?.password && errors?.password && errors?.password
                }
              />
              <HStack w="full" justify="space-between" mt="9px">
                <Checkbox mt={[5, 0]}>Remember me</Checkbox>
              </HStack>
              <FormControl>
                <FormLabel
                  paddingTop={[39, 29]}
                  float="left"
                  fontSize={12}
                  fontWeight={600}
                >
                  Don't Have An Account?
                  <Button
                    ml={1}
                    fontSize={16}
                    fontWeight={600}
                    variant="link"
                    colorScheme="white"
                  >
                    <RouterLink to="/signup"> Sign Up</RouterLink>
                  </Button>
                </FormLabel>
                <Button
                  type="submit"
                  ml={[2, "76px"]}
                  mt={[7, 5]}
                  colorScheme="gray"
                  color={btnStyle}
                  backgroundColor="darkMode.grayBackground"
                  w={[20, "auto"]}
                  alignSelf="end"
                  isLoading={isCreateLoading}
                >
                  Login
                </Button>
              </FormControl>
            </form>
          )}
        </Formik>
      </VStack>
    </Box>
  );
};

export default Login;
