import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { GET_COMPARISONS } from "../../services/queryKeys";
import { useGetComparisons } from "../../services/query/comparison";
import ErrorComponent from "../../components/data/ErrorComponent";
import PageLoader from "../../components/Loaders/PageLoader";
import CustomHeader from "../../components/common/CustomHeader";
import { ComparedListComponent } from "../../components/data/Analysis/ComparedListComponent";

const ComparedList = () => {
  const { data, error, isLoading, refetch } = useGetComparisons(10, 0);

  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.invalidateQueries(GET_COMPARISONS);
  }, [queryClient]);

  return (
    <Box px={8}>
      <CustomHeader title="Compared Firmwares" />

      {isLoading && (
        <Grid
          templateColumns={["repeat(1, 1fr)", null, null, "repeat(2, 1fr)"]}
          gap={6}
        >
          <GridItem>
            <PageLoader lineNo={6} />
          </GridItem>
          <GridItem>
            <PageLoader lineNo={6} />
          </GridItem>
        </Grid>
      )}

      {error && <ErrorComponent message={error?.message} reload={refetch} />}

      {data && (
        <Grid
          my="1em"
          columnGap="2em"
          templateColumns={["repeat(1, 1fr)", null, null, "repeat(2, 1fr)"]}
        >
          {data?.results?.map((item: any, i: number) => (
            <ComparedListComponent
              uid1={item?.firmwares[0]?.scan_uid}
              uid2={item?.firmwares[1]?.scan_uid}
              name1={item?.firmwares[0]?.device_name}
              name2={item?.firmwares[1]?.device_name}
              key={i}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default ComparedList;
