import { Box, Skeleton, Stack } from "@chakra-ui/react";
import React from "react";

const PageLoader = ({ lineNo = 2 }) => {
  return (
    <Stack>
      {new Array(lineNo).fill(0).map((el, i) => (
        <Box key={i}>
          <Skeleton height="20px" width="100%" mt="8px" />
          <Skeleton height="20px" width="60%" mt="8px" />
        </Box>
      ))}
    </Stack>
  );
};

export default PageLoader;
