import React from "react";
import {
  Box,
  Flex,
  Grid,
  Heading,
  GridItem,
  useColorModeValue,
} from "@chakra-ui/react";
import DashboardCard from "../../components/data/Dashboard/DashboardCard";
import FirmwareNews from "../../components/data/Dashboard/FirmwareNews";
import GeneralItems from "../../components/data/Dashboard/GeneraItems";
import { useNavigate } from "react-router-dom";
import { DashboardMenu } from "../../components/data/Dashboard/Menu";
import FirmwareUpload from "../../components/data/Dashboard/Widgets/FirmwareUpload";
import ThreatIntelligence from "../../components/data/Dashboard/Widgets/ThreatIntelligence";
import FirmwareComparisons from "../../components/data/Dashboard/Widgets/FirmwareComparisons";

const Dashboard = () => {
  const navigate = useNavigate();
  // const textColorMode = useColorModeValue(
  //   "lightMode.mainTextColor",
  //   "darkMode.mainTextColor"
  // );

  const textColorMode = "darkMode.mainTextColor"
  // const colorMode = useColorModeValue(
  //   "lightMode.mainTextColor",
  //   "darkMode.mainTextColor"
  // )

  const colorMode = "darkMode.mainTextColor"

  return (
    <Box
      color={colorMode}
      minHeight="100%"
    >
      <Flex justify={"space-between"} align="center" py={4} flexWrap="wrap">
        <Heading
          fontSize={{ base: "25px", md: "36px" }}
          my="1em"
          color={textColorMode}
        >
          Dashboard
        </Heading>
        <Flex gap={4} flexWrap="wrap">
          <Flex
            color="white"
            border="1px solid gray"
            onClick={() => navigate("/file-upload")}
            px="17px"
            alignItems="center"
            borderRadius="7px"
            cursor="pointer"
          >
            Upload Firmware
          </Flex>
          <DashboardMenu />
        </Flex>
      </Flex>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(5, 1fr)" }}
        gap="28px"
      >
        <GridItem colSpan={3}>
          <DashboardCard />
          <FirmwareUpload />
          <ThreatIntelligence />
          <FirmwareComparisons />
        </GridItem>
        <GridItem colSpan={{ base: 3, lg: 2 }} w="90%" >
          <GeneralItems />
          <FirmwareNews />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Dashboard;
