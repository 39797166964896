import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Text,
  Image,
  useColorMode,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { menuLists, adminLists } from "../common/constants";
import { useGetUserProfile } from "../../services/query/user";
import { useIsNavActive, useLogOut } from "../../utils/helpers";
import { sideLinks } from "../common/constants";

const SideMenu = React.forwardRef(({ isOpen, onClose }: any, btnRef: any) => {
  const bgColor = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.secBgColor"
  );
  const { data: userInfo } = useGetUserProfile();
  const logOut = useLogOut();
  const location = useLocation();
  const isNavActive = useIsNavActive();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton mb={2} />
        <DrawerBody bgColor={bgColor}>
          <Box mt={8}>
            {sideLinks.map((el, idx: number) => {
              const scan_uid = localStorage.getItem("scan_uid") as string
              let linkURL = ""
              if (el.path === "/malware") {
                linkURL = "/malware/report/"+scan_uid
              } else if (el.path === "/security") {
                linkURL = "/security/scan-overview/"+scan_uid
              } else if (el.path === "/analysis") {
                linkURL = "/analysis/"+scan_uid
              } else {
                linkURL = el.path;
              } 
              const pathString = window.location.pathname.split("/")[1]
              if (pathString !== "analysis" && 
                pathString !== "security" && 
                pathString !== "malware" &&
                pathString !== "threat-intelligence"
              ) {
                if (el.path === "/malware" ||
                el.path === "/security" || 
                el.path === "/analysis"
                ) {
                  return (<div style={{display:"none"}}></div>)
                }
                
              }
              if (el.name === "Health") {
                return (<div style={{display:"none"}}></div>)
              } else {
                return (
                <Link key={idx} to={linkURL} onClick={onClose}>
                  <Box
                    p={2}
                    display="flex"
                    borderRadius="4px"
                    alignItems="center"
                    justifyContent="flex-start"
                    bgColor={
                      isNavActive(el.path)
                        ? colorMode === "light"
                          ? "lightMode.btnBgColor"
                          : "darkMode.navBgColor"
                        : "none"
                    }
                  >
                    {colorMode === "light" ? el.lightIcon : el.darkIcon}
                    <Text
                      ml="2"
                      color={
                        colorMode === "light"
                          ? "lightMode.mainTextColor"
                          : "darkMode.mainTextColor"
                      }
                    >
                      {el.name}
                    </Text>
                  </Box>
                </Link>
              )}
            })}
          </Box>

          <Divider my={4} />
          <Box>
            {(userInfo?.is_admin === true ? adminLists : menuLists).map(
              (el, idx) => (
                <Link
                  to={el.path ?? location.pathname}
                  key={idx}
                  onClick={
                    (el.action === "LOG_OUT" && logOut) ||
                    (el.action === "COLOR_MODE" && toggleColorMode) ||
                    undefined
                  }
                >
                  <Box
                    p={2}
                    color="black"
                    display="flex"
                    onClick={onClose}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Image
                      width="17px"
                      color="black"
                      alt="dashboard"
                      src={colorMode === "dark" ? el.darkIcon : el.lightIcon}
                    />
                    <Text
                      ml="2"
                      color={
                        colorMode === "light"
                          ? "lightMode.mainTextColor"
                          : "darkMode.mainTextColor"
                      }
                    >
                      {el.action !== "COLOR_MODE"
                        ? el.label
                        : colorMode === "dark"
                        ? "Light Mode"
                        : "Dark Mode"}
                    </Text>
                  </Box>
                </Link>
              )
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
});

export default SideMenu;
