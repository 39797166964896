import {
  GET_FIRMWARE_NEWS,
  GET_GENERAL_ITEMS,
  GET_COMPARISONS,
  GET_THREAT_INTELLIGENCE,
  GET_THREAT_DETAILS,
  GET_THREAT_COUNT,
  GET_MODELS,
} from "../apiUrl";
import axiosInstance from "../axiosInstance";

export const getFirmwareNews = async () => {
  const res = await axiosInstance.get(GET_FIRMWARE_NEWS);
  return res.data;
};

export const getGeneralItems = async () => {
  const res = await axiosInstance.get(GET_GENERAL_ITEMS);
  return res.data;
};

export const getThreatDetails = async () => {
  const res = await axiosInstance.get(GET_THREAT_DETAILS);
  return res.data;
};

export const getTwoRecentComparison = async () => {
  const res = await axiosInstance.get(`${GET_COMPARISONS}?limit=10&offset=0`);
  return res.data;
};

export const getThreatIntelligenceSummary = async () => {
  const res = await axiosInstance.get(`${GET_THREAT_INTELLIGENCE}/`);
  return res.data;
};

export const getThreatIntelligenceSummaryDetail = async ({ queryKey }) => {
  const [, scan_uid] = queryKey;
  const res = await axiosInstance.get(`${GET_THREAT_INTELLIGENCE}/${scan_uid}`);
  return res.data;
};

export const getThreatCount = async () => {
  const res = await axiosInstance.get(GET_THREAT_COUNT);
  return res.data;
};

export const getRecentFirmwareList = async () => {
  const res = await axiosInstance.get(`${GET_MODELS}?limit=10&offset=0`);
  return res.data;
};
