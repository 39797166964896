import React, { useMemo } from "react";
import { Box, Button, Image, Input, Text } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import {
  baseStyle,
  focusedStyle,
  rejectStyle,
  inptBoxStyle,
  acceptStyle,
} from "../../styles/styles";

const FileUpload = ({ dropUpload, base, onClick, onChange, src }) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      noClick: true,
      onDrop: (files) => {
        dropUpload(files);
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Box
      px={[2, 9, 10, "200px"]}
      py={[2, 3, 5, 5]}
      sx={{
        "@media (max-width: 500px)": {
          paddingTop: "5px",
          paddingBottom: "5px",
        },
      }}
    >
      <Box mt="0" sx={inptBoxStyle}>
        File
      </Box>
      {base === "" ? (
        <Input
          type="file"
          title="Choose a video please"
          onChange={onChange}
          border="none"
          mt="1"
        />
      ) : (
        <>
          <Text>File has been added</Text>
          <Button mb="3" variant="outline" onClick={onClick}>
            Change
          </Button>
        </>
      )}

      <div className="container">
        <Box {...getRootProps({ style })} flexDirection="column">
          <input {...getInputProps()} />
          {base === "" ? (
            <Box textAlign="center">
              <Image src={src} alt="upload" w="100px" />

              <Text fontWeight="600" color="black">
                DRAG & DROP
              </Text>
              <Text color="black">To Upload</Text>
            </Box>
          ) : (
            <Text color="black" fontWeight="600">
              File has been added
            </Text>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default FileUpload;

export const FileButton = ({ isLoading }) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      w="100"
      mt="5"
      px={[2, 9, 10, "200px"]}
    >
      <Button
        type="submit"
        isLoading={isLoading}
        colorScheme="#4E97D1"
        color="white"
        size="md"
        height="40px"
        width={["100%", "150px", "150px", "150px"]}
      >
        Upload
      </Button>
    </Box>
  );
};
