import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Ellipse43, Ellipse44, PdfAnalysis } from "../../../../assets";
import BoxDesign from "./BoxDesign";
import { Layout, Layout1, Layout2 } from "./PdfLayout";

const GeneralInfo = ({ meta, analysis }) => {
  const date = new Date().toLocaleDateString();

  return (
    <Box>
      <Box>
        <Box textAlign="left" color="white">
          <Box w="95%" mx="auto">
            <Text fontSize="55px" fontWeight="800" pt="70px">
              Firmware Analysis Report
            </Text>
            <Text fontSize="23px">Generated on {date}</Text>
          </Box>

          <Box mt="200px" position="relative" h="690px" overflow="hidden">
            <Box w="50%">
              <Image src={Ellipse43} alt="wave1" />
            </Box>

            <Box w="60%" position="absolute" top="0">
              <Image src={Ellipse44} alt="wave2" />
            </Box>
          </Box>
        </Box>
        <Box mt="50px" w="95%" mx="auto">
          <Text fontSize="35px" fontWeight="700">
            General Information
          </Text>
          <BoxDesign />

          <Box mt={"50px"}>
            <Box display="flex" alignItems="center">
              <Image src={PdfAnalysis} alt="analysis" w="57px" h="50px" />

              <Text fontSize="30px" ml="20px" fontWeight="600">
                Analysis Data
              </Text>
            </Box>
            <Box mt="30px">
              <Layout1 color="white">
                <Text w="30%" pl="9px">
                  device_class:
                </Text>
                <Text w="65%">{meta?.device_class || "No Result"}</Text>
              </Layout1>

              <Layout1 color="black">
                <Text w="30%" pl="9px">
                  device_name:
                </Text>
                <Text w="65%">{meta?.device_name || "No Result"}</Text>
              </Layout1>

              <Layout1 color="white">
                <Text w="30%" pl="9px">
                  device_part:
                </Text>
                <Text w="65%">{meta?.device_part || "No Result"}</Text>
              </Layout1>

              <Layout1 color="black">
                <Text w="30%" pl="9px">
                  hid:
                </Text>
                <Text w="65%">{meta?.hid || "No Result"}</Text>
              </Layout1>

              <Layout1 color="white">
                <Text w="30%" pl="9px">
                  included_files:
                </Text>
                <Text w="65%">
                  {JSON.stringify(meta?.included_files || "No Result")}
                </Text>
              </Layout1>

              <Layout1 color="black">
                <Text w="30%" pl="9px">
                  number_of_included_files:
                </Text>
                <Text w="65%">
                  {meta?.number_of_included_files || "No Result"}
                </Text>
              </Layout1>

              <Layout1 color="white">
                <Text w="30%" pl="9px">
                  release_date:
                </Text>
                <Text w="65%">{meta?.release_date || "No Result"}</Text>
              </Layout1>

              <Layout1 color="black">
                <Text w="30%" pl="9px">
                  size:
                </Text>
                <Text w="65%">{meta?.size || "No Result"}</Text>
              </Layout1>

              <Layout1 color="white">
                <Text w="30%" pl="9px">
                  vendor:
                </Text>
                <Text w="65%">{meta?.vendor || "No Result"}</Text>
              </Layout1>
            </Box>

            <Layout bg="#777D31">
              <Text mb="5">File Hashes</Text>
            </Layout>
            <Layout1 color="white">
              <Text w="30%" pl="9px">
                SHA1:
              </Text>
              <Text w="65%">{analysis?.file_hashes?.sha1 || "No Result"}</Text>
            </Layout1>
            <Layout1 color="black">
              <Text w="30%" pl="9px">
                SHA256:
              </Text>
              <Text w="65%">
                {analysis?.file_hashes?.sha256 || "No Result"}
              </Text>
            </Layout1>
            <Layout1 color="white">
              <Text w="30%" pl="9px">
                SHA512:
              </Text>
              <Text w="65%">
                {analysis?.file_hashes?.sha512 || "No Result"}
              </Text>
            </Layout1>

            {analysis?.binwalk?.entropy_analysis_graph && (
              <Layout bg="#777D31">
                <Text mb="5">Binwalk Enthropy Graph</Text>

                <img
                  src={`data:image/png;base64,${analysis?.binwalk?.entropy_analysis_graph}`}
                  width="1024px"
                  alt="enthropy"
                />
              </Layout>
            )}

            <Layout bg="#318B80">
              <Text mb="5">STATIC ANALYSIS SUMMARY</Text>
            </Layout>
            <Layout2 bg="#4C2078">
              <Text w="30%" pl="9px">
                CPU ARCHITECTURE ANALYSIS:
              </Text>
              <Text w="65%">
                {analysis?.cpu_architecture?.skipped || "No Result"}
              </Text>
            </Layout2>
            <Layout2 bg="#78205A">
              <Text w="30%" pl="9px">
                FILE TYPE ANALYSIS:
              </Text>
              <Text w="65%">
                {analysis?.file_type?.full || "No Result"},{" "}
                {analysis?.file_type?.mime || "No Result"}
              </Text>
            </Layout2>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralInfo;
