import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";

import { getVirusList, getVirusReport, postVirus } from "../api/virus";
import { UPLOAD_VIRUS, VIRUS_LIST, VIRUS_REPORT } from "../queryKeys";

export const useUploadVirus = (options = {}) => {
  const { mutate, isLoading } = useMutation(postVirus, {
    mutationKey: UPLOAD_VIRUS,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetVirusReport = (sha: string, options = {}) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [VIRUS_REPORT, sha],
    getVirusReport,
    { ...options }
  );
  return { data, isLoading, error, refetch };
};

export const useGetVirusList = (options = {}) => {
  const { data, isLoading, error, refetch } = useQuery<any, AxiosError>(
    [VIRUS_LIST],
    getVirusList,
    { ...options }
  );
  return { data, isLoading, error, refetch };
};
