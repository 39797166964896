import { Box, Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { InfoItemComponent } from "../../DropDownButtonComponent";
import { useGetAnalysisCount } from "../../../../services/query/analysis";
import { useParams } from "react-router-dom";
// import { useGetRadareUrl } from "../../../../services/query/radare";
import { RadareLink, RawDownload } from "./Buttons";
import { formatBytes } from "../../../../utils/helpers";

const TopData = ({ data: firmWareData }) => {
  const { scan_uid } = useParams();
  // const { data: radareData, isLoading: isRadareLoading } =
  //   useGetRadareUrl(scan_uid);
  const { data: analysisCount } = useGetAnalysisCount(scan_uid as string);
  const metaData = firmWareData?.message?.firmware?.meta_data;
  // if scan_uid?.length > 
  // const truncateStr = 

  const textColorMode = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.mainTextColor"
  );
  const cardBgColor = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );

  return (
    <Flex
      gap={4}
      justifyContent={"space-between"}
      flexDirection={["column", "column", "row", "row"]}
    >
      {/* <Box width={["100%", "100%", "55%", "55%"]}>
        <Text
          color={textColorMode}
          fontWeight={"semibold"}
          fontSize={[12, 12, "18px", "18px"]}
        >
          {metaData?.device_name}
        </Text>

        <Box fontSize={[10, 10, "15px", "15px"]} my={"3.5"}>
          <Text as="span" fontWeight={"800"} color={textColorMode}>
            UID:
          </Text>
          <Text as="span"> {scan_uid}</Text>
        </Box> */}

        {/* <Flex gap={4} my="14px">
          <RawDownload scan={scan_uid} meta={metaData?.device_name} /> */}

          {/* <Compare onClick={() => setOpen(true)} /> */}
          {/* <RadareLink
            isLoading={isRadareLoading}
            radare={radareData?.message?.radare_url}
            scan={scan_uid}
          />
        </Flex> */}
      {/* </Box> */}

      <Box
        p="2em"
        shadow="lg"
        background="#d9e3ee"
        borderRadius={"15px"}
        bgColor={cardBgColor}
        width={["100%", "100%", "100%", "100%"]}
      >
        <Text
          pb="1em"
          fontWeight="600"
          color={textColorMode}
          fontSize={["11px", "11px", "15px", "15px"]}
        >
          General Information
        </Text>

        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <Box fontSize={[10, 10, "15px", "15px"]} my={"3.5"}>
            <Text
              color={textColorMode}
              fontWeight={"semibold"}
              fontSize={[12, 12, "18px", "18px"]}
            >
              {metaData?.device_name}
            </Text>
            <Text as="span"> {scan_uid}</Text>
            {analysisCount?.malware_count < 1 &&
              <Text
                color={textColorMode}
                fontSize={[13, 13, "18px", "18px"]}
                marginTop={4}
                fontWeight={500}
                padding={2}
                backgroundColor="rgba(100, 255, 150, 0.2)"
                border="1px solid rgba(255, 255, 255, 0.5)"
                borderLeft="3px solid rgba(255, 255, 255, 0.5)"
                borderRadius={5}
              >
                Safe to execute/deploy -- no malware found
              </Text>
            }
            {analysisCount?.malware_count >= 1 &&
              <Text
                color={textColorMode}
                fontSize={[13, 13, "18px", "18px"]}
                marginTop={4}
                fontWeight={500}
                padding={2}
                backgroundColor="rgba(255, 120, 150, 0.2)"
                border="1px solid rgba(255, 255, 255, 0.5)"
                borderLeft="3px solid rgba(255, 255, 255, 0.5)"
                borderRadius={5}
              >
                Not Safe to execute/deploy -- malware found
              </Text>
            }
          </Box>
          <div></div>
          <InfoItemComponent text="Device Name" data={metaData?.device_name} />
          <InfoItemComponent
            text="Device Class"
            data={metaData?.device_class}
          />
          <InfoItemComponent text="Device part" data={metaData?.device_part} />
          <InfoItemComponent text="HID" data={metaData?.hid} />
          <InfoItemComponent text="File Type" data="Not Available" />
          <InfoItemComponent text="Vendor" data={metaData?.vendor} />
          <InfoItemComponent text="Firmware Version" data={metaData?.version} />
          <InfoItemComponent
            text="Release Date"
            data={metaData?.release_date}
          />
          <InfoItemComponent
            text="File Size"
            data={formatBytes(metaData?.size)}
          />
          <InfoItemComponent
            text="Number of included files"
            data={metaData?.number_of_included_files}
          />
          <InfoItemComponent
            text="Total threat"
            data={analysisCount?.threat_count}
          />
          <InfoItemComponent
            text="CVSS Score"
            data={analysisCount?.cve_count}
          />
          <InfoItemComponent text="Total CWE" data={analysisCount?.cwe_count} />
          <InfoItemComponent
            text="Total Malware"
            data={analysisCount?.malware_count}
          />
        </Grid>
      </Box>

      {/* {open && (
        <AnalysisModal
          data={firmWareData}
          isOpen={true}
          onClose={() => setOpen(false)}
        />
      )} */}
    </Flex>
  );
};

export default TopData;
