import React from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { GiPenguin } from "react-icons/gi";
import { FaPython, FaMicrochip, FaMemory } from "react-icons/fa";
import { RiStackshareLine } from "react-icons/ri";
import { FiHardDrive } from "react-icons/fi";
import { props } from "../../types";

const StatusCards = ({
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  text8,
  text9,
  text10,
  text11,
}: props) => {

  function truncate(n, digits) {
    const step = Math.pow(10, digits || 0);
    const temp = Math.trunc(step * n);

    return temp / step;
  }

  const toMB = (n) => {
    const floatNumber = parseFloat(n)/(1024*1024) // Change if we want GigaBytes
    const res = truncate(floatNumber, 2)
    return res
  }
  const bgColor = "darkMode.cardBgColor"
  return (
    <>
      <Box bgColor={bgColor}>
        <Heading as="h2" fontSize="1.25rem" fontWeight="500" lineHeight="1.2">
          {text1}
        </Heading>
        <Text color="#28a745" fontSize="1rem" fontWeight="500">
          {text2}
        </Text>
      </Box>

      <Box p="2" my="3">
        <Box justifyContent="space-between" mb="5">
          <Flex textAlign="center">
            <Box w="30px" mr="10px" p="1">
              <GiPenguin />
            </Box>
            <Text>{text3}</Text>
          </Flex>
          <Flex>
            <Box w="30px" mr="10px" p="1">
              <FaPython />
            </Box>
            {text4}
          </Flex>
          <Flex>
            <Box w="30px" mr="10px" p="1">
              <RiStackshareLine />
            </Box>
            {text5} v
          </Flex>
        </Box>
        <Box>
          <Flex>
            <Box w="30px" mr="10px" p="1">
              <FaMicrochip />
            </Box>
            {text6} cores ({text7} threads)
          </Flex>
        </Box>
        <Flex my="5">
          <Box w="30px" mr="10px" p="1">
            <FaMemory />
          </Box>
          <Box border="none" textAlign="center" fontSize="13px">
            {toMB(text8)} MB/{toMB(text9)} MB
          </Box>
        </Flex>
        <Flex my="2">
          <Box w="30px" mr="10px" p="1">
            <FiHardDrive />
          </Box>

          <Box border="none" fontSize="13px">
            {toMB(text10)} MB/{toMB(text11)} MB
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default StatusCards;
