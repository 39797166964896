import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { flexed } from "../../styles/styles";

const Form = ({ value, onChange, err }) => {
  const labelHead = {
    color: useColorModeValue("lightMode.btnBgColor", "darkMode.btnBgColor"),
    position: "absolute",
    top: "-13px",
    left: "13px",
    zIndex: "2",
    bgColor: useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor"),
    width: "fit-content",
    fontSize: "13.6px",
    px: 2,
  };
  const inputStyle = {
    px: "3",
    border: "1px",
    bgColor: useColorModeValue("lightMode.BgColor", "darkMode.BgColor"),
    borderColor: useColorModeValue(
      "lightMode.BgColor",
      "darkMode.mainTextColor"
    ),
  };

  return (
    <Box sx={flexed}>
      <FormControl
        mb={[10, 0, 0, 0]}
        variant="floating"
        id="Device Name"
        isRequired
        w={["100%"]}
        position="relative"
      >
        <Input
          type="text"
          value={value}
          sx={inputStyle}
          onChange={onChange}
          placeholder=""
        />
        <Text sx={labelHead}>Query</Text>
        {err && <FormHelperText color={"red.500"}>{err}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default Form;

export const Btn = ({ onClick, isLoading }) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      w="100"
      px={[2, 9, 10, "200px"]}
    >
      <Button
        type="submit"
        isLoading={isLoading}
        bgColor="darkMode.grayBackground"
        color="white"
        size="md"
        height="40px"
        width={["100%", "150px", "150px", "150px"]}
        onClick={onClick}
      >
        Search
      </Button>
    </Box>
  );
};
