import React from "react";
import {
  Button,
  Flex,
  GridItem,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { IDropdownProps } from "../../types";
import { cut } from "../../utils/helpers";

export const DropDownButtonComponent = ({ text, items }: IDropdownProps) => {
  const btnBgColorMode = useColorModeValue(
    "lightMode.secBgColor",
    "lightMode.btnBgColor"
  );

  return (
    <Menu>
      <MenuButton
        py="4"
        as={Button}
        fontWeight={"700"}
        bgColor={btnBgColorMode}
        fontSize={[10, 10, "13px", "13px"]}
        height={["30px", "30px", "40px", "40px"]}
      >
        <Flex
          gap={2}
          display="flex"
          align="center"
          justify={"center"}
          flexDirection={"row"}
        >
          <Text>{text}</Text>
          <AiOutlineCaretDown />
        </Flex>
      </MenuButton>
      <MenuList>
        {items.map((item, index) => (
          <MenuItem
            key={index}
            isDisabled={item.isLoading}
            onClick={item.action}
            display="flex"
            justifyContent={"space-between"}
          >
            {item.text}
            {item.isLoading && <Spinner size={"sm"} />}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const InfoItemComponent = ({
  text,
  data,
  perma,
}: {
  text: string;
  data?: string;
  perma?: string;
}) => {
  const colorMode = useColorModeValue(
    "lightMode.darkTextColor",
    "darkMode.mainTextColor"
  );

  return (
    <GridItem>
      <Text
        fontWeight="600"
        color={colorMode}
        fontSize={["11px", "11px", "15px", "1.5rem"]}
      >
        {text}
      </Text>

      {data && (
        <Text color={colorMode} fontSize={["11px", "11px", "12px", "1.5rem"]}>
          {cut(data)}
        </Text>
      )}

      {perma && (
        <Link
          color="blue"
          fontSize={["11px", "11px", "12px", "1.3rem"]}
          href={perma}
        >
          {cut(perma)}
        </Link>
      )}
    </GridItem>
  );
};
