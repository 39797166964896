import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Flex } from "@chakra-ui/react";
import { AiFillDelete } from "react-icons/ai";
import AnalysisTop from "../../components/data/Analysis/TopData";
import AnalysisBottom from "../../components/data/Analysis/BottomComponent";
import { useGetAnalysis } from "../../services/query/analysis";
import { useDeleteFirmware } from "../../services/query/firmware";
import LoadingComponent from "../../components/Loaders/LoadingComponent";
import ErrorComponent from "../../components/data/ErrorComponent";
import useCustomToast from "../../utils/notification";
import CustomHeader from "../../components/common/CustomHeader";
import { useRecentFirmwareList } from "../../services/query/dashboard";
import { useNavigate } from "react-router-dom";


const AnalysisPage = () => {
  const { errorToast, successToast } = useCustomToast();
  const navigate = useNavigate();
  const { refetch: refetchRecentFirmwareList } = useRecentFirmwareList();
  const { mutate } = useDeleteFirmware({
    onSuccess: () => {
      successToast("Firmware deleted successfully");
      refetchRecentFirmwareList();
      setTimeout(() => {
        console.log("Navigating to dashboard")
        navigate("/dashboard");
      }, 2000);
    },
    onError: (err) => {
      errorToast(err.response.data.message);
    },
  });

  const { scan_uid } = useParams();
  const { data, isLoading, error, refetch } = useGetAnalysis(
    scan_uid as string
  );
  
  const deleteFirmware = () => {
    mutate({
      scan_uid: scan_uid,
    });
  };
  
  
  return (
    <Box data-testid={"analysis"}>
      <Flex justifyContent={"space-between"} alignItems="center">
        <CustomHeader title="Analysis" />

        <Box>
          
          
        </Box>
      </Flex>

      {isLoading && <LoadingComponent />}
       
      {error && (
        <ErrorComponent
          message="This firmware cannot be processed."
          header={scan_uid}
          reload={refetch}
        />
      )}


      {data && (
        <>
          <AnalysisTop data={data} deleteFirmware={deleteFirmware} />
        </>
      )}

      {data && (
        <>
            <AnalysisBottom data={data} />


            
        </>
      )}
      
    </Box>
  );
};

export default AnalysisPage;
