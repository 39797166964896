import React from "react";
import { Box, Grid } from "@chakra-ui/react";
import {
  Computer,
  Threat,
  Malware,
  Warning,
  ComputerDarkmode,
  ThreatDarkmode,
  MalwareDarkmode,
  WarningDarkmode,
} from "../../../assets";
import { useThreatCount } from "../../../services/query/dashboard";
import Card from "./Card";

const DashboardCard = () => {
  const { data, refetch } = useThreatCount();

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Box mb="32px">
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(4, 1fr)" }}
        w="100%"
        gap="24px"
      >
        <Card
          data={data?.total_threats}
          label="Total Threats"
          lightSrc={Threat}
          darkSrc={ThreatDarkmode}
        />
        <Card
          data={data?.total_cve}
          label="Total CVE"
          lightSrc={Malware}
          darkSrc={ComputerDarkmode}
        />
        <Card
          data={data?.total_cwe}
          label="Total CWE"
          lightSrc={Warning}
          darkSrc={WarningDarkmode}
        />
        <Card
          data={data?.total_malware}
          label="Total Malware"
          lightSrc={Computer}
          darkSrc={MalwareDarkmode}
        />
      </Grid>
    </Box>
  );
};

export default DashboardCard;
