import React from "react";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useGetErrorLogs } from "../../services/query/errorLogs";
import CustomHeader from "../../components/common/CustomHeader";
import { scrollBarStyle } from "../../utils/helpers";

const ErrorLogs = () => {
  const { data } = useGetErrorLogs();
  return (
    <Box 
      h="75vh"
      >
      <CustomHeader title="Error Logs" />
      <Flex justify={"center"} mt={8} 
        height={"100%"}
        >
        <Box
          w={["100%", "90%", "70%", "60%"]}
          height={"70%"}
          overflowY="auto"
          sx={scrollBarStyle}
          border="none"
          padding={"20px"}
          borderRadius={"5px"}
          bgColor="darkMode.cardBgColor"
          color="white"
        >
          <Text fontSize={"24px"} fontWeight="bold" mb={4}>
            Error Logs
          </Text>
          <Text color="white" as="code">{data && data.data}</Text>
        </Box>
      </Flex>
    </Box>
  );
};
export default ErrorLogs;
