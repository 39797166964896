import React from "react";
import { Text, Box, Flex, useColorModeValue, Grid, VStack, Button } from "@chakra-ui/react";
import { useGetAnalysisCount } from "../../../../services/query/analysis";
import { useNavigate, useParams } from "react-router-dom";
import AnalysisModal from "../../../Modals/AnalysisModal";
import { InfoItemComponent } from "../../DropDownButtonComponent";
import { Compare, Download } from "./ButtonComponent";
import { formatBytes } from "../../../../utils/helpers";
import { AiFillDelete } from "react-icons/ai";

const AnalysisTopComponent = ({ data: firmWareData, deleteFirmware }) => {
  const [open, setOpen] = React.useState(false);
  const { scan_uid } = useParams();
  const { data: analysisCount } = useGetAnalysisCount(scan_uid as string);
  const metaData = firmWareData?.message?.firmware?.meta_data;
  const textColorMode = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.mainTextColor"
  );
  const cardBgColor = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.cardBgColor"
  );
  const navigate = useNavigate();
  return (
    <VStack
    >
      <Box width={["100%", "100%", "100%", "100%"]}>
        <Flex gap={4} 
        my="14px"
        justifyContent={'flex-end'}
        w={'100%'}
        >
          <Button
            bgColor={cardBgColor}
            mr="10px"
            disabled={true}
            cursor={"pointer"}
            onClick={() => {
              // Apply patch
            }}
          >
            Apply Patch
          </Button>
          <Button 
          onClick={()=>navigate(`/analysis-pdf/${scan_uid}`)}
          style={{background:"darkMode.grayBackground"}}
          bg={'darkMode.grayBackground'}
          >Generate PDF</Button>
          <Compare onClick={() => setOpen(true)} />
          <Button
            variant="danger"
            cursor={"pointer"}
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this Firmware?")
              ) {
                deleteFirmware();
              }
            }}
            rightIcon={<AiFillDelete />}
          >
            Delete
          </Button>
        </Flex>
      </Box>
      <Box
        p="2em"
        shadow="lg"
        background="#d9e3ee"
        borderRadius={"15px"}
        bgColor={cardBgColor}
        width={"100%"}
      >
        <Text
          pb="1em"
          fontWeight="600"
          color={textColorMode}
          fontSize={["11px", "11px", "15px", "2rem"]}
        >
          General Information
        </Text>
        <Text
          color={textColorMode}
          fontWeight={"semibold"}
          fontSize={[12, 12, "18px", "20px"]}
        >
          {metaData?.device_name}
        </Text>
        <Box fontSize={[10, 10, "15px", "18px"]} my={"3.5"} mb={4}>
          <Text as="span"> {scan_uid}</Text>
          {analysisCount?.malware_count < 1 &&
            <Text
              color={textColorMode}
              fontSize={[13, 13, "18px", "18px"]}
              marginTop={4}
              fontWeight={500}
              padding={2}
              backgroundColor="rgba(100, 255, 150, 0.2)"
              border="1px solid rgba(255, 255, 255, 0.5)"
              borderLeft="3px solid rgba(255, 255, 255, 0.5)"
              borderRadius={5}
            >
              Safe to execute/deploy -- no malware found
            </Text>
          }
          {analysisCount?.malware_count >= 1 &&
            <Text
              color={textColorMode}
              fontSize={[13, 13, "18px", "18px"]}
              marginTop={4}
              fontWeight={500}
              padding={2}
              backgroundColor="rgba(255, 120, 150, 0.2)"
              border="1px solid rgba(255, 255, 255, 0.5)"
              borderLeft="3px solid rgba(255, 255, 255, 0.5)"
              borderRadius={5}
            >
              Not Safe to execute/deploy -- malware found
            </Text>
          }
        </Box>
        
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <InfoItemComponent text="Device Name" data={metaData?.device_name} />
          <InfoItemComponent
            text="Device Class"
            data={metaData?.device_class}
          />
          <InfoItemComponent text="Device part" data={metaData?.device_part} />
          <InfoItemComponent text="HID" data={metaData?.hid} />
          <InfoItemComponent text="File Type" data="Not Available" />
          <InfoItemComponent text="Vendor" data={metaData?.vendor} />
          <InfoItemComponent text="Firmware Version" data={metaData?.version} />
          <InfoItemComponent
            text="Release Date"
            data={metaData?.release_date}
          />
          <InfoItemComponent
            text="File Size"
            data={formatBytes(metaData?.size)}
          />
          <InfoItemComponent
            text="Number of included files"
            data={metaData?.number_of_included_files}
          />
          <InfoItemComponent
            text="Total threat"
            data={analysisCount?.threat_count}
          />
          <InfoItemComponent
            text="CVSS Score"
            data={analysisCount?.cve_count}
          />
          <InfoItemComponent text="Total CWE" data={analysisCount?.cwe_count} />
          <InfoItemComponent
            text="Total Malware"
            data={analysisCount?.malware_count}
          />
        </Grid>
      </Box>
      {open && (
        <AnalysisModal
          data={firmWareData}
          isOpen={true}
          onClose={() => setOpen(false)}
        />
      )}
    </VStack>
  );
};

export default AnalysisTopComponent;
