import { useQuery } from "react-query";
import { getCodeCaveDetails } from "../api/codeCave"
import { CODE_CAVE_OBJECTS } from "../queryKeys";

export const useCodeCaveDetails = (scan_uid, options = {}) => {
  const { data, isLoading, error } = useQuery(
    [CODE_CAVE_OBJECTS, scan_uid],
    getCodeCaveDetails,
    {
      ...options,
    }
  );
  return { data, isLoading, error };
};