import { Box, Button, Spinner, Text, VStack, Flex, Center } from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { usePatchManagerApprove, usePatchManagerDisapprove, usePatchManagerRunFullAnalysis, usePatchManagerScanForMalware } from '../../../services/query/patchManager';
import useCustomToast from '../../../utils/notification';
import { 
    IoIosCheckmarkCircle,
    IoIosCloseCircle,
} from "react-icons/io";


const bgColor = '#33384A'
const textColor = "white"

const customStyles = {
    rows: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
    },
    table: {
		style: {
			backgroundColor: bgColor,
            color: textColor,
		},
	},
    headRow: {
        style: {
			backgroundColor: bgColor,
            color: textColor,
		},
    },
    rowCells: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
    },
    headCells: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
    },
    header: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
    },
    pagination: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none"
        },
        pageButtonsStyle: {
			borderRadius: '50%',
			height: '40px',
			width: '40px',
			padding: '8px',
			margin: 'px',
			cursor: 'pointer',
			transition: '0.4s',
			color: textColor,
            fill: "white",
			backgroundColor: '#4F5776',
        },
    },
    cells: {
        style: {
            backgroundColor: bgColor,
            color: textColor,
            border: "none",
            width: "200px",
        },
    },
    noData: {
		style: {
			display: 'flex',
            border: "none",
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: bgColor,
            color: textColor,
		},
	},
};


const ExpandedComponent = ({ data }) => {
    return (
        <>
            <VStack spacing='24px'>
                {data && data.meta_data && Object.getOwnPropertyNames(data.meta_data).map((key) => {
                    return (
                        <Flex w="100%" style={{marginTop: "0px", padding: "10px"}} bgColor={bgColor} color={textColor}>
                            <Text w='300px' textAlign={"right"} pr="15px" bgColor={bgColor} color={textColor}>{key}:</Text>
                            <Box flex='1' bgColor={bgColor} color={textColor}>
                                <Text>{String(data.meta_data[key])}</Text>
                            </Box>
                        </Flex>
                    )
                })}
            </VStack>  
        </>
        
    )
}

const PatchesTable = ({renderData, title, refreshMethod}) => {
    const { errorToast, successToast } = useCustomToast();

    const { mutate: approveMutate, isLoading: isApproveLoading } = usePatchManagerApprove({
        onSuccess: () => {
            successToast("Patch Approved");
        },
        onError: () => {
            errorToast("Please try after some time");
        },
    });

    const { mutate: disapproveMutate, isLoading: isDisapproveLoading } = usePatchManagerDisapprove({
        onSuccess: () => {
            successToast("Patch Disapproved");
        },
        onError: () => {
            errorToast("Please try after some time");
        },
    });

    const { mutate: runFullAnalysisMutate, isLoading: isRunFullAnalysisLoading } = usePatchManagerRunFullAnalysis({
        onSuccess: () => {
            successToast("Run full analysis created");
        },
        onError: () => {
            errorToast("Please try after some time");
        },
    });

    const { mutate: scanMalwareMutate, isLoading: isScanMalwareLoading } = usePatchManagerScanForMalware({
        onSuccess: () => {
            successToast("Scan for malware created");
        },
        onError: () => {
            errorToast("Please try after some time");
        },
    });

    const columns: any = [
        {
            name: "Name",
            selector: (row) => row.patch_name,
            sortable: true,
        },
        {
            name: "File Present",
            selector: (row) => String(row.file_present),
            sortable: true,
            cell: (row) => (row.file_present ? <IoIosCheckmarkCircle color="green"/> : <IoIosCloseCircle color="red"/>)
        },
        {
            name: "Approved",
            selector: (row) => String(row.approved),
            sortable: true,
            cell: (row) => (row.approved ? <IoIosCheckmarkCircle color="green"/> : <IoIosCloseCircle color="red"/>)
        },
        {
            name: "Safe To Deploy",
            selector: (row) => String(row.safe),
            sortable: true,
            cell: (row) => (row.safe ? <IoIosCheckmarkCircle color="green"/> : <IoIosCloseCircle color="red"/>)
        },
        {
            name: "Approve",
            cell: (row) => (
                <Button
                    bgColor={"teal"}
                    colorScheme='teal' size='sm'
                    onClick={async (ev)=>{
                        approveMutate({patch_id:row.patch_id})
                        await refreshMethod()
                        return true
                    }}
                    disabled={row.approved}
                    leftIcon={<IoIosCheckmarkCircle />}
                >
                </Button>
            )
        },
        {
            name: "Disapprove",
            cell: (row) => (
                <Button
                    bgColor={"red"} colorScheme='red' size='sm'
                    onClick={async (ev)=>{
                        disapproveMutate({patch_id:row.patch_id})
                        await refreshMethod()
                        return true
                    }}
                    disabled={!row.approved}
                    leftIcon={<IoIosCloseCircle />}
                >
                </Button>
            )
        },
        {
            name: "Full Analysis",
            cell: (row) => {
                return (
                    <Button 
                        bgColor={"gray"} colorScheme='gray' size='sm'
                        onClick={(ev)=>{
                            runFullAnalysisMutate({patch_id:row.id})
                            return true
                        }}
                    >
                        Run
                    </Button>
                )
            }
        }
    ];

    return (
        <>
            {isApproveLoading &&
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='teal.500'
                    size='xl'
                />
            }
            {isDisapproveLoading &&
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='red.500'
                    size='xl'
                />
            }
            {isRunFullAnalysisLoading &&
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='gray.500'
                    size='xl'
                />
            }
            {isScanMalwareLoading &&
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='purple.500'
                    size='xl'
                />
            }
            
            <DataTable
                title={title}
                columns={columns}
                data={renderData}
                customStyles={customStyles}
                pagination
                expandableRows
                expandableRowsComponent={ExpandedComponent}
            />
        </>
    ) 
}

export default PatchesTable
