import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useGetRecentComparisons } from "../../../../services/query/dashboard";
import { GET_RECENT_COMPARISONS } from "../../../../services/queryKeys";
import PageLoader from "../../../Loaders/PageLoader";

const FirmwareComparisons = () => {
  const { data: comparisons, isLoading: comparisonsLoading } =
    useGetRecentComparisons();

  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.invalidateQueries(GET_RECENT_COMPARISONS);
  }, [queryClient, GET_RECENT_COMPARISONS]);

  // const bgColor = useColorModeValue("lightMode.secBgColor", "darkMode.secBgColor")
  const bgColor = "darkMode.mainBgColor"
  // const cardColor = useColorModeValue("lightMode.cardBgColor", "darkMode.cardBgColor")
  const cardColor = "darkMode.cardBgColor"

  return (
    <Box mt="16px">
      <Box
        bg={bgColor}
        p="10px 0px 16px 17px"
        borderTopRadius="10px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        Firmware Comparisons
      </Box>
      <Box
        marginTop="-8px"
        bg={cardColor}
        p="14px 40px 28px 17px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius="10px"
      >
        {comparisonsLoading && <PageLoader lineNo={2} />}
        {comparisons?.results?.length > 0 ? (
          comparisons?.results.map((item: any, i: number) => (
            <Link
              to={`/analysis/compare/result/${
                item?.result?.request?.compare_id.split(";")[0]
              }/${item?.result?.request?.compare_id.split(";")[1]}`}
              key={i + 1}
            >
              <Box
                justifyContent=""
                _hover={{ background: "gray.600" }}
                borderTop={`${i !== 0 ? "1px solid gray" : "none"} `}
                p="8px"
                borderRadius={"5px"}
              >
                <Box>
                  {item?.firmwares?.length
                    ? item?.firmwares[0]?.device_name || "No Device Name"
                    : "No Device Name"}
                </Box>
                <Box color="#909090" fontSize="12px">
                  {item?.firmwares?.length
                    ? item?.result?.request?.compare_id.split(";")[0]
                    : ""}
                </Box>
                <Box>
                  {item?.firmwares?.length > 1
                    ? item?.firmwares[1]?.device_name || "No Device Name"
                    : "No Device Name"}
                </Box>
                <Box color="#909090" fontSize="12px">
                  {item?.firmwares?.length
                    ? item?.result?.request?.compare_id.split(";")[1]
                    : ""}
                </Box>
              </Box>
            </Link>
          ))
        ) : (
          <Text>No Firmware Compared at the moment</Text>
        )}
      </Box>
    </Box>
  );
};

export default FirmwareComparisons;
