import { useQuery } from "react-query";
import { getThreatDetails } from "../api/threat";
import { GET_THREAT_DETAILS } from "../queryKeys";

export const useThreatDetails = (scan_uid, options = {}) => {
  const { data, isLoading, error } = useQuery(
    [GET_THREAT_DETAILS, scan_uid],
    getThreatDetails,
    {
      ...options,
    }
  );
  return { data, isLoading, error };
};
