import React from "react";
import { Flex, CircularProgress, useColorModeValue } from "@chakra-ui/react";

const LoadingComponent = () => {
  const colorMode = useColorModeValue(
    "darkMode.mainBgColor",
    "lightMode.mainBgColor"
  );

  return (
    <Flex my="2em" alignItems="center" justifyContent="center">
      <CircularProgress isIndeterminate color={colorMode} />
    </Flex>
  );
};

export default LoadingComponent;
