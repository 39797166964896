import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const GoBack = () => {
  const navigate = useNavigate();

  return (
    <IoIosArrowBack size={32} cursor="pointer" onClick={() => navigate(-1)} />
  );
};

export default GoBack;
