import { Box, GridItem, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export const ModelItemComponent = ({ item }: { item: any }) => {
  const { colorMode } = useColorMode();

  return (
    <GridItem>
      <Link to={`/analysis/${item.scan_uid}`}>
        <Box
          my="9px"
          p="10px"
          borderRadius="0.5em"
          bgColor={
            colorMode === "light"
              ? "lightMode.secBgColor"
              : "darkMode.cardBgColor"
          }
          borderColor={
            colorMode === "light"
              ? "lightMode.BgColor"
              : "darkMode.mainTextColor"
          }
        >
          <Text fontWeight="500" fontSize={{ lg: "20px" }}>
            {item.device_class ? item.device_class : "No device class"}
          </Text>
          <Text fontWeight="500" fontSize={{ lg: "20px" }}>
            {item.vendor ? item.vendor : "No Vendor"}
          </Text>
          <Text fontSize={["15px", "15px", "12px", "12px"]}>
            {item.created_at}
          </Text>
        </Box>
      </Link>
    </GridItem>
  );
};
