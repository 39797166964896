import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import React from "react";

interface iProp {
  tHead1: string;
  tHead2: string;
  tHead3: string;
  tHead4: string;
  tHead5?: string;
  tHead6?: string;
  tBody1: string;
  tBody2: string;
  tBody3: string;
  tBody4: string;
  tBody5?: string;
  tBody6?: string;
}

const FirmwareDataTable = (props: iProp) => {
  const {
    tHead1,
    tHead2,
    tHead3,
    tHead4,
    tHead5,
    tHead6,
    tBody1,
    tBody2,
    tBody3,
    tBody4,
    tBody5,
    tBody6,
  } = props;
  return (
    <Table size="sm" fontSize={"md"}>
      <Tbody>
        <Tr>
          <Td width="70%">{tHead1}</Td>
          <Td width="30%">{tBody1}</Td>
        </Tr>
        <Tr>
          <Td width="70%">{tHead2}</Td>
          <Td width="30%">{tBody2}</Td>
        </Tr>
        <Tr>
          <Td width="70%">{tHead3}</Td>
          <Td width="30%">{tBody3}</Td>
        </Tr>
        <Tr>
          <Td width="70%">{tHead4}</Td>
          <Td width="30%">{tBody4}</Td>
        </Tr>

        {tHead5 && tBody5 ? (
          <Tr>
            <Td width="70%">{tHead5}</Td>
            <Td width="30%">{tBody5}</Td>
          </Tr>
        ) : null}

        {tHead6 && tBody6 ? (
          <Tr>
            <Td width="70%">{tHead6}</Td>
            <Td width="30%">{tBody6}</Td>
          </Tr>
        ) : null}
      </Tbody>
    </Table>
  );
};

export default FirmwareDataTable;
