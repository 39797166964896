import { apiURL } from "../../environment";
import { DOWNLOAD_FILE, DOWNLOAD_PDF } from "../apiUrl";
import axiosInstance from "../axiosInstance";

export const getFileDownload = async (scan_uid) => {
  const res = await axiosInstance.get(
    `${DOWNLOAD_FILE}?firmware=${scan_uid}&uid=${scan_uid}`
  );
  return res.data;
};

export const downloadFile = (scan_uid: any, fileName = "Firmware") => {
  getFileDownload(scan_uid).then((res) => {
    const url = res.target_link;
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  });
};

export const getPdfDownload = async (scan_uid) => {
  const token = JSON.parse(localStorage.getItem("user") as string).access;
  const pdfDownloadUrl = `${apiURL}${DOWNLOAD_PDF}?scan_uid=${scan_uid}&token=${token}`;
  const link = document.createElement("a");
  link.href = pdfDownloadUrl;
  link.setAttribute("target", "_blank");
  link.setAttribute("download", "Firmware assessment.pdf");
  document.body.appendChild(link);
  link.click();
};
