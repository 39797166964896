import React, { useState } from "react";
import {
  Flex,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  useColorMode,
  useColorModeValue,
  Tooltip
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BsFillPersonFill } from "react-icons/bs";
import { CgMenuRight } from "react-icons/cg";
import { Logo } from "../../assets";
import SideMenu from "./SideMenu";
import { useCustomMediaQuery, useIsNavActive } from "../../utils/helpers";
import { BiChevronDown } from "react-icons/bi";
import { useGetUserProfile } from "../../services/query/user";
import Notification from "../Notification/Notification";
import { navLinks, menuLists, adminLists } from "../common/constants";
import { HeaderMenuItem } from "../data/Auth/HeaderMenuItem";

const Header = () => {
  const { data: userInfo } = useGetUserProfile();
  const isNavActive = useIsNavActive();
  const [isOpen, setIsOpen] = useState(false);
  // const navBgColorMode = useColorModeValue(
  //   "lightMode.navBgColor",
  //   "darkMode.navBgColor"
  // );

  // const secBgColorMode = useColorModeValue(
  //   "lightMode.secBgColor",
  //   "darkMode.secBgColor"
  // );

  const navBgColorMode = "darkMode.navBgColor"

  const { isMobile } = useCustomMediaQuery();

  return (
    <>
      <Flex
        h="45"
        py="2em"
        width="100%"
        overflow="hidden"
        mx={"auto"}
        alignItems="center"
        maxW={"1540px"}
        justifyContent="space-between"
        bgColor={navBgColorMode}
      >
        <Box display={"flex"} alignItems="center">
          <Box fontSize={"13"} color="white">
            <Link to="/">
              <Image ml={2} src={Logo} alt="logo" width={"33px"} />
            </Link>
          </Box>

          <Box
            fontSize={"13"}
            color="white"
            ml={"10"}
            display={["none", "none", "none", "flex"]}
          >
            {navLinks.slice(0, 3).map((el, idx: number) => {
              const scan_uid = localStorage.getItem("scan_uid") as string
              let linkURL = ""
              if (el.path === "/security") {
                linkURL = "/security/scan-overview/"+scan_uid
              } else {
                if (el.path === "/analysis") {
                  linkURL = "/analysis/"+scan_uid
                } else {
                  linkURL = el.path // "/dashboard"
                }
              }

              const pathString = window.location.pathname.split("/")[1]
              if (pathString === "analysis" || pathString === "security" || pathString === "malware" || el.path === "/dashboard" || pathString === "threat-intelligence") {
                return (<Box
                  p={2}
                  // key={idx}
                  m="0 10px"
                  height="100%"
                  borderRadius="4px"
                  bgColor={
                    // isNavActive(el.path) ? colorMode === "light" ? "lightMode.btnBgColor" : "darkMode.secBgColor" : "none"
                    isNavActive(el.path) ? "darkMode.secBgColor" : "none"
                  }
                >
                  <Link to={linkURL}>
                    {el.hoverText !== undefined && el.hoverText !== "" &&
                      <Tooltip label={el.hoverText}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text ml="5px">{el.name}</Text>
                        </Box>
                      </Tooltip>
                    }
                    {el.hoverText === undefined || el.hoverText === "" &&
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text ml="5px">{el.name}</Text>
                      </Box>
                    }
                  </Link>
                </Box>)
              } else {
                return <div style={{display: "none"}}></div>
              }
            })}

            {navLinks.slice(14).map((el, idx: number) => {
              const scan_uid = localStorage.getItem("scan_uid") as string
              return (<Box
                  p={2}
                  // key={idx}
                  m="0 10px"
                  height="100%"
                  borderRadius="4px"
                  bgColor={
                    isNavActive(el.path) ? "darkMode.secBgColor" : "none"
                  }
                >
                  <Link to={el.path}>
                    {el.hoverText !== undefined && el.hoverText !== "" &&
                      <Tooltip label={el.hoverText}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text ml="5px">{el.name}</Text>
                        </Box>
                      </Tooltip>
                    }
                    {el.hoverText === undefined || el.hoverText === "" &&
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text ml="5px">{el.name}</Text>
                      </Box>
                    }
                  </Link>
                </Box>
              )
            })}
            {navLinks.slice(9, 10).map((el, idx: number) => {
              const scan_uid = localStorage.getItem("scan_uid") as string
              let linkURL = ""
              if (el.path === "/malware") {
                linkURL = "/malware/report/"+scan_uid
              } else {
                linkURL = "/"
              } 
              const pathString = window.location.pathname.split("/")[1]
              if (pathString === "analysis" || pathString === "security" || pathString === "malware" || pathString === "threat-intelligence") {
                return (<Box
                  p={2}
                  // key={idx}
                  m="0 10px"
                  height="100%"
                  borderRadius="4px"
                  bgColor={
                    isNavActive(el.path) ? "darkMode.secBgColor" : "none"
                  }
                >
                  <Link to={linkURL}>
                    {el.hoverText !== undefined && el.hoverText !== "" &&
                      <Tooltip label={el.hoverText}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text ml="5px">{el.name}</Text>
                        </Box>
                      </Tooltip>
                    }
                    {el.hoverText === undefined || el.hoverText === "" &&
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text ml="5px">{el.name}</Text>
                      </Box>
                    }
                  </Link>
                </Box>
                )
              } else {
                return <div style={{display: "none"}}></div>
              }
              
            })}
            
            <Box display={"flex"} alignItems="center">
                <Link to={'/patch-manager'}>
                    <Text>Patch Manager</Text>
                </Link>
            </Box>
            <Menu closeOnSelect isLazy>
              <MenuButton
                as={Button}
                display="flex"
                variant="secondary"
                rightIcon={<BiChevronDown />}
                fontSize={"13"}
                fontWeight={"normal"}
                h="36px"
                _active={{bgColor: "darkMode.secBgColor"}}
                _focus={{bgColor: "darkMode.secBgColor"}}
                _hover={{bgColor: "darkMode.secBgColor"}}
              >
                Statistics
              </MenuButton>

              <MenuList
                // bgColor={useColorModeValue(
                //   "lightMode.navBgColor",
                //   "darkMode.secBgColor"
                // )}
                bgColor="darkMode.grayBackground"
              >
                {navLinks.slice(4, 6).map((el, idx) => {
                  if (el.name === "Health") {
                    return (<div style={{display:"none"}}></div>)
                  } else {
                    return (
                      <MenuItem
                        w="80%"
                        // key={idx}
                        m="auto"
                        height="100%"
                        borderRadius="4px"
                        bgColor={
                          // isNavActive(el.path) ? colorMode === "light" ? "lightMode.btnBgColor" : "darkMode.navBgColor" : "none"
                          isNavActive(el.path) ? "darkMode.grayBackground" : "none"
                        }
                        _focus={{
                          bgColor: "darkMode.grayBackground",
                        }}
                        _hover={{
                          bgColor: "darkMode.grayBackground",
                        }}
                      >
                        <Link to={el.path}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Image src={el.image} alt="dashboard" width="17px" />
                            <Text ml="5px">{el.name}</Text>
                          </Box>
                        </Link>
                      </MenuItem>
                    )
                  }
                })}
              </MenuList>
            </Menu>

            {/* {navLinks.slice(10).map((el, idx: number) => {
               return (
                <Box
                  p={2}
                  // key={idx}
                  m="0 10px"
                  height="100%"
                  borderRadius="4px"
                  bgColor={
                    isNavActive(el.path) ? "darkMode.secBgColor" : "none"
                  }
                >
                  <Link to={el.path}>
                    {el.hoverText !== undefined && el.hoverText !== "" &&
                      <Tooltip label={el.hoverText}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Text ml="5px">{el.name}</Text>
                        </Box>
                      </Tooltip>
                    }
                    {el.hoverText === undefined || el.hoverText === "" &&
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text ml="5px">{el.name}</Text>
                      </Box>
                    }
                  </Link>
                </Box>
              )
            })} */}
          </Box>
        </Box>

        <Box
          fontSize="13"
          color="white"
          mr="4"
          display={"flex"}
          flexDirection="row"
        >
          <Notification />
          <Button
            onClick={() => setIsOpen(true)}
            bgColor="darkMode.grayBackground"
            display={["inline-block", "inline-block", "inline-block", "none"]}
            _active={{bgColor: "darkMode.grayBackground"}}
            _focus={{bgColor: "darkMode.grayBackground"}}
            _hover={{bgColor: "darkMode.grayBackground"}}
          >
            <CgMenuRight color="#fff" size={16} />
          </Button>

          <Menu>
            <MenuButton
              p="1em"
              as={Button}
              fontSize="11"
              bgColor="darkMode.grayBackground"
              _active={{bgColor: "darkMode.grayBackground"}}
              _focus={{bgColor: "darkMode.grayBackground"}}
              _hover={{bgColor: "darkMode.grayBackground"}}
              display={["none", "none", "none", "inline-block"]}
            >
              <BsFillPersonFill size={16} />
            </MenuButton>
            <MenuList bgColor="darkMode.grayBackground" color="white">
              {(userInfo?.is_admin === true ? adminLists : menuLists).map(
                (el, idx) => (
                  <HeaderMenuItem key={idx} item={el} />
                )
              )}
            </MenuList>
          </Menu>
        </Box>
      </Flex>

      {isOpen && isMobile && (
        <SideMenu isOpen={true} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

export default Header;