import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  DELETE_NOTIFICATIONS,
} from "../apiUrl";
import axiosInstance from "../axiosInstance";

export const getNotifications = async () => {
  const res = await axiosInstance.get(GET_NOTIFICATIONS);
  return res.data;
};

export const deleteNotifications = async () => {
  const res = await axiosInstance.delete(DELETE_NOTIFICATIONS);
  return res.data;
};

export const deleteNotificationsId = async (body) => {
  const res = await axiosInstance.delete(
    `${DELETE_NOTIFICATIONS}${body.id}/`,
    body
  );
  return res.data;
};

export const markAsRead = async (body) => {
  const res = await axiosInstance.post(
    `${MARK_NOTIFICATION_AS_READ}/${body.id}/`,
    body
  );
  return res.data;
};

export const markAllAsRead = async () => {
  const res = await axiosInstance.post(MARK_ALL_NOTIFICATIONS_AS_READ);
  return res.data;
};
