import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export const AuthInput = ({ label, name, error, value, onChange, onBlur }) => {
  return (
    <FormControl mb={2}>
      <FormLabel fontSize="1em" mb={0}>
        {label}
      </FormLabel>
      <Input
        mt={0}
        h={23}
        py={5}
        px={2}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        bgColor="white"
      />
      <Text color={"tomato"} fontSize="8px">
        {error}
      </Text>
    </FormControl>
  );
};

export const LoginInput = ({
  label,
  my,
  mt,
  error,
  name,
  value,
  onChange,
  onBlur,
}) => {
  const inputStyle = useColorModeValue(
    "lightMode.inputBgColor",
    "darkMode.inputBgColor"
  );

  return (
    <FormControl mt={my}>
      <FormLabel fontSize="12" mt={mt}>{label}</FormLabel>
      <Input
        bgColor={inputStyle}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={name}
        color="darkMode.inputTextColor"
      />
      <Text color={"tomato"} fontSize="10px">
        {error}
      </Text>
    </FormControl>
  );
};
