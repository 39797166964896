import { apiURL } from "../../environment";
import { GET_USER, EDIT_USER_INFO, CHANGE_PASSWORD, EDIT_MANAGE_ENGINE } from "../apiUrl";
import axiosInstance from "../axiosInstance";

export const getUserProfile = async () => {
  const res = await axiosInstance.get(GET_USER);
  return res.data?.message;
};

export const editUserInfo = async (body) => {
  const res = await axiosInstance.patch(apiURL + EDIT_USER_INFO, body);
  return res.data?.message;
};

export const changePassword = async (body) => {
  const res = await axiosInstance.post(apiURL + CHANGE_PASSWORD, body);

  return res.data?.message;
};

export const changeManageEngineCreds = async (body) => {
  const res = await axiosInstance.post(apiURL + EDIT_MANAGE_ENGINE, body);
  console.log(res)
  // return res.data?.message;
  return res
};
