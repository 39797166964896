import { useMutation } from "react-query";
import { createUser } from "../api/register";
import { CREATE_NEW_USER } from "../queryKeys";

export const useCreateNewUser = (options = {}) => {
  const { mutate, isLoading } = useMutation(createUser, {
    mutationKey: CREATE_NEW_USER,
    ...options,
  });

  return { mutate, isLoading };
};
