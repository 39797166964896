import { useColorModeValue } from "@chakra-ui/react";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import FirmwareDataTable from "./FirmwareDataTable";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export const FirmwareTable = ({ data }) => {
  return (
    <FirmwareDataTable
      tHead1="Firmware Samples"
      tHead2="Firmware total size"
      tHead3="Firmware average size"
      tHead4="Unique included files"
      tHead5="unique included files total size"
      tHead6="unique included files average size"
      tBody1={`${data?.general?.number_of_firmwares?.toFixed(2)}`}
      tBody2={`${data?.general?.total_firmware_size?.toFixed(2)} MiB`}
      tBody3={`${data?.general?.average_firmware_size?.toFixed(2)} MiB`}
      tBody4={`${data?.general?.number_of_unique_files?.toFixed(2)}`}
      tBody5={`${data?.general?.total_file_size?.toFixed(2)} MiB`}
      tBody6={`${data?.general?.average_file_size?.toFixed(2)} KiB`}
    />
  );
};

export const UnpackerStats = ({ data }) => {
  return (
    <FirmwareDataTable
      tHead1="Share of not-packed files"
      tHead2="Average packed entropy"
      tHead3="Average not-packed entropy"
      tHead4="Ratio of data loss"
      tBody1={`100.00%`}
      tBody2={`${data?.unpacking?.average_packed_entropy?.toFixed(2)} MiB`}
      tBody3={`${data?.unpacking?.average_unpacked_entropy?.toFixed(2)} MiB`}
      tBody4={`${data?.unpacking?.overall_data_loss_ratio?.toFixed(2)}`}
    />
  );
};

export const FirmwareDoughnut = ({ data }) => {
  const datas = data?.firmware_meta?.vendor;
  const months: string[] = [];
  const values: number[] = [];
  const merged = [].concat.apply([], datas);

  merged.filter((item) =>
    typeof item === "number" ? values.push(item) : months.push(item)
  );

  const datass = {
    labels: [...months],
    datasets: [
      {
        data: [...values],
        backgroundColor: [
          "#4062FA",
          "#FFCE56",
          "#FF6384",
          "#36A2EB",
          "#56ff5e",
          "#ff5e56",
          "#640f7e",
          "#4e3938",
          "#e0ff56",
          "#ff2e50",
        ],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  };
  return <Doughnut data={datass} width="5px" />;
};

export const DeviceClasses = ({ data }) => {
  const datas = data?.firmware_meta?.device_class;
  const months = [];
  const values = [];
  const merged = [].concat.apply([], datas);

  merged.filter((item) =>
    typeof item === "number" ? values.push(item) : months.push(item)
  );

  const datass = {
    labels: [...months],
    datasets: [
      {
        data: [...values],
        backgroundColor: ["#4062FA", "#FFCE56", "#FF6384", "#36A2EB"],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  };
  return <Doughnut data={datass} width="5px" />;
};

export const FirmwareContainer = ({ data }) => {
  const datas = data?.file_type?.firmware_container;
  const months = [];
  const values = [];
  const merged = [].concat.apply([], datas);

  merged.filter((item) =>
    typeof item === "number" ? values.push(item) : months.push(item)
  );

  const datass = {
    labels: [...months],
    datasets: [
      {
        data: [...values],
        backgroundColor: ["#4062FA", "#FFCE56", "#FF6384", "#36A2EB"],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  };
  return <Doughnut data={datass} width="5px" />;
};

export const UnpackerUsage = ({ data }) => {
  const datas = data?.unpacking?.used_unpackers;
  const months = [];
  const values = [];
  const merged = [].concat.apply([], datas);

  merged.filter((item) =>
    typeof item === "number" ? values.push(item) : months.push(item)
  );

  const datass = {
    labels: [...months],
    datasets: [
      {
        data: [...values],
        backgroundColor: ["#4062FA", "#FFCE56", "#FF6384", "#36A2EB"],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  };
  return <Doughnut data={datass} width="5px" />;
};

export const DataLost = ({ data }) => {
  const datas = data?.unpacking?.data_loss_file_types;
  const months = [];
  const values = [];
  const merged = [].concat.apply([], datas);

  merged.filter((item) =>
    typeof item === "number" ? values.push(item) : months.push(item)
  );

  const datass = {
    labels: [...months],
    datasets: [
      {
        data: [...values],
        backgroundColor: ["#4062FA", "#FFCE56", "#FF6384", "#36A2EB"],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  };
  return <Doughnut data={datass} width="5px" />;
};

export const Architecture = ({ data }) => {
  const datas = data?.architecture?.cpu_architecture;
  const months = [];
  const values = [];
  const merged = [].concat.apply([], datas);

  merged.filter((item) =>
    typeof item === "number" ? values.push(item) : months.push(item)
  );

  const datass = {
    labels: [...months],
    datasets: [
      {
        data: [...values],
        backgroundColor: ["#4062FA", "#FFCE56", "#FF6384", "#36A2EB"],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  };
  return <Doughnut data={datass} width="5px" />;
};

export const SoftwareComponent = ({ data }) => {
  const textColorMode = useColorModeValue(
    "lightMode.mainTextColor",
    "darkMode.mainTextColor"
  );

  const datas = data?.software_components?.software_components;
  const months = [];
  const values = [];
  const merged = [].concat.apply([], datas);

  merged.filter((item) =>
    typeof item === "number" ? values.push(item) : months.push(item)
  );

  const datass = {
    labels: [...months],
    datasets: [
      {
        data: [...values],
        backgroundColor: ["#4062FA", "#FFCE56", "#FF6384", "#36A2EB"],
        borderColor: "black",
        borderWidth: 0,
      },
    ],
  };
  return <Doughnut data={datass} width="5px" color={textColorMode} />;
};

const config: ChartOptions = {
  transitions: {
    resize: {
      animation: {
        duration: 0,
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
};

export const firmwareBarChartOptions: ChartOptions = {
  ...config,
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
      borderColor: "#FFFFFF",
      backgroundColor: [
        "#4062FA",
        "#F4C069",
        "#4062FA",
        "#F4C069",
        "#4062FA",
        "#F4C069",
        "#4062FA",
        "#4062FA",
        "#4062FA",
        "#4062FA",
      ],
    },
  },
};

export const ExploitMitigationChart = ({ data }) => {
  const key: string[] = [];
  const values: number[] = [];
  const newData = data?.exploit_mitigations?.exploit_mitigations;
  newData?.forEach(function (el) {
    values.push(el[1]);
    key.push(el[0]);
  });

  const exploits = {
    labels: [...key],
    datasets: [
      {
        label: "",
        data: [...values],
      },
    ],
  };

  return <Bar data={exploits} options={firmwareBarChartOptions} />;
};

export const ElfExecutables = ({ data }) => {
  const key: string[] = [];
  const values: number[] = [];
  const newData = data?.elf_executable?.executable_stats;
  newData?.forEach(function (el) {
    values.push(el[1]);
    key.push(el[0]);
  });

  const elf = {
    labels: [...key],
    datasets: [
      {
        label: "",
        data: [...values],
      },
    ],
  };

  return <Bar data={elf} options={firmwareBarChartOptions} />;
};

// export const FirmwareTable2 = () => {
//   return (
//     <Table size="sm">
//       <Tbody>
//         <Tr>
//           <Td width="70%">0.0.0.0</Td>
//           <Td width="30%">43</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">204.194.232.200</Td>
//           <Td width="30%">7</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">204.194.234.200</Td>
//           <Td width="30%">7</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">192.168.0.1</Td>
//           <Td width="30%">6</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">192.168.0.100</Td>
//           <Td width="30%">2</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">192.168.0.199</Td>
//           <Td width="30%">2</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">192.168.0.254</Td>
//           <Td width="30%">2</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">224.0.0.0</Td>
//           <Td width="30%">2</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">0.9.17.1</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//       </Tbody>
//     </Table>
//   );
// };

// export const FirmwareTable3 = () => {
//   return (
//     <Table size="sm" fontSize={"x-small"}>
//       <Tbody>
//         <Tr>
//           <Td width="70%">::aE</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">71::1093</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">::1093</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">::ac</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">::beac</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">::dea</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">ac::</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">ac::A</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">ac::ADD</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">ac::D</Td>
//           <Td width="30%">1</Td>
//         </Tr>
//       </Tbody>
//     </Table>
//   );
// };

// export const FirmwareTable4 = () => {
//   return (
//     <Table size="sm" fontSize={"x-small"}>
//       <Tbody>
//         <Tr>
//           <Td width="70%">support.dlink.com/</Td>
//           <Td width="30%">54</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">schemas.xmlsoap.org/soap/encoding/</Td>
//           <Td width="30%">3</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">schemas.xmlsoap.org/soap/envelope/</Td>
//           <Td width="30%">3</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">support.dlink.com/products/view.asp</Td>
//           <Td width="30%">3</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">www.dlink.com</Td>
//           <Td width="30%">2</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">www.dlink.com/</Td>
//           <Td width="30%">2</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">www.liget/documentation/configuration.html</Td>
//           <Td width="30%">2</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">www.w3.org/1999/xhtml</Td>
//           <Td width="30%">2</Td>
//         </Tr>
//         <Tr>
//           <Td width="70%">www.w3c.org/2003/05/soap-encoding</Td>
//           <Td width="30%">2</Td>
//         </Tr>
//       </Tbody>
//     </Table>
//   );
// };

export const ReleaseDateBarChart = ({ data }) => {
  const datas = data?.release_date?.date_histogram_data;
  const months = [];
  const values = [];
  const merged = [].concat.apply([], datas);

  merged.filter((item) =>
    typeof item === "number" ? values.push(item) : months.push(item)
  );
  const state = {
    config,
    labels: [...months],
    datasets: [
      {
        label: "Release Date",
        backgroundColor: [
          "#006DB2",
          "#00527C",
          "#A3CEEF",
          "#BABABA",
          "#E1EEF9",
          "#A3CEEF",
          "#7BB4E3",
        ],
        borderColor: "#006DB2",
        borderWidth: 1,
        data: [...values],
      },
    ],
  };

  return <Bar data={state} options={firmwareBarChartOptions} />;
};
