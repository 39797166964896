import React from "react";
import { Box, useColorModeValue, Text } from "@chakra-ui/react";
import { useGetAllBinarySearchList } from "../../services/query/search";
import CustomHeader from "../../components/common/CustomHeader";
import { Link } from "react-router-dom";
import PageLoader from "../../components/Loaders/PageLoader";
import { dateFormat, scrollBarStyle } from "../../utils/helpers";

const BinarySearchList = () => {
  const { data: binaryList, isLoading: isBinaryLoading } =
    useGetAllBinarySearchList();

  return (
    <Box
      color={useColorModeValue(
        "lightMode.mainTextColor",
        "darkMode.mainTextColor"
      )}
      px={{ base: "16px", xl: 41 }}
      pt={{ base: "20px", "2xl": "40px" }}
      paddingBottom={{ lg: "70px", "2xl": "100px" }}
    >
      <CustomHeader title="Binary Search List" />

      <Box
        marginTop="46px"
        bg={useColorModeValue("lightMode.secBgColor", "darkMode.secBgColor")}
        borderRadius="10px"
        padding={{
          base: "34px 8px 34px 8px",
          lg: "34px 48px 70px 48px",
        }}
        h="60vh"
        overflowY="auto"
        sx={scrollBarStyle}
        fontSize={{ base: "14px", "2xl": "14px" }}
        fontWeight="semibold"
        filter="drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))"
      >
        {isBinaryLoading && <PageLoader lineNo={2} />}
        {binaryList?.results?.map((item) => (
          <Box
            _hover={{ boxShadow: "lg", cursor: "pointer" }}
            borderBottom="1px solid #000"
          >
            <Link to={`/binary-search/${item?.search_id}`}>
              <Text>ID:{item?.id}</Text>
              <Text>Short description:{item?.short_description}</Text>
              <Text>Status: {item?.status}</Text>
              <Box>
                <Text mt={-5} float="right">
                  Created At:{dateFormat(item?.created_at)}
                </Text>
              </Box>
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BinarySearchList;
