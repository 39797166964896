import React from "react";
import { Box, Button, useColorModeValue } from "@chakra-ui/react";
import AddComment from "./AddComment";
import ShowComment from "./ShowComment";
import EditComment from "./EditComment";
import CreateComment from "./CreateComment";

const CommentComponent = () => {
  const [showButton, setShowBotton] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [showBox, setShowBox] = React.useState("button");
  const [id, setId] = React.useState("");
  const [text, setText] = React.useState("");
  const [comment, setComment] = React.useState("");
  const background = useColorModeValue("white", "darkMode.mainBgColor");
  const btn = useColorModeValue("lightMode.secBgColor", "lightMode.btnBgColor");

  return (
    <Box my={"8"} bgColor={'darkMode.grayBackground'}>
      <AddComment
        show={showBox}
        onClick={() => setShowBotton(!showButton)}
        showBtn={showButton}
        SecOnClick={() => setShowBox("comment")}
        FinalOnClick={() => setShowBox("input")}
      />

      {showBox === "comment" && (
        <Box
          height={"auto"}
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          borderRadius="4px"
          justifyContent="space-between"
        >
          <Box
            w="95%"
            mx="auto"
            my="10px"
            display="flex"
            justifyContent={"flex-end"}
          >
            <Button
              h={"20px"}
              width={"75px"}
              fontSize="11"
              paddingX={"9"}
              py="3"
              fontWeight={"500"}
              bgColor="#33384a"
              onClick={() => {
                setShowBox("button");
                setEdit(false);
              }}
              background={btn}
            >
              Close
            </Button>
          </Box>
          <ShowComment
            edit={edit}
            setText={setText}
            setId={setId}
            setEdit={setEdit}
          />

          <EditComment
            edit={edit}
            id={id}
            text={text}
            setText={setText}
            onClick={() => {
              setShowBox("button");
              setShowBotton(false);
              setEdit(false);
            }}
            onChange={(e) => setText(e.target.value)}
          />

          <Box h="18px" />
        </Box>
      )}

      {showBox === "input" && (
        <CreateComment
          setComment={setComment}
          setShowBotton={setShowBotton}
          setShowBox={setShowBox}
          comment={comment}
          onClick={() => {
            setShowBox("button");
            setShowBotton(false);
          }}
          onChange={(e) => setComment(e.target.value)}
        />
      )}
    </Box>
  );
};

export default CommentComponent;
