import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useColorModeValue,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Box,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useInitiateComparison } from "../../services/query/comparison";
import { useGetModels } from "../../services/query/analysis";
import { useNavigate } from "react-router-dom";
import useCustomToast from "../../utils/notification";
import { useGetRecentComparisons } from "../../services/query/dashboard";

const AnalysisModal = React.forwardRef(
  ({ isOpen, onClose, data: currentFirmware }: any, btnRef: any) => {
    const [firmWareScanId, setFirmwareScanId] = React.useState("");
    const { errorToast, successToast } = useCustomToast();
    const { refetch: refetchRecentComparisons } = useGetRecentComparisons();
    const { scan_uid } = useParams();
    const navigate = useNavigate();
    const { data: firmWareData } = useGetModels();
    const { mutate, isLoading } = useInitiateComparison({
      onSuccess: (res) => {
        successToast("You will be notified when your result is ready");
        refetchRecentComparisons();
        setTimeout(() => {
          navigate(
            `/analysis/compare/result/${res?.firmwares[0]?.scan_uid}/${res?.firmwares[1]?.scan_uid}`
          );
        }, 3000);
      },
      onError: (err) => {
        errorToast(err.response.data.firmwares[0]);
      },
    });

    const compare = () => {
      const arr = [scan_uid, firmWareScanId.toString()];
      mutate({
        firmwares: arr,
        redo: false,
      });
    };

    const btnBgColorMode = useColorModeValue(
      "lightMode.secBgColor",
      "lightMode.btnBgColor"
    );

    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Compare{" "}
              {currentFirmware?.message?.firmware?.meta_data?.device_name} with
              other Firmwares
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Select
                  placeholder="Select Firmware"
                  value={firmWareScanId}
                  onChange={(e) => setFirmwareScanId(e.target.value)}
                >
                  {firmWareData?.results?.map((item, i) => (
                    <option key={i} value={item.scan_uid}>
                      {item.device_name ? item.device_name : "No Device Name"}
                    </option>
                  ))}
                </Select>

                <Box w="100%">
                  <Button
                    onClick={compare}
                    mx="auto"
                    my={"10px"}
                    bg={btnBgColorMode}
                    isLoading={isLoading}
                  >
                    Compare
                  </Button>
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export default AnalysisModal;
