import { useState, useMemo } from "react";
import useCustomToast from "../../utils/notification";
import { useCreateNewFirmware } from "../../services/query/uploadFirmware";
import { useRecentFirmwareList } from "../../services/query/dashboard";
import { useNavigate } from "react-router-dom";
import { Upload } from "../../assets";
import CustomHeader from "../../components/common/CustomHeader";
import UploadInput from "../../components/CustomInput/UploadInput";
import { FileButton } from "../../components/data/FileUpload/FileUpload";
import {
  acceptStyle,
  flexed,
  baseStyle,
  focusedStyle,
  rejectStyle,
  inptBoxStyle,
} from "../../components/styles/styles";
import { Box, Button, Image, Input, Text } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import "./FileUpload.css"

const NewUpload = () => {
  const { refetch: refetchFirmwareList } = useRecentFirmwareList();
  const { successToast, infoToast } = useCustomToast();
  const [deviceName, setDeviceName] = useState("");
  const [devicePart, setDevicPart] = useState("");
  const [deviceClass, setDeviceClass] = useState("");
  const [fileName, setFileName] = useState("");
  const [version, setVersion] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [tag, setTag] = useState("");
  const [vendor, setVendor] = useState("");
  const [base64, setBase64] = useState("");
  const navigate = useNavigate();

  const { mutate, isLoading: isCreateLoading } = useCreateNewFirmware({
    onSuccess: () => {
      successToast("Upload successful");
      infoToast("Hold on while your Firmware is been processed", 16000);
      refetchFirmwareList();
      setTimeout(() => {
        navigate(`/`);
      }, 16000);
    },
    onError: (err) => {
      if (err.message === "Request failed with status code 413") {
        infoToast(" size limit exceeded in firmwware upload", 6000);
        return;
      }
      infoToast(err?.response?.data?.message || err.message, 6000);
    },
  });

  const firmareUpload = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("device_name", deviceName);
    formData.append("device_part", devicePart);
    formData.append("device_class", deviceClass);
    formData.append("file_name", fileName);
    formData.append("release_date", releaseDate);
    formData.append("version", version);
    formData.append("tags", tag);
    formData.append("vendor", vendor);
    formData.append("upload_file", base64);
    mutate(formData);
  };

  const fileUpload = async (e) => {
    setBase64(e.target.files[0]);
  };

  const dropUpload = async (e) => {
    setBase64(e);
  };

  useDropzone({
    noClick: true,
    onDrop: (files) => {
      dropUpload(files);
    },
  });

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      noClick: true,
      onDrop: (files) => {
        dropUpload(files[0]);
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Box>
      <Box>
        <CustomHeader title=" Upload Firmware" />

        <Box as="form" onSubmit={firmareUpload}>
          <Box sx={flexed}>
            <UploadInput
              value={deviceName}
              label="Device Name"
              helper="Name of Device"
              onChange={(e) => {
                setDeviceName(e.target.value);
              }}
            />
            <UploadInput
              value={devicePart}
              label="Device Part"
              helper="e.g: Kernel, bootloader"
              onChange={(e) => {
                setDevicPart(e.target.value);
              }}
            />
          </Box>

          <Box sx={flexed}>
            <UploadInput
              value={deviceClass}
              label="Device Class"
              helper="e.g: DVD-Rom, VoIP Gateway"
              onChange={(e) => {
                setDeviceClass(e.target.value);
              }}
            />
            <UploadInput
              value={fileName}
              label="File Name"
              helper="Name of file uploaded"
              onChange={(e) => {
                setFileName(e.target.value);
              }}
            />
          </Box>

          <Box sx={flexed}>
            <UploadInput
              value={version}
              label="Version"
              helper="e.g: 1.2"
              onChange={(e) => {
                setVersion(e.target.value);
              }}
            />
            <UploadInput
              value={releaseDate}
              label="Release Date"
              helper="e.g: 2019"
              onChange={(e) => {
                setReleaseDate(e.target.value);
              }}
            />
          </Box>

          <Box sx={flexed}>
            <UploadInput
              value={tag}
              label="Tags"
              helper="Comma separated list (e.g: flashdump,partial)"
              onChange={(e) => {
                setTag(e.target.value);
              }}
            />
            <UploadInput
              value={vendor}
              label="Vendor"
              helper="e.g: Asus, Cisco"
              onChange={(e) => {
                setVendor(e.target.value);
              }}
            />
          </Box>

          <Box
            px={[2, 9, 10, "200px"]}
            py={[2, 3, 5, 5]}
            sx={{
              "@media (max-width: 500px)": {
                paddingTop: "5px",
                paddingBottom: "5px",
              },
            }}
          >
            <Box mt="0" sx={inptBoxStyle} color="white">
              File
            </Box>
            {base64 === "" ? (
              <Input
                type="file"
                title="Choose a video please"
                onChange={(e) => {
                  fileUpload(e);
                }}
                border="none"
                mt="1"
              />
            ) : (
              <>
                <Text>File has been added</Text>
                <Button mb="3" variant="outline" onClick={() => setBase64("")}>
                  Change
                </Button>
              </>
            )}

            <div className="container" style={{backgroundColor: "darkMode.grayBackground"}}>
              <Box {...getRootProps({ style })} flexDirection="column">
                <input {...getInputProps()} />
                {base64 === "" ? (
                  <Box textAlign="center" bgColor="darkMode.grayBackground">
                    <Image src={Upload} alt="upload" w="100px" />

                    <Text fontWeight="600" color="white">
                      DRAG & DROP
                    </Text>
                    <Text color="white">To Upload</Text>
                  </Box>
                ) : (
                  <Text color="white" fontWeight="600">
                    File has been added
                  </Text>
                )}
              </Box>
            </div>
          </Box>

          <FileButton isLoading={isCreateLoading} />
        </Box>
      </Box>
    </Box>
  );
};

export default NewUpload;
